import { useOrderContext } from "./OrderContextProvider";

const OrderNotes = () => {
  const { orderEntry, updateOrderEntryField, readOnly } = useOrderContext();

  const handleChange = (e: any) => {
    updateOrderEntryField("order_details", e.target.name, e.target.value);
  };

  return (
    <div className="panel">
      <div className="flex items-center justify-between panel-header">
        <h3 className="flex items-center font-bold">Order Notes</h3>
      </div>
      <div className="panel-body !p-0">
        <textarea
          readOnly={readOnly}
          className="block w-full h-full rounded-none border-none resize-none"
          rows={5}
          placeholder="Note any additional info related to the order..."
          name="OrderNotes"
          value={orderEntry.order_details.OrderNotes}
          onChange={(e) => handleChange(e)}></textarea>
      </div>
    </div>
  );
};

export default OrderNotes;
