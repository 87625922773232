import { useCallback, useRef, useState } from "react";
import { useNoteContext } from "./NoteContext";
import { baseURL } from "config";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";

const NoteDetail = () => {
  const { activeNoteId, activeNote, updateNoteField } = useNoteContext();
  const timeoutHandlerRef = useRef<any | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const storage_string = localStorage.getItem("USER");
  const storage = storage_string ? JSON.parse(storage_string) : [];

  const [saving, setSaving] = useState<boolean>(false);
  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  const updateNote = useCallback(async (note: any, signal: AbortSignal) => {
    try {
      setSaving(true);
      // eslint-disable-next-line
      const response = await axios.put(`${baseURL}/notes/${activeNoteId}`, note, {
        headers: {
            Authorization: `Bearer ${userInfo?.accessToken}`
        },
        signal
    })
        .then(({ data }) => data)

    } catch (ex) {
      console.log(ex)
    } finally {
      setSaving(false);
    }
  }, [activeNoteId, setSaving])

  const handleChange = useCallback((e: any) => {
    const { name, value } = e.target;

    updateNoteField(name, value)

    if (timeoutHandlerRef.current) {
      clearTimeout(timeoutHandlerRef.current);
      timeoutHandlerRef.current = null;
    }
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
    timeoutHandlerRef.current = setTimeout(() => {
      abortControllerRef.current = new AbortController()
      updateNote(activeNote, abortControllerRef.current.signal)
    }, 200)
  }, [activeNote, updateNoteField, updateNote])

  return (
    <div className='h-full relative flex flex-col p-4'>
      <div className="mb-2 border-b">
        <input readOnly={storage['AccessType'] === "Administrator" ? false : true} type="text" className="border-none !p-0 !pb-1" name="title" value={activeNote.title} onChange={handleChange}></input>
      </div>
      <div className="flex-1 relative">
        <textarea onChange={handleChange} name="content" readOnly={storage['AccessType'] === "Administrator" ? false : true} value={activeNote.content} className="h-full border-none p-0"></textarea>
        <div className={`absolute z-10 bottom-2 right-2 text-gray-500  ${saving ? "" : "hidden"}`}><FaSpinner className="w-4 h-4 animate-spin-slow" /></div>
      </div>
    </div>
  );
}

export default NoteDetail;