import {
  FaEdit,
  FaHistory,
  FaRegCheckSquare,
  FaRegCopy,
  FaRegFileAlt,
  FaRegWindowClose
} from "react-icons/fa";
import moment from "moment";
import { Link } from "react-router-dom";
import { useOrderContext } from "./OrderContextProvider";

interface Order {
  Status: string;
  CreatedOn: string;
  OrderNumber: number;
  DeliveryAddress: string;
  DeliveryDate: string;
}

const PreviousOrders = () => {
  const { previousOrders } = useOrderContext();
  return (
    <div className="panel  bg-gray-50">
      <div className="panel-header !bg-gray-100">
        <h3 className="flex items-center font-bold">
          <FaHistory className="w-4 h-4 mr-1" />
          Previous Orders
        </h3>
      </div>
      <div className=" h-[330px] overflow-auto">
        <div className="space-y-3 panel-body">
          {previousOrders.map((item: Order, i: number) => (
            <div
              key={`order-item-${i}`}
              className="flex items-center justify-between gap-1 cursor-pointer">
              <Link to={`/orders/${item.OrderNumber}`} className="flex items-center">
                <span className="flex-none w-4 mr-1" title={item.Status}>
                  {(() => {
                    switch (item.Status) {
                      case "Pending":
                        return <FaRegCheckSquare className="w-4 h-4" />;
                      case "Quote":
                        return <FaRegFileAlt className="w-4 h-4" />;
                      case "Cancel":
                        return <FaRegWindowClose className="w-4 h-4" />;
                      // case 'lost':
                      case "Delivered":
                        return <FaRegCheckSquare className="w-4 h-4" />;
                      case "Completed":
                        return <FaRegCheckSquare className="w-4 h-4" />;
                      //   return <Lost handleClick={handleClick} />
                      default:
                        return null;
                    }
                  })()}
                </span>
                <span className="line-clamp-1">{`${
                  item.DeliveryDate ? moment(item.DeliveryDate).format("MM/DD/YY") + "-" : ""
                }
                ${item.OrderNumber.toString()}-${item.DeliveryAddress}`}</span>
              </Link>
              <div className="flex">
                <Link
                  to={`/orders/${item.OrderNumber}/edit`}
                  className="ml-1 text-blue-500 btn-text">
                  <FaEdit className="w-4 h-4" />
                </Link>
                <Link
                  to={`/orders/new?copy=${item.OrderNumber}`}
                  className="ml-1 text-orange-500 btn-text">
                  <FaRegCopy className="w-4 h-4" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PreviousOrders;
