import axios from "axios";
import { baseURL } from "config";
import { useState } from "react";
import { FaEye, FaEyeSlash, FaUserTie } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../context";
import { toast } from "react-toastify";
import React from "react";

const UserProfile = () => {
  const { state } = useGlobalContext();
  const [passwordType, setPasswordType] = useState("password");
  const [emailError, setEmailError] = useState("");
  const [PasswordModal, setPasswordModal] = useState(false);

  const validEmail = new RegExp(`^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$`);

  const confirmValue = () => {
    if (!validEmail.test(userInputValues.Email)) {
      setEmailError("Email is invalid!");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  const [userInputValues, setuserInputValues] = useState({
    ID: state.user.ID,
    Name: state.user.Name,
    Password: state.user.Password,
    Email: state.user.Email,
    AccessType: state.user.AccessType,
    Picture: state.user.Picture,
    isDeleted: state.user.isDeleted
  });

  const [passwordData, setPasswordData] = useState({
    currentPass: "",
    newPass: "",
    confirmPass: ""
  });

  const navigate = useNavigate();

  const putAvatar = async (file: any, key: string) => {
    const data = {
      key: key
    };
    const preSignedURL = await axios.post(`${baseURL}/presignedUrl`, data, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    const myHeaders = new Headers({ "Content-Type": "image/jpeg" });
    return await fetch(preSignedURL.data, {
      method: "PUT",
      headers: myHeaders,
      body: file
    });
  };

  const onImageChange = async (e: any) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(`${baseURL}/upload-to-linode`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data.data) {
          setuserInputValues({
            ...userInputValues,
            Picture: response.data.data
          });
        }

        console.log(response.data);
        // Handle success response
      } catch (error) {
        console.error('Error uploading file:', error);
        // Handle error
      }













      // const key = "image/" + Date.now().toString() + "_" + file.name;
      // putAvatar(file, key)
      //   .then((res) => {
      //     setuserInputValues({
      //       ...userInputValues,
      //       Picture: `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION_NAME}.amazonaws.com/${key}`
      //     });
      //   })
      //   .catch((error) => {
      //     console.log("error:::", error);
      //   });
    }
  };

  const onChangePass = (e: any) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value
    });
  };

  const onChange = (e: any) => {
    // setCurrentPassword(e.target.value);
    // setNewPassword(e.target.value);
    // setConfirmPassword(e.target.value);
    // setState({ ...state, [name]: value });
    setuserInputValues({
      ...userInputValues,
      [e.target.name]: e.target.value
    });
  };

  const createUser = async () => {
    if (!confirmValue()) return;

    const data = {
      Name: userInputValues.Name,
      Email: userInputValues.Email,
      Password: userInputValues.Password,
      AccessType: userInputValues.AccessType,
      Picture: userInputValues.Picture
    };

    const createdUser = await axios.post(`${baseURL}/user`, data, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });

    if (createdUser) {
      toast.success("User add successful");
    } else {
      toast.error("CreatingUSer Failed::");
    }
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleGeneratePassword = (e: any) => {
    const lowerCaseLetters = "abcdefghijklmnopqrstuvwxyz";
    const upperCaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const characters = lowerCaseLetters + upperCaseLetters + numbers;
    let password = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters[randomIndex];
    }
    setuserInputValues({
      ...userInputValues,
      Password: password
    });
  };

  const handleDelete = () => {
    axios
      .delete(`${baseURL}/user/${userInputValues.ID}`, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          navigate("/users");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Please input all values");
      });
  };

  const handleSuspend = () => {
    setuserInputValues({
      ...userInputValues,
      isDeleted: !userInputValues.isDeleted
    });
    axios
      .put(`${baseURL}/user/${userInputValues.ID}`, {
        ID: userInputValues.ID,
        Name: userInputValues.Name,
        Password: userInputValues.Password,
        Email: userInputValues.Email,
        AccessType: userInputValues.AccessType,
        Picture: userInputValues.Picture,
        isDeleted: !userInputValues.isDeleted
      }, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Please input all values");
      });
  };

  const handleUpdate = () => {
    axios
      .put(`${baseURL}/user/${userInputValues.ID}`, userInputValues, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Please input all values");
      });
  };

  const handleSubmit = (e: any) => {
    axios
      .post(`${baseURL}/login`, {
        email: userInputValues.Email,
        password: passwordData.currentPass
      }, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          if (passwordData.newPass !== passwordData.confirmPass) {
            toast.error("The passwords you entered don't match", {
              autoClose: 1000,
              hideProgressBar: true
            });
          } else {
            axios
              .post(`${baseURL}/passupdate`, {
                Password: passwordData.newPass,
                Email: userInputValues.Email
              }, {
                headers: {
                  Authorization: `Bearer ${userInfo?.accessToken}`,
                },
              })
              .then((response) => {
                if (response.data.success) {
                  toast.success(response.data.message);
                }
                setPasswordModal(false);
              })
              .catch((err) => {
                console.log(err);
                toast.error("Please input all values");
              });
          }
        }
      })
      .catch((err) => {
        toast.error("The current password is incorrect", {
          autoClose: 1000,
          hideProgressBar: true
        });
      });

    // console.log(passwordData);
    // if(passwordData.currentPass == "") {
    //   console.log("incorrect");
    // } else {
    //   if(passwordData.newPass != passwordData.confirmPass) {
    //     console.log("xxxxx");
    //   } else {
    //     console.log("sucess");
    //   }
    // }
  };

  return (
    <div className="relative">
      <div className="flex justify-center">
        {!userInputValues.Picture ? (
          <FaUserTie className="w-36 h-36 " />
        ) : (
          <img className="w-36- h-36" src={userInputValues.Picture} alt="user profile" />
        )}
      </div>
      <div className="flex justify-center mt-10 max-w-[500px] w-full mx-auto">
        <div className="w-full space-y-4 text-sm">
          <div className="w-full form-control">
            <label>Name</label>
            <div className="input-inline">
              <input
                type="text"
                placeholder="User1"
                name="Name"
                id="Name"
                onChange={(e) => onChange(e)}
                value={userInputValues.Name}
              />
            </div>
          </div>
          <div className="form-control">
            <label>Email Address</label>
            <div className="input-inline">
              <input
                type="text"
                placeholder="John@Domain.Com"
                name="Email"
                id="Email"
                onChange={(e) => onChange(e)}
                value={userInputValues.Email}
              />
              {emailError && <span className="errorText">{emailError}</span>}
            </div>
          </div>

          <div className="form-control">
            {/* <div className="text-right w-[141px]"> */}
            <label>PassWord</label>
            {/* </div> */}
            <div className="input-inline">
              <div className="relative shadow-sm mrounded-md">
                <input
                  className="userpassword text-ellipsis"
                  disabled={userInputValues.ID ? true : false}
                  type={passwordType}
                  name="Password"
                  id="Password"
                  onChange={(e) => onChange(e)}
                  placeholder="********"
                  value={userInputValues.ID ? "********" : userInputValues.Password}
                />
                <div className="absolute inset-y-0 right-0 flex items-center">
                  <button
                    className="btn black"
                    onClick={togglePassword}
                    disabled={userInputValues.ID ? true : false}>
                    {passwordType === "password" ? (
                      <FaEye className="text-black" />
                    ) : (
                      <FaEyeSlash className="text-black" />
                    )}
                  </button>
                  {!userInputValues.ID ? (
                    <button
                      onClick={(e) => handleGeneratePassword(e)}
                      id="currency"
                      name="currency"
                      className="h-full text-sm border rounded-md btn-info btn">
                      Generate
                    </button>
                  ) : (
                    <div>
                      <button
                        onClick={() => setPasswordModal(true)}
                        className="h-full text-sm border rounded-md btn-info btn focus:ring-2">
                        Change
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="form-control">
            {/* <div className="text-right w-[141px]"> */}
            <label>Access Type</label>
            {/* </div> */}
            <div className="input-inline">
              <select
                onChange={(e) => onChange(e)}
                name="AccessType"
                id="AccessType"
                placeholder="Select Access Type"
                value={userInputValues.AccessType}>
                <option value="" disabled hidden>
                  Select Access Type
                </option>
                <option value="Administrator" key="Administrator">
                  Administrator
                </option>
                <option value="Order Taker" key="Order Taker">
                  Order Taker
                </option>
                <option value="Delivery Driver" key="Delivery Driver">
                  Delivery Driver
                </option>
              </select>
            </div>
          </div>
          <div className="form-control">
            {/* <div className="text-right w-[141px]"> */}
            <label>Picture</label>
            {/* </div> */}
            <div className="input-inline">
              <input
                onChange={(e) => onImageChange(e)}
                className="block w-full text-sm cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:border-gray-400 dark:placeholder-gray-400"
                id="file_input"
                type="file"></input>
            </div>
          </div>

          {!userInputValues.ID ? (
            <div className="flex justify-between mt-10 mb-10">
              <div>
                <button className="btn btn-danger " onClick={() => navigate("/users")}>
                  Cancel
                </button>
              </div>
              <div>
                <button className="btn btn-primary" onClick={() => createUser()}>
                  Create
                </button>
              </div>
            </div>
          ) : (
            <div className="flex justify-between mt-10 mb-10">
              <div>
                <button onClick={() => handleDelete()} className="btn btn-danger">
                  Delete User
                </button>
              </div>
              <div>
                {!userInputValues.isDeleted ? (
                  <button onClick={() => handleSuspend()} className="mx-5 btn btn-primary">
                    Suspend User
                  </button>
                ) : (
                  <button onClick={() => handleSuspend()} className="mx-5 btn btn-primary">
                    Recovery User
                  </button>
                )}
              </div>
              <div>
                <button onClick={() => handleUpdate()} className="btn btn-info">
                  Save User
                </button>
              </div>
            </div>
          )}
        </div>
        {PasswordModal ? (
          <React.Fragment>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
              <div className="relative w-auto max-w-3xl mx-auto my-6">
                {/*content*/}
                <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200">
                    <h3 className="text-3xl font-semibold">Change Password</h3>
                    <button
                      className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                      onClick={() => setPasswordModal(false)}>
                      <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  <div className="relative flex-auto p-6">
                    <div className="w-full mt-2 form-control">
                      <div className="from-control sm:w-1/3">
                        <label className="">Current password</label>
                      </div>
                      <div className="input-inline sm:w-2/3">
                        <input
                          type="password"
                          placeholder="Current password"
                          name="currentPass"
                          id="CurrentPassword"
                          onChange={(e) => onChangePass(e)}
                        />
                      </div>
                    </div>
                    <div className="w-full mt-2 form-control">
                      <div className="from-control sm:w-1/3">
                        <label className="">New password</label>
                      </div>
                      <div className="input-inline sm:w-2/3">
                        <input
                          type="password"
                          placeholder="New password"
                          name="newPass"
                          id="NewPassword"
                          onChange={(e) => onChangePass(e)}
                        />
                      </div>
                    </div>
                    <div className="w-full mt-2 form-control">
                      <div className="from-control sm:w-1/3">
                        <label className="">Confirm password</label>
                      </div>
                      <div className="input-inline sm:w-2/3">
                        <input
                          type="password"
                          placeholder="Confirm password"
                          name="confirmPass"
                          id="ConfirmPassword"
                          onChange={(e) => onChangePass(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between p-6 border-t border-solid rounded-b border-slate-200">
                    <button
                      className="grid w-20 btn btn-danger "
                      type="button"
                      onClick={() => setPasswordModal(false)}>
                      Close
                    </button>
                    <button
                      className="grid w-20 btn btn-primary "
                      type="button"
                      onClick={(e) => handleSubmit(e)}>
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-50"></div>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default UserProfile;
