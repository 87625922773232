// import module
import { useNotificationValues } from "providers/NotificationValuesProvider";
import { FaQuestionCircle } from "react-icons/fa";
import { useLayout } from "providers/LayoutProvider";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckArrowRight, faDolly } from "@fortawesome/free-solid-svg-icons";

// import {
//   Drawer,
// } from "@material-tailwind/react";
// import Note from "components/Note";

const NotificationStats = () => {

  // values
  const { values } = useNotificationValues();
  const { toggleNotesPanel } = useLayout()

  return (
    <div className="notification-stats-panel-wrapper">
      <div className="flex space-x-4">
        <div className="notification-item cursor-pointer">
          {/* <FaQuestionCircle onClick={toggleNotesPanel} className="notification-item-icon" /> */}
          <img src="/assets/notes.svg" alt="note icon" onClick={toggleNotesPanel} className="notification-item-icon" />
        </div>
        {/* <Link to="orders/active" className="notification-item">
          <FontAwesomeIcon icon={faTruckArrowRight} className="notification-item-icon" />
          <span className="w-5 h-5 flex justify-center items-center absolute -top-2 -right-2 bg-red-500 rounded-full text-white text-xs">
            {values.pendingOrderCount}
          </span>
        </Link>
        <Link to="orders/active" className="notification-item">
          <FontAwesomeIcon icon={faDolly} className="notification-item-icon"/>
          <span className="w-5 h-5 flex justify-center items-center absolute -top-2 -right-2  bg-red-500 rounded-full text-white text-xs">
            {values.deliveredOrderCount}
          </span>
        </Link> */}
      </div>
    </div>

  );
};

export default NotificationStats;
