import { FaCog } from "react-icons/fa";
import Dumpster from "./components/Dumpster";
import Item from "./components/Item";

const Settings = () => {
  return (
    <>
      <div className="flex items-center">
        <div className="">
          <h1 className="flex items-center font-bold md:text-xl ">
            <FaCog className="w-1/2 h-6 mr-1 md:w-7 md:h-7 " /> Settings
          </h1>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 xl:col-span-1 md:grid-cols-2 mt-8">
        <div className="mt-4">
          <Item />
        </div>
        <div className="mt-4 ml-4">
          <Dumpster />
        </div>
      </div>
    </>
  );
};

export default Settings;
