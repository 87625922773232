import { useCallback, useEffect, useState } from "react";
import { FaMapMarkerAlt, FaUserTie, FaUserPlus, FaFileContract } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { baseURL } from "config";
import $ from "jquery";
import Auto from "./Auto";
import RouteDialog from "./RouteDialog";
import React from "react";
import { useOrderContext } from "./OrderContextProvider";
import { generateEmptyOrderEntry } from "utils/orders.helper";
import AddressAutocomplete from "./AddressAutocomplete";

interface myProps {
  customers: Customer[];
  getCustomers: () => void;
  openAddressModal: any;
  selectedAddress: any;
  addresses: any;
  storeAddress: any;
}
const CustomerInfo: React.FC<myProps> = ({ customers, getCustomers, openAddressModal, selectedAddress, addresses, storeAddress }) => {
  const {
    orderEntry,
    resetOrderEntry,
    updateOrderEntryField,
    setOrderEntry,
    readOnly,
    setReadOnly
  } = useOrderContext();
  const [showModal, setShowModal] = useState(false);
  const [googleModal, setGoogleModal] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const storage_string = localStorage.getItem("USER");
  const storage = storage_string ? JSON.parse(storage_string) : [];

  $(".form-control").attr("autocomplete", "none");

  // const validEmail = new RegExp(`^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$`);
  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  const PHONE_REGEX = new RegExp("^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$");

  const handleChange = (e: any) => {
    switch (e.target.name) {
      case "PhoneNumber":
        setPhoneError("");
        break;
      case "EmailAddress":
        setEmailError("");
        break;
      // case "BillingAddress":
      //   setUserTyping(true);
      //   break;
      default:
        break;
    }
    if (e.target.name === "PhoneNumber" && e.target.value === "") {
      resetOrderEntry();
    } else {
      updateOrderEntryField("customer", e.target.name, e.target.value);
    }
  };
  const onBillingAddressSelected = useCallback(
    (address: string) => updateOrderEntryField("customer", "BillingAddress", address),
    [updateOrderEntryField]
  );

  const confirmValue = () => {
    if (!orderEntry.customer.PhoneNumber) {
      setPhoneError("PhoneNumber is required! ");
      return false;
    }
    if (!PHONE_REGEX.test(orderEntry.customer.PhoneNumber)) {
      setPhoneError("PhoneNumber is incorrect!");
      return false;
    }
    if (orderEntry.customer.PhoneNumber.length < 10) {
      setPhoneError("PhoneNumber is incorrect!");
      return false;
    }
    // if (!validEmail.test(orderEntry.customer.EmailAddress)) {
    //   setEmailError("Email is invalid!");
    //   return false;
    // }
    return true;
  };

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }
  const updateCustomer = () => {
    setShowModal(false);
    if (!confirmValue()) return;

    if (!orderEntry.customer.PhoneNumber) return;
    const customer = {
      ...orderEntry.customer,
      PhoneNumber: orderEntry.customer.PhoneNumber?.slice(-10),
      ...orderEntry.delivery_contact,
      DeliveryContactPhone: orderEntry.delivery_contact.DeliveryContactPhone?.slice(-10),
      DateAdded: orderEntry.customer.DateAdded
        ? orderEntry.customer.DateAdded
        : new Date().toISOString(),
      AddedBy: orderEntry.customer.AddedBy ? orderEntry.customer.AddedBy : storage["Name"],
      LastModified: orderEntry.customer.AddedBy ? new Date().toISOString() : "",
      LastModifiedBy: orderEntry.customer.AddedBy ? storage["Name"] : ""
    };

    axios
      .put(`${baseURL}/customer/${orderEntry.customer.PhoneNumber?.slice(-10)}`, customer, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          getCustomers();
          storeAddress(response?.data?.OrderNumber)
        }
      })
      .catch((err) => {
        toast.error("Customer already exist.");
      });
  };

  const resetOrderWithCustomer = (customer: Customer) => {
    setOrderEntry({
      ...generateEmptyOrderEntry(),
      customer: {
        PhoneNumber: customer.PhoneNumber,
        CustomerName: customer.CustomerName,
        EmailAddress: customer.EmailAddress,
        BillingAddress: customer.BillingAddress,
        CustomerNotes: customer.CustomerNotes,
        DateAdded: customer.DateAdded,
        AddedBy: customer.AddedBy,
        LastModified: customer.LastModified,
        LastModifiedBy: customer.LastModifiedBy
      },
      delivery_contact: {
        DeliveryContactPhone: customer.DeliveryContactPhone,
        DeliveryContact: customer.DeliveryContact,
        DeliveryAddress: customer.DeliveryAddress,
        DeliveryNotes: ""
      }
    });

    setReadOnly(false);
  };


  return (
    <div className="panel overflow-visible	">
      <div className="flex items-center justify-between panel-header">
        <h3 className="flex items-center font-bold">
          <FaUserTie className="w-4 h-4 mr-1" />
          Customer
        </h3>
        <div className="text-right">
          <button
            className="btn bg-orange-500 btn-icon hover:bg-orange-700 !p-1"
            onClick={() => {
              setShowModal(true);
            }}>
            <FaUserPlus className="w-3 h-3" />
          </button>
        </div>
      </div>
      <div className="space-y-4 panel-body">
        <div className="form-control">
          <div>
            <label title="Customer Phone">Phone</label>
          </div>
          <div className="input-inline">
            <Auto
              customers={customers}
              onSelect={resetOrderWithCustomer}
              handleChange={handleChange}
              value={orderEntry.customer.PhoneNumber}
            />
            {phoneError && <span className="errorText">{phoneError}</span>}
          </div>
        </div>

        <div className="form-control">
          <div>
            <label title="Customer Name">Name</label>
          </div>
          <div className="input-inline">
            <input
              autoComplete="none"
              type="text"
              readOnly={readOnly}
              placeholder="Name"
              value={orderEntry.customer.CustomerName}
              name="CustomerName"
              onChange={(e: any) => handleChange(e)}
            />
          </div>
        </div>
        <div className="form-control">
          <div>
            <label title="Customer Email">Email</label>
          </div>
          <div className="input-inline">
            <input
              type="email"
              autoComplete="none"
              placeholder="Email"
              name="EmailAddress"
              readOnly={readOnly}
              value={orderEntry.customer.EmailAddress}
              onChange={(e: any) => handleChange(e)}
              required
            />
            {emailError && <span className="errorText">{emailError}</span>}
          </div>
        </div>
        <div className="">
          {/* <div>
            <label title="Customer Address">Address</label>
          </div> */}
          <div className="relative flex items-center input-inline">
            {addresses.length > 0 ? <img src="/assets/contact.png" alt="contact" className="w-6 h-6 cursor-pointer" onClick={openAddressModal} /> : null}
            {/* <AddressAutocomplete
              // setShowPreviousAddresses={setShowPreviousAddresses}
              // showPreviousAddresses={showPreviousAddresses}
              type="text"
              autoComplete="none"
              readOnly={readOnly}
              placeholder="Address"
              value={selectedAddress}
              // value={orderEntry.customer.BillingAddress}
              name="BillingAddress"
              onChange={(e: any) => handleChange(e)}
              onPlaceSelected={onBillingAddressSelected}
              className=""
            /> */}
            <AddressAutocomplete
              type="text"
              autoComplete="none"
              readOnly={readOnly}
              placeholder="Address"
              value={orderEntry.customer.BillingAddress}
              name="BillingAddress"
              onChange={(e) => handleChange(e)}
              onPlaceSelected={onBillingAddressSelected}
              className="!pr-8 !w-full"
            />
            <div className="absolute inset-y-0 flex right-3">
              <button
                disabled={readOnly}
                onClick={() => {
                  setGoogleModal(true);
                }}
                className="btn text-blue-500 btn-text">
                <FaMapMarkerAlt className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <React.Fragment>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none aria-hidden: focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="relative flex-auto">
                  <div className="p-6 text-center">
                    <svg
                      aria-hidden="true"
                      className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                      Are you sure you want to Add/Update this customer?
                    </h3>
                    <div className="flex justify-between">
                      <button
                        onClick={() => {
                          setShowModal(false);
                        }}
                        className="btn text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        No, cancel
                      </button>
                      <button
                        onClick={() => {
                          updateCustomer();
                        }}
                        className="btn text-white bg-orange-500 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium items-center px-5 py-2.5 text-center">
                        Yes, I'm sure
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-50"></div>
        </React.Fragment>
      ) : null}

      {googleModal && <RouteDialog setGoogleModal={setGoogleModal} useBillingAddress={true} />}
    </div>
  );
};

export default CustomerInfo;
