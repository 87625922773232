import { Bars3Icon } from "@heroicons/react/24/outline";
import { useLayout } from "providers/LayoutProvider";
import NotificationStats from "./components/NotificationStats";
import Search from "./components/Search";

const Navbar = () => {
  const { toggleMobileMenuVisible } = useLayout();

  return (
    <div className="navbar">
      <div className="p-2 border-r lg:hidden">
        <Bars3Icon
          className="w-8 h-8 text-gray-500 hover:text-primary transition cursor-pointer"
          aria-hidden="true"
          onClick={toggleMobileMenuVisible}
        />
      </div>
      <div className="grow flex justify-between items-center">
        <Search />
        <NotificationStats />
      </div>
    </div>
  );
};

export default Navbar;
