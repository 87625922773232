export const generateEmptyOrderEntry = () => ({
  OrderNumber: 0,
  customer: {
    PhoneNumber: "",
    CustomerName: "",
    EmailAddress: "",
    BillingAddress: "",
    CustomerNotes: "",
    DateAdded: "",
    AddedBy: "",
    LastModified: "",
    LastModifiedBy: ""
  },
  delivery_contact: {
    DeliveryContactPhone: "",
    DeliveryContact: "",
    DeliveryAddress: "",
    DeliveryNotes: ""
  },
  order_details: {
    DumpSterSize: "",
    NumberOfDays: "",
    MilesOneWay: 0,
    Shingles: 0,
    Concrete: 0,
    LaborMisc: 0,
    Discount: 0,
    DeliveryDate: "",
    PickupDate: "",
    MorningAfternoon: "",
    OrderNotes: "",
    AdditionalDayCost: null,
    ExtraMilesCost: null
  },
  summary: {
    ProductCost: 0,
    TrailerCost: 0,
    DaysCost: 0,
    MileageCost: 0,
    ShinglesCost: 0,
    ConcreteCost: 0,
    LaborMiscCost: 0,
    TotalCost: 0,
    Payments: 0,
    BalanceCost: 0
  },
  order_info: {
    OrderDate: "",
    Status: "Quote",
    CreatedOn: "",
    CreatedBy: "",
    LastModifiedOn: "",
    LastModifiedBy: "",
    DeliveredTimestamp: "",
    DeliveredBy: "",
    PickedUpTimestamp: "",
    PickedUpby: "",
    PaymentType: "",
    isPickUp: false
  }
});

export const generateOrderEntryFromOrder = (order: any) => {
  return {
    OrderNumber: order.OrderNumber,
    customer: {
      PhoneNumber: order.PhoneNumber,
      Password: order.Password,
      CustomerName: order.CustomerName,
      EmailAddress: order.EmailAddress,
      BillingAddress: order.BillingAddress,
      CustomerNotes: order.CustomerNotes,
      DateAdded: order.DateAdded,
      AddedBy: order.AddedBy,
      LastModified: order.LastModified,
      LastModifiedBy: order.LastModifiedBy
    },
    delivery_contact: {
      DeliveryContactPhone: order.DeliveryContactPhone,
      DeliveryContact: order.DeliveryContact,
      DeliveryAddress: order.DeliveryAddress,
      DeliveryNotes: order.DeliveryNotes
    },
    order_details: {
      DumpSterSize: order.DumpSterSize,
      NumberOfDays: order.NumberOfDays,
      MilesOneWay: order.MilesOneWay,
      Shingles: order.Shingles,
      Concrete: order.Concrete,
      LaborMisc: order.LaborMisc,
      Discount: order.Discount,
      AdditionalDayCost: order.AdditionalDayCost,
      ExtraMilesCost: order.ExtraMilesCost,
      DeliveryDate: order.DeliveryDate,
      PickupDate: order.PickupDate,
      MorningAfternoon: order.MorningAfternoon,
      OrderNotes: order.OrderNotes
    },
    summary: {
      ProductCost: order.ProductCost,
      TrailerCost: order.TrailerCost,
      DaysCost: order.DaysCost,
      MileageCost: order.MileageCost,
      ShinglesCost: order.ShinglesCost,
      ConcreteCost: order.ConcreteCost,
      LaborMiscCost: order.LaborMiscCost,
      TotalCost: order.TotalCost,
      Payments: order.Payments,
      BalanceCost: order.BalanceCost
    },
    order_info: {
      OrderDate: order.OrderDate,
      Status: order.Status,
      CreatedOn: order.CreatedOn,
      CreatedBy: order.CreatedBy,
      LastModifiedOn: order.LastModifiedOn,
      LastModifiedBy: order.LastModifiedBy,
      DeliveredTimestamp: order.DeliveredTimestamp,
      DeliveredBy: order.DeliveredBy,
      PickedUpTimestamp: order.PickedUpTimestamp,
      PickedUpby: order.PickedUpby,
      PaymentType: order.PaymentType,
      isPickUp: order.isPickUp
    }
  };
};

export const truncateNumber = (val: number, digitCount: number = 2) => {
  return +val.toFixed(2);
};

export const calculateSummaryTotals = (entry: any, taxPercent: number) => {
  const sum = truncateNumber(
    +entry.summary.ProductCost +
    +entry.summary.DaysCost +
    +entry.summary.MileageCost +
    +entry.summary.ShinglesCost +
    +entry.summary.ConcreteCost +
    +entry.summary.LaborMiscCost
  );

  const tax = truncateNumber(sum * (taxPercent / 100));

  const totalCost = truncateNumber(sum + tax);

  entry.summary.TrailerCost = tax;
  entry.summary.TotalCost = totalCost;
  entry.summary.BalanceCost = truncateNumber(totalCost - entry.summary.Payments);

  return entry;
};
