import { Fragment, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { usePaymentDialogContext } from "./PaymentDialogContextProvider";
import { PAYMENT_TYPES } from "config/constants";
import { FaSpinner } from "react-icons/fa";

const PaymentDialog = () => {
  const { visible, close, payment, updatePaymentField, saving, save } = usePaymentDialogContext();

  const handleChange = useCallback(
    ({ target: { name, value } }: any) => {
      updatePaymentField(name, value);
    },
    [updatePaymentField]
  );

  return (
    <Transition show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-[60]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black opacity-50" aria-hidden="true"></div>
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-6 md:p-8">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Panel
                className={`relative w-[500px] max-sm:w-[300px] rounded-xl bg-white p-6 shadow-xl shadow-white/5 md:p-8`}>
                <div className="space-y-4 text-center">
                  <div className="w-full  h-20 flex justify-center ">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                      <path d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zM272 192H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16s7.2-16 16-16zM256 304c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16zM164 152v13.9c7.5 1.2 14.6 2.9 21.1 4.7c10.7 2.8 17 13.8 14.2 24.5s-13.8 17-24.5 14.2c-11-2.9-21.6-5-31.2-5.2c-7.9-.1-16 1.8-21.5 5c-4.8 2.8-6.2 5.6-6.2 9.3c0 1.8 .1 3.5 5.3 6.7c6.3 3.8 15.5 6.7 28.3 10.5l.7 .2c11.2 3.4 25.6 7.7 37.1 15c12.9 8.1 24.3 21.3 24.6 41.6c.3 20.9-10.5 36.1-24.8 45c-7.2 4.5-15.2 7.3-23.2 9V360c0 11-9 20-20 20s-20-9-20-20V345.4c-10.3-2.2-20-5.5-28.2-8.4l0 0 0 0c-2.1-.7-4.1-1.4-6.1-2.1c-10.5-3.5-16.1-14.8-12.6-25.3s14.8-16.1 25.3-12.6c2.5 .8 4.9 1.7 7.2 2.4c13.6 4.6 24 8.1 35.1 8.5c8.6 .3 16.5-1.6 21.4-4.7c4.1-2.5 6-5.5 5.9-10.5c0-2.9-.8-5-5.9-8.2c-6.3-4-15.4-6.9-28-10.7l-1.7-.5c-10.9-3.3-24.6-7.4-35.6-14c-12.7-7.7-24.6-20.5-24.7-40.7c-.1-21.1 11.8-35.7 25.8-43.9c6.9-4.1 14.5-6.8 22.2-8.5V152c0-11 9-20 20-20s20 9 20 20z" />
                    </svg>
                  </div>
                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    Are you sure you want to add this Payment?
                  </h3>

                  <div className="form-control">
                    <div className="label-inline">
                      <label>Payment Type</label>
                    </div>
                    <div className="input-inline">
                      <select
                        placeholder="Select"
                        name="PaymentType"
                        value={payment?.PaymentType}
                        onChange={handleChange}>
                        <option value="" disabled hidden>
                          Select Payment Type
                        </option>
                        {PAYMENT_TYPES.map((type, i) => (
                          <option key={`payment-type-item-${i}`} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="form-control">
                    <div className="label-inline">
                      <label>Amount</label>
                    </div>
                    <div className="input-inline">
                      <input
                        type="number"
                        name="PaymentAmount"
                        placeholder="Enter Amount"
                        value={payment.PaymentAmount}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-control !items-start">
                    <div className="label-inline pt-2">
                      <label>Payment Note</label>
                    </div>
                    <div className="input-inline">
                      <textarea
                        className="w-full h-[100px]"
                        name="PaymentNote"
                        placeholder="Enter Payment Note"
                        value={payment.PaymentNote}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <button
                      onClick={close}
                      disabled={saving}
                      className="btn text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                      Cancel
                    </button>
                    <button
                      onClick={save}
                      disabled={saving}
                      className="btn text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium items-center px-5 py-2.5 text-center">
                      {saving && <FaSpinner className="w-4 h-4 mr-1" />} Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PaymentDialog;
