import { AUTH_TOKEN_NAME, EMPTY_TOKEN } from "config/constants";
import { SetValue } from "config/types";
import React, { ReactNode, useCallback, useContext, useEffect, useMemo } from "react";
import { useBoolean, useLocalStorage } from "usehooks-ts";

interface AuthContext {
  token: string;
  storeToken: SetValue<string>;
  clearToken: () => void;
  checking: boolean;
  authenticated: boolean;
}
// eslint-disable-next-line
const AuthContext: React.Context<AuthContext> = React.createContext<AuthContext>({
  token: "",
  storeToken: () => {},
  clearToken: () => {},
  checking: false,
  authenticated: false
});

interface AuthProvider {
  children?: ReactNode;
}
// eslint-disable-next-line
export function AuthProvider({ children }: AuthProvider) {
  const [token, storeToken] = useLocalStorage(AUTH_TOKEN_NAME, EMPTY_TOKEN);

  const authenticated = useMemo(() => token !== null && token !== EMPTY_TOKEN, [token]);

  const { value: checking, setValue: setChecking } = useBoolean(true);

  const clearToken = useCallback(() => {
    storeToken(EMPTY_TOKEN);
  }, [storeToken]);

  useEffect(() => {
    setChecking(false);
  }, [setChecking]);

  return (
    <AuthContext.Provider value={{ token, clearToken, storeToken, checking, authenticated }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = (): AuthContext => {
  const context = useContext<AuthContext>(AuthContext);
  return context;
};
