import {
  FaBorderAll,
  FaEdit,
  FaRegTrashAlt,
  FaUserPlus,
  FaProductHunt,
  FaCog,
  FaQuinscape,
  FaFolderPlus,
} from "react-icons/fa";
import { baseURL } from "config";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import React from "react";

interface OrderSetting_interface {
  ItemDescription: string;
  Description: string;
  Value: string;
  TicketDescription: string;
  ID: number;
  NoteDescription: string;
}

const OrderEntrySetting = () => {

  const [currentItem, setCurrentItem] = useState<OrderSetting_interface>(Object);
  const [currentProductItem, setCurrentProductItem] = useState<OrderSetting_interface>(Object);
  const [currentNoteItem, setCurrentNoteItem] = useState<OrderSetting_interface>(Object);

  const [orderDetailData, setOrderDetailData] = useState<OrderSetting_interface[]>([]);
  const [ProductData, setProductData] = useState<OrderSetting_interface[]>([]);
  const [NoteData, setNoteData] = useState<OrderSetting_interface[]>([]);

  const [orderDetailEditModal, setOrderDetailEditModal] = useState(false);
  const [productEditModal, setProductEditModal] = useState(false);
  const [noteEditModal, setNoteEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [noteDeleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    getOrderSetting();
    getQuickNotes();
    // eslint-disable-next-line
  }, []);

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }
console.log(currentProductItem)
  const getQuickNotes = async () => {
    const quicNotes = await axios.get(`${baseURL}/quicknotes`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    if (!quicNotes.data.success) return;
    setNoteData(quicNotes.data.data);
  }

  const getOrderSetting = async () => {
    const settingData = await axios.get(`${baseURL}/setting/order-entry`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    console.log("Settingdata", settingData);
    if (!settingData.data.success) return;
    setOrderDetailData(
      settingData.data.data.filter(
        (item: OrderSetting_interface) => item["TicketDescription"] === "OrderDetail"
      )
    );
    setProductData(
      settingData.data.data.filter(
        (item: OrderSetting_interface) => item["TicketDescription"] === "Product"
      )
    );
  };

  const updateOrderDetail = async () => {
    if (!!!(currentItem.ItemDescription && currentItem.Value)) {
      toast.error("Please fill in all fields");
      return;
    }
    const updatedItem = await axios.put(
      `${baseURL}/setting/order-entry/${currentItem.ID}`,
      currentItem, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    }
    );
    if (updatedItem) {
      getOrderSetting();
      setOrderDetailEditModal(false);
      toast.success("Updated Successfully");
    } else {
      toast.error("Failed to Update");
    }
  };

  const updateProduct = async () => {
    if (!!!(currentProductItem.ItemDescription && currentProductItem.Value)) {
      toast.error("Please fill all fields");
      return;
    }

    if (currentProductItem.ID === 0) {
      const data = {
        ItemDescription: currentProductItem.ItemDescription,
        Value: currentProductItem.Value,
        TicketDescription: currentProductItem.TicketDescription
      };
      const createdProduct = await axios.post(`${baseURL}/setting/order-entry/`, data, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      });
      if (createdProduct) {
        getOrderSetting();
        setProductEditModal(false);
        toast.success("Created Successfully");
      }
    } else {
      const updatedProduct = await axios.put(
        `${baseURL}/setting/order-entry/${currentProductItem.ID}`,
        currentProductItem, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      }
      );
      if (updatedProduct) {
        getOrderSetting();
        setProductEditModal(false);
        toast.success("Updated Successfully");
      } else {
        toast.error("Failed to Update");
      }
    }
  };

  const updateNote = async () => {
    if (currentNoteItem.ID === 0) {
      const data = {
        NoteDescription: currentNoteItem.NoteDescription,
      };
      const createdProduct = await axios.post(`${baseURL}/quicknotes/`, data, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      });
      if (createdProduct) {
        getQuickNotes();
        setNoteEditModal(false);
        toast.success("Created Successfully");
      }
    } else {
      const updatedProduct = await axios.put(
        `${baseURL}/quicknotes/${currentNoteItem.ID}`,
        currentNoteItem, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      }
      );
      if (updatedProduct) {
        getQuickNotes();
        setNoteEditModal(false);
        toast.success("Updated Successfully");
      } else {
        toast.error("Failed to Update");
      }
    }
  }

  const deleteProduct = async () => {
    const deletedProduct = await axios.delete(
      `${baseURL}/setting/order-entry/${currentProductItem.ID}`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    }
    );
    if (deletedProduct) {
      getOrderSetting();
      setDeleteModal(false);
      toast.success("Deleted Successfully");
    } else {
      toast.error("Failed to Delete");
    }
  };

  const handleChange = (e: any) => {
    setCurrentItem({
      ...currentItem,
      Value: e.target.value
    });
  };

  const handleChangeProduct = (e: any) => {
    console.log(e)
    setCurrentProductItem({
      ...currentProductItem,
      [e.target.name]: e.target.value,
      TicketDescription: "Product"
    });
  };

  const handleChangeNote = (e: any) => {
    setCurrentNoteItem({
      ...currentNoteItem,
      NoteDescription: e.target.value,
    });
  }

  const deleteNote = async () => {
    const deletedProduct = await axios.delete(
      `${baseURL}/quicknotes/${currentNoteItem.ID}`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    }
    );
    if (deletedProduct) {
      getQuickNotes();
      setDeleteModal(false);
      toast.success("Deleted Successfully");
    } else {
      toast.error("Failed to Delete");
    }
  }

  return (
    <>
      <div className="flex items-center">
        <div className="">
          <h1 className="flex items-center font-bold md:text-xl">
            <FaCog className="h-6 mr-1 md:w-7 md:h-7 " /> Order Entry Settings
          </h1>
        </div>
      </div>

      <div className="grid w-full xl:grid-cols-3 lg:grid-cols-2 gap-4 md:grid-cols-2">
        <div className="w-full h-full">
          <div className="flex justify-between">
            <div>
              <h1 className="flex items-center font-bold md:text-xl mt-4">
                <FaBorderAll className="mb-1 mr-1 h-7 md:w-7 md:h-7" /> Order Detail
              </h1>
            </div>
          </div>
          <div className="w-full pt-2">
            <div className="flex">
              <div className="relative w-full">
                <div className="space-y-1 border-collapsen">
                  <table className="w-full">
                    <thead>
                      <tr className="orderEntrySetting">
                        <th>Item Description</th>
                        <th>Value</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetailData.map(({ ItemDescription, Value }, i) => (
                        <tr key={i} className="text-center ">
                          <td>{ItemDescription}</td>
                          <td>
                            {ItemDescription === "Sales Tax"
                              ? `${Value}%`
                              : ItemDescription === "2 Day Discount"
                                ? `$ (${Value})`
                                : ItemDescription === "Shingles Cost Per 10 Square"
                                  ? `$ ${Value}`
                                  : Value}
                          </td>
                          <td className="flex border-none justify-evenly">
                            <button
                              className="text-blue-500 hover:text-blue-700 btn-text"
                              onClick={() => {
                                setCurrentItem(orderDetailData[i]);
                                setOrderDetailEditModal(true);
                              }}>
                              <FaEdit className="w-6 h-6" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-full">
          <div className="flex justify-between mt-4">
            <div>
              <h1 className="flex items-center font-bold md:text-xl ">
                <FaProductHunt className="w-1/2 mr-1 h-7 md:w-7 md:h-7" /> Products
              </h1>
            </div>
            <button
              className="bg-orange-500 btn btn-icon hover:bg-orange-700"
              onClick={() => {
                setProductEditModal(true);
                setCurrentProductItem({
                  ...currentProductItem,
                  ItemDescription: "",
                  Value: "",
                  ID: 0
                });
              }}>
              <FaUserPlus className="w-4 h-4" />
            </button>
          </div>
          <div className="w-full pt-2">
            <div className="flex">
              <div className="relative w-full">
                <div className="space-y-1 border-collapsen">
                  <table className="w-full">
                    <thead>
                      <tr className="orderEntrySetting">
                        <th>Product Description</th>
                        <th>Value</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ProductData.map(({ ItemDescription, Value }, i) => (
                        <tr key={i} className="text-center ">
                          <td>{ItemDescription}</td>
                          <td>{Value}</td>
                          <td className="flex border-none justify-evenly">
                            <button
                              className="text-blue-500 hover:text-blue-700 btn-text"
                              onClick={() => {
                                setProductEditModal(true);
                                setCurrentProductItem(ProductData[i]);
                              }}>
                              <FaEdit className="w-6 h-6" />
                            </button>
                            <button
                              className="ml-1 text-red-500 btn-text"
                              onClick={() => {
                                setShowDeleteModal(true);
                                setCurrentProductItem(ProductData[i]);
                              }}>
                              <FaRegTrashAlt className="w-6 h-6" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-full">
          <div className="flex justify-between mt-4">
            <div>
              <h1 className="flex items-center font-bold md:text-xl ">
                <FaQuinscape className="w-1/2 mr-1 h-7 md:w-7 md:h-7" /> Quick Notes
              </h1>
            </div>
            <button
              className="bg-orange-500 btn btn-icon hover:bg-orange-700"
              onClick={() => {
                setNoteEditModal(true);
                setCurrentNoteItem({
                  ...currentNoteItem,
                  NoteDescription: "",
                  ID: 0
                });
              }}>
              <FaFolderPlus className="w-4 h-4" />
            </button>
          </div>
          <div className="w-full pt-2">
            <div className="flex">
              <div className="relative w-full">
                <div className="space-y-1 border-collapsen">
                  <table className="w-full">
                    <thead>
                      <tr className="orderEntrySetting">
                        <th>Product Description</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {NoteData.map(({ NoteDescription, ID }, i) => (
                        <tr key={i} className="text-center ">
                          <td>{NoteDescription}</td>
                          <td className="flex border-none justify-evenly">
                            <button
                              className="text-blue-500 hover:text-blue-700 btn-text"
                              onClick={() => {
                                setNoteEditModal(true);
                                setCurrentNoteItem(NoteData[i]);
                              }}>
                              <FaEdit className="w-6 h-6" />
                            </button>
                            <button
                              className="ml-1 text-red-500 btn-text"
                              onClick={() => {
                                setDeleteModal(true);
                                setCurrentNoteItem(NoteData[i]);
                              }}>
                              <FaRegTrashAlt className="w-6 h-6" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {orderDetailEditModal ? (
        <React.Fragment>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none aria-hidden: focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="relative flex-auto">
                  <div className="p-6 space-y-4 text-center">
                    <svg
                      aria-hidden="true"
                      className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                      Are you sure you want to edit this item?
                    </h3>

                    <div className="form-control">
                      <div className="label-inline">
                        <label>Item Description</label>
                      </div>
                      <div className="text-lg input-inline">
                        {/* <input type="number" name="ItemDescription" placeholder='Enter Description ' /> */}
                        {currentItem?.ItemDescription}
                      </div>
                    </div>

                    <div className="form-control">
                      <div className="label-inline">
                        <label>Value</label>
                      </div>
                      <div className="input-inline">
                        <input
                          type="number"
                          name="Value"
                          min={0}
                          placeholder="Enter Value"
                          value={currentItem?.Value}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <button
                        onClick={() => {
                          setOrderDetailEditModal(false);
                          setCurrentItem(Object);
                        }}
                        className="btn text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        No, cancel
                      </button>
                      <button
                        onClick={() => {
                          updateOrderDetail();
                        }}
                        className="btn text-white bg-orange-500 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium items-center px-5 py-2.5 text-center">
                        Yes, I'm sure
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-50"></div>
        </React.Fragment>
      ) : null}
      {productEditModal ? (
        <React.Fragment>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none aria-hidden: focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="relative flex-auto">
                  <div className="p-6 space-y-4 text-center">
                    <svg
                      aria-hidden="true"
                      className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                      Are you sure you want to Add/Edit this Product?
                    </h3>

                    <div className="form-control">
                      <div className="label-inline">
                        <label>Product Description</label>
                      </div>
                      <div className="input-inline">
                        <input
                          type="text"
                          name="ItemDescription"
                          placeholder="Enter Product Size "
                          value={currentProductItem?.ItemDescription}
                          onChange={(e) => handleChangeProduct(e)}
                        />
                      </div>
                    </div>

                    <div className="form-control">
                      <div className="label-inline">
                        <label>Value</label>
                      </div>
                      <div className="input-inline">
                        <input
                          type="number"
                          name="Value"
                          placeholder="Enter Value"
                          min={0}
                          value={currentProductItem?.Value}
                          onChange={(e) => handleChangeProduct(e)}
                        />
                      </div>
                    </div>
                    <div className="form-control">
                      <div className="label-inline">
                        <label>Inv. Desc.</label>
                      </div>
                      <div className="input-inline">
                        <input
                          type="text"
                          name="Description"
                          placeholder="Enter Product Details "
                          value={currentProductItem?.Description}
                          onChange={(e) => handleChangeProduct(e)}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <button
                        onClick={() => {
                          setProductEditModal(false);
                        }}
                        className="btn text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        No, cancel
                      </button>
                      <button
                        onClick={() => {
                          updateProduct();
                        }}
                        className="btn text-white bg-orange-500 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium items-center px-5 py-2.5 text-center">
                        Yes, I'm sure
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-50"></div>
        </React.Fragment>
      ) : null}
      {noteEditModal ? (
        <React.Fragment>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none aria-hidden: focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="relative flex-auto">
                  <div className="p-6 space-y-4 text-center">
                    <svg
                      aria-hidden="true"
                      className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                      Are you sure you want to Add/Edit this Product? x
                    </h3>

                    <div className="form-control">
                      <div className="label-inline">
                        <label>Note Description</label>
                      </div>
                      <div className="input-inline">
                        <input
                          type="text"
                          name="NoteDescription"
                          placeholder="Enter Product Size "
                          value={currentNoteItem?.NoteDescription}
                          onChange={(e) => handleChangeNote(e)}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <button
                        onClick={() => {
                          setNoteEditModal(false);
                        }}
                        className="btn text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        No, cancel
                      </button>
                      <button
                        onClick={() => {
                          updateNote();
                        }}
                        className="btn text-white bg-orange-500 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium items-center px-5 py-2.5 text-center">
                        Yes, I'm sure
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-50"></div>
        </React.Fragment>
      ) : null}
      {showDeleteModal ? (
        <React.Fragment>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none aria-hidden: focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="relative flex-auto">
                  <div className="p-6 text-center">
                    <svg
                      aria-hidden="true"
                      className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                      Are you sure you want to delete this product?
                    </h3>
                    <div className="flex justify-between">
                      <button
                        onClick={() => {
                          setShowDeleteModal(false);
                        }}
                        className="btn text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        No, cancel
                      </button>
                      <button
                        onClick={() => {
                          deleteProduct();
                        }}
                        className="btn text-white bg-orange-500 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium items-center px-5 py-2.5 text-center">
                        Yes, I'm sure
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-50"></div>
        </React.Fragment>
      ) : null}
      {noteDeleteModal ? (
        <React.Fragment>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none aria-hidden: focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="relative flex-auto">
                  <div className="p-6 text-center">
                    <svg
                      aria-hidden="true"
                      className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                      Are you sure you want to delete this product?
                    </h3>
                    <div className="flex justify-between">
                      <button
                        onClick={() => {
                          setDeleteModal(false);
                        }}
                        className="btn text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        No, cancel
                      </button>
                      <button
                        onClick={() => {
                          deleteNote();
                        }}
                        className="btn text-white bg-orange-500 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium items-center px-5 py-2.5 text-center">
                        Yes, I'm sure
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-50"></div>
        </React.Fragment>
      ) : null}
    </>
  );
};

export default OrderEntrySetting;
