
interface Actives {
  item: string;
  value: string;

}

const Item = () => {
  // const onChange = (string:val) => {
  //   return val;
  // };

  const actives: Actives[] = [
    { item: "Sales Tax", value: "0%" },
    { item: "Cost Per Mile", value: "1.15" },
    { item: "Conerete Cost Per Ton", value: "25"},
    { item: "2 Day DisCount", value: "$  (50.00)" },
    { item: "Shingles Cost Per 10 Square", value: "$ 30.00"},
    { item: "Sales Tax", value: "0%" },
    { item: "Cost Per Mile", value: "1.15" },
    { item: "Conerete Cost Per Ton", value: "25"},
    { item: "2 Day DisCount", value: "$  (50.00)" },
    { item: "Shingles Cost Per 10 Square", value: "$ 30.00"},
    { item: "Sales Tax", value: "0%" },
    { item: "Cost Per Mile", value: "1.15" },
    { item: "Conerete Cost Per Ton", value: "25"},
    { item: "2 Day DisCount", value: "$  (50.00)" },
  ];

  return (
    <div className='flex'>
      <div className="relative w-full">
        <div className="space-y-1  border-collapsen">
          <div>
            <table className='w-full'>
              <thead>
                <tr>
                  <th className='text-center'>Item Description</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {actives.map(({ item, value}, i) => (
                  <tr key={i} className='text-center '>
                    <td className='text-center'>{item}</td>
                    <td>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
