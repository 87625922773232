import { useOrderContext } from "./OrderContextProvider";

const CustomerNotes = () => {
  const { orderEntry, updateOrderEntryField, readOnly } = useOrderContext();

  const handleChange = (e: any) => {
    updateOrderEntryField("customer", e.target.name, e.target.value);
  };

  return (
    <div className="panel">
      <div className="flex items-center justify-between panel-header">
        <h3 className="flex items-center font-bold">Customer Notes</h3>
      </div>
      <div className="panel-body !p-0">
        <textarea
          className="block w-full  h-full rounded-none focus:outline-none border-none resize-none"
          rows={5}
          readOnly={readOnly}
          placeholder="Note any additional info related to the customer..."
          name="CustomerNotes"
          value={orderEntry.customer.CustomerNotes}
          onChange={(e) => handleChange(e)}></textarea>
      </div>
    </div>
  );
};

export default CustomerNotes;
