import { FaUser } from "react-icons/fa";
import UserProfile from "./UserProfile";

const UserManage = () => {
  return (
    <>
      <div className="flex items-center">
        <div>
          <h1 className="flex items-center h-full font-bold md:text-xl">
            <FaUser className="w-1/2 h-6 mr-1 md:w-7 md:h-7 " /> User
          </h1>
        </div>
      </div>
      <div className="justify-center w-full h-full ">
        <div className="relative w-full h-full">
          <div className="w-full mt-4">
            <UserProfile />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManage;
