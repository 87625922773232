import { FaInstalod, FaMapMarkerAlt, FaRegCopy } from "react-icons/fa";
// import Input from "react-phone-number-input/input";
import { useOrderContext } from "./OrderContextProvider";
import { useCallback, useState } from "react";
import RouteDialog from "./RouteDialog";
import AddressAutocomplete from "./AddressAutocomplete";


interface myProps {
  openDeliveryAddressModal: any;
  closeDeliveryAddressModal: any;
  addresses: any;
  selectedAddress: any;
}

const DeliveryInfo: React.FC<myProps> = ({ openDeliveryAddressModal, closeDeliveryAddressModal, addresses, selectedAddress }) => {
  const { orderEntry, updateOrderEntryField, copyContact, readOnly } = useOrderContext();
  const [googleModal, setGoogleModal] = useState(false);

  const handleChange = (e: any) => {
    updateOrderEntryField("delivery_contact", e.target.name, e.target.value);
  };

  const onDeliveryAddressSelected = useCallback(
    (address: string) => updateOrderEntryField("delivery_contact", "DeliveryAddress", address),
    [updateOrderEntryField]
  );

  const copyPhoneNumber = () => {
    updateOrderEntryField(
      "delivery_contact",
      "DeliveryContactPhone",
      orderEntry.customer.PhoneNumber
    );
  };

  const copyName = () => {
    updateOrderEntryField("delivery_contact", "DeliveryContact", orderEntry.customer.CustomerName);
  };

  const copyAddress = () => {
    updateOrderEntryField(
      "delivery_contact",
      "DeliveryAddress",
      orderEntry.customer.BillingAddress
    );
  };

  return (
    <div className="panel ">
      <div className="flex justify-between panel-header">
        <h3 className="flex items-center font-bold">
          <FaInstalod className="w-4 h-4 mr-1" />
          Delivery Contact
        </h3>
        <div className="text-right">
          <button
            className="bg-orange-500 btn btn-icon hover:bg-orange-700 disabled:bg-orange-500 !p-1"
            disabled={readOnly}
            onClick={copyContact}>
            <FaRegCopy className="w-3 h-3" />
          </button>
        </div>
      </div>
      <div className="space-y-1 panel-body">
        <div className="space-y-4">
          <div className="form-control">
            <div>
              <label title="Deliverer Phone">Phone</label>
            </div>
            <div className="relative input-inline">
              <input
                type="text"
                placeholder="Phone Number"
                name="DeliveryContactPhone"
                maxLength={10}
                readOnly={readOnly}
                value={orderEntry.delivery_contact.DeliveryContactPhone}
                onChange={(e: any) => handleChange(e)}
                className="!pr-8"
              />
              {/* <Input
                required
                disabled={readOnly}
                autoComplete="none"
                placeholder="Phone Number"
                value={orderEntry.delivery_contact.DeliveryContactPhone}
                onChange={(value) => setPhoneNumber(value)}
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                minLength="12"
                maxLength="12"
                international
                country="US"
              /> */}
              <div className="absolute inset-y-0 flex items-center right-3">
                <button
                  className="inline-block text-orange-500 btn btn-text"
                  disabled={readOnly}
                  onClick={() => copyPhoneNumber()}>
                  <FaRegCopy className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
          <div className="form-control">
            <div>
              <label title="Deliverer Name">Name</label>
            </div>
            <div className="relative input-inline">
              <input
                autoComplete="none"
                readOnly={readOnly}
                type="text"
                placeholder="Name"
                value={orderEntry.delivery_contact.DeliveryContact}
                name="DeliveryContact"
                onChange={(e: any) => handleChange(e)}
                className="!pr-8"
              />
              <div className="absolute inset-y-0 flex items-center right-3">
                <button
                  className="text-orange-500 btn btn-text"
                  disabled={readOnly}
                  onClick={() => copyName()}>
                  <FaRegCopy className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
          <div className="">
            {/* <div>
              <label title="Deliverer Address">Address</label>
            </div> */}
            <div className="relative flex items-center input-inline">
              {/* <GoogleAutoComplete
              options={{ types: ["address"] }}
              apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
              type="text"
              id="txtSource"
              defaultValue={orderEntry.delivery_contact.DeliveryAddress}
            /> */}
              {addresses.length > 0 ? <img src="/assets/contact.png" alt="contact" className="w-6 h-6 cursor-pointer" onClick={openDeliveryAddressModal} /> : null}
              <AddressAutocomplete
                type="text"
                autoComplete="none"
                placeholder="Address"
                readOnly={readOnly}
                value={selectedAddress || orderEntry.delivery_contact.DeliveryAddress}
                // value={selectedAddress}
                name="DeliveryAddress"
                onChange={(e: any) => handleChange(e)}
                onPlaceSelected={onDeliveryAddressSelected}
                className="!pr-[52px]"
              />
              <div className="absolute inset-y-0 flex right-8">
                <button
                  disabled={readOnly}
                  onClick={() => {
                    setGoogleModal(true);
                  }}
                  className="text-blue-500 btn btn-text">
                  <FaMapMarkerAlt className="w-4 h-4" />
                </button>
              </div>
              <div className="absolute inset-y-0 flex right-3">
                <button
                  className="text-orange-500 btn btn-text"
                  disabled={readOnly}
                  onClick={() => copyAddress()}>
                  <FaRegCopy className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {googleModal && <RouteDialog setGoogleModal={setGoogleModal} />}
    </div>
  );
};

export default DeliveryInfo;
