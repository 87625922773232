import { EMPTY_SYSTEM_SETTINGS, SIDEBAR_MINIMIZE_TOGGLE_NAME } from "config/constants";
import { SetValue, SystemSettings } from "config/types";
import React, { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useLocalStorage, useToggle } from "usehooks-ts";
import axios from "utils/axios.helper";

interface LayoutContext {
  sidebarMinimized: boolean;
  toggleSidebarMinimized: () => void;
  
  mobileMenuVisible: boolean;
  toggleMobileMenuVisible: () => void;

  searchFocused: boolean,
  setSearchFocused: SetValue<boolean>,

  notesPanelVisible: boolean;
  toggleNotesPanel: () => void;
  openNotesPanel: () => void;
  closeNotesPanel: () => void;

  settings: SystemSettings;
  refreshSettings: () => void;
}
// eslint-disable-next-line
const LayoutContext: React.Context<LayoutContext> = React.createContext<LayoutContext>({
  sidebarMinimized: false,
  toggleSidebarMinimized: () => {},

  mobileMenuVisible: false,
  toggleMobileMenuVisible: () => {},

  searchFocused: false,
  setSearchFocused: () => {},

  notesPanelVisible: false,
  toggleNotesPanel: () => {},
  openNotesPanel: () => {},
  closeNotesPanel: () => {},

  settings: EMPTY_SYSTEM_SETTINGS,
  refreshSettings: () => {}
});

interface LayoutProvider {
  children: ReactNode;
}
// eslint-disable-next-line
export function LayoutProvider({ children }: LayoutProvider) {
  const [sidebarMinimized, setSidebarMinimized] = useLocalStorage<boolean>(SIDEBAR_MINIMIZE_TOGGLE_NAME, false);
  const toggleSidebarMinimized = useCallback(() => setSidebarMinimized((toggle) => !toggle), [setSidebarMinimized]);

  const [mobileMenuVisible, toggleMobileMenuVisible] = useToggle();

  const [searchFocused, setSearchFocused] = useState<boolean>(false);

  const [notesPanelVisible, setNotesPanelVisible] = useState<boolean>(false);
  const toggleNotesPanel = useCallback(() => setNotesPanelVisible((toggle) => !toggle), [setNotesPanelVisible]);
  const openNotesPanel = useCallback(() => setNotesPanelVisible(true), [setNotesPanelVisible]);
  const closeNotesPanel = useCallback(() => setNotesPanelVisible(false), [setNotesPanelVisible]);

  const [settings, setSettings] = useState<SystemSettings>(EMPTY_SYSTEM_SETTINGS);

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  const loadSystemSettings = useCallback(async () => {
    try {
      const response = await axios.get(`/setting/system`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    })
      .then(({ data }) => data);
      if (response.success) setSettings(response.data[0]);
      else throw response.message;
    } catch (error) {
      console.log(error);
    }
  }, [setSettings]);

  useEffect(() => {
    loadSystemSettings();
  }, [loadSystemSettings]);

  return (
    <LayoutContext.Provider
      value={{
        sidebarMinimized,
        toggleSidebarMinimized,

        mobileMenuVisible,
        toggleMobileMenuVisible,

        searchFocused,
        setSearchFocused,

        notesPanelVisible,
        toggleNotesPanel,
        openNotesPanel,
        closeNotesPanel,

        settings,
        refreshSettings: loadSystemSettings
      }}>
      {children}
    </LayoutContext.Provider>
  );
}

export const useLayout = (): LayoutContext => {
  const context = useContext<LayoutContext>(LayoutContext);
  return context;
};
