import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";

interface myProps {
  category: string[];
  series: serie[];
  mode: string;
}

// const ChartReport = ()=> {
const ChartReport : React.FunctionComponent<myProps> = ({category, series, mode}) =>{

    useEffect(() => {
      // console.log("category:::", category)
    }, [category])

    // const [series] = useState([
    //   {
    //     name: '2021',
    //     data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 45, 78, 188]
    //   }, {
    //     name: '2022',
    //     data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 65, 56, 45]
    //   }
    // ]);

    return (
          <div className="self-center h-full mixed-chart">
            <ReactApexChart
              options={{
                chart: {
                  type: 'bar',
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    // columnWidth: '55%',
                    barHeight:'100%',
                    dataLabels: {
                      position:'Top',
                    },
                  },
                },
                dataLabels: {
                  enabled: true,
                  // offsetY: 80,
                  style: {
                    fontSize: `${(mode === "YEAR" || mode === "10YEAR") ? '14px' : (mode === "MONTH"? '18px' : '30px')}`,
                    colors: ["#304758"],
                  },
                },                
                yaxis: {
                  tickAmount: 5,
                  labels: {
                    formatter: function(val) {
                      return 10.5.toFixed(0)
                    }
                  },
                },
                stroke: {
                  show: true,
                  // width: 8,
                  colors: ['transparent']
                },
                xaxis: {
                  categories: category,
                },
                fill: {
                  opacity: 1
                },
                tooltip: {
                  y: {
                    formatter: function (val:any) {
                      return "$ " + val 
                    }
                  }
                },
                labels:["Text"],
                colors: mode !== "YEAR" ? ['#00E396'] : ['#269FFB','#00E396']
                
              }}
            
              series={series}
              type="bar"
              // width="1400"
              height="100%"
            />
          </div>
    );
  }


export default ChartReport;