import { Account, Note, NotificationValues, Payment, SystemSettings } from "./types";

export const VERSION = "1.5.0";
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "TEST";

export const SIDEBAR_MINIMIZE_TOGGLE_NAME = "SIDEBAR-MINIMIZED";

// export const API_ENDPOINT = "http://localhost:4000/api";
export const API_ENDPOINT = "https://matrix.normfred.net/api";

export const AUTH_TOKEN_NAME = "USER";

export const EMPTY_TOKEN = "";

export const EMPTY_ACCOUNT: Account = {
  ID: 0,
  AccessType: "",
  Email: "",
  Name: "",
  Picture: "",
  accessToken: ""
};

export const EMPTY_SYSTEM_SETTINGS: SystemSettings = {
  companyAddress: "",
  companyCoverLogo: "",
  companySquareLogo: ""
};

export const EMPTY_NOTIFICATION_VALUES: NotificationValues = {
  pendingOrderCount: 0,
  deliveredOrderCount: 0,
  completedOrderCount: 0
};

export const EMPTY_ORDER_ID = 0;

export const EMPTY_PAYMENT_ID = "";

export const EMPTY_PAYMENT: Payment = {
  OrderNumber: EMPTY_ORDER_ID,
  PaymentDate: "",
  PaymentType: "",
  PaymentAmount: 0,
  PaymentNote: ""
};

export const SEARCH_TIMEOUT = 150;

export const EMPTY_NOTE: Note = {
  ID: 0,
  title: "",
  content: ""
};

export const PAYMENT_TYPES = [
  "CREDIT CARD - MAN",
  "CREDIT CARD - TAP",
  "CREDIT CARD - INV",
  "CASH",
  "CHECK",
  "CREDIT CARD - WEBSITE"
];
