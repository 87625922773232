import axios from "utils/axios.helper";
import { EMPTY_PAYMENT } from "config/constants";
import { Payment } from "config/types";
import React, { ReactNode, useCallback, useContext, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import PaymentDialog from "./PaymentDialog";

interface PaymentDialogContextValues {
  visible: boolean;
  open: (payment: Payment | number, onAfterSaved?: (payment: Payment, order: any) => void) => void;
  close: () => void;

  payment: Payment;
  updatePaymentField: (name: string, value: any) => void;

  saving: boolean;
  save: () => void;
}

const PaymentDialogContext: React.Context<PaymentDialogContextValues> =
  React.createContext<PaymentDialogContextValues>({
    visible: false,
    open: () => { },
    close: () => { },

    payment: { ...EMPTY_PAYMENT },
    updatePaymentField: () => { },

    saving: false,
    save: () => { }
  });

interface PaymentDialogContextProviderProps {
  children: ReactNode;
}

export function PaymentDialogContextProvider({ children }: PaymentDialogContextProviderProps) {
  const [visible, setVisible] = useState<boolean>(false);
  const [payment, setPayment] = useState<Payment>({ ...EMPTY_PAYMENT });

  const [saving, setSaving] = useState<boolean>(false);

  const [onAfterSaved, setOnAfterSaved] = useState(() => (payment: Payment, order: any) => { });

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  const open = useCallback(
    (payment: Payment | number, onAfterSaved?: (payment: Payment, order: any) => void) => {
      setPayment(
        typeof payment === "object" ? payment : { ...EMPTY_PAYMENT, OrderNumber: payment }
      );

      setOnAfterSaved(() => onAfterSaved);

      setVisible(true);
    },
    [setPayment, setOnAfterSaved, setVisible]
  );

  const close = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const updatePaymentField = useCallback(
    (name: string, value: string) => {
      setPayment((payment) => ({ ...payment, [name]: value }));
    },
    [setPayment]
  );

  const save = useCallback(async () => {
    const payload = { ...payment, PaymentAddedBy: userInfo?.Name, PaymentDate: moment(new Date()).format("YYYY-MM-DD") };

    setSaving(true);
    try {
      let request =
        payment.PaymentID
          ? axios.put(`/payment/${payment.PaymentID}`, payload, {
            headers: {
              Authorization: `Bearer ${userInfo?.accessToken}`,
            },
          }).then(({ data }) => data)
          : axios.post(`/payment/`, payload, {
            headers: {
              Authorization: `Bearer ${userInfo?.accessToken}`,
            },
          })
            .then(({ data }) => data);
      const response: any = await request;
      if (response.success) {
        toast.success(response.message);

        onAfterSaved && onAfterSaved(response.data, response.order);
      } else {
        toast.warn(response.message);
      }
      close();
    } catch (ex) {
      console.log(ex);
      toast.error("An error ocurred while saving payment info.");
    } finally {
      setSaving(false);
    }
  }, [payment, onAfterSaved, close]);

  return (
    <PaymentDialogContext.Provider
      value={{
        visible,
        open,
        close,

        payment,
        updatePaymentField,

        saving,
        save
      }}>
      {children}
      <PaymentDialog />
    </PaymentDialogContext.Provider>
  );
}

export const usePaymentDialogContext = (): PaymentDialogContextValues =>
  useContext<PaymentDialogContextValues>(PaymentDialogContext);
