import { baseURL } from "config";
import { Note, SetValue } from "config/types";
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import axios from "utils/axios.helper";
import { generateEmptyNote } from "utils/note.helper";

interface NoteContextValues {
  notes: Note[],
  setNotes: SetValue<Note[]>;

  activeNoteId: number,
  activeNote: Note,

  updateNoteField: (name: string, value: any) => void;

  selectNote: (noteId: number) => void;
  refreshNotes: () => void;
}

const NoteContext: React.Context<NoteContextValues> =
  React.createContext<NoteContextValues>({
    notes: [],
    setNotes: () => { },

    activeNoteId: 0,
    activeNote: generateEmptyNote(),

    updateNoteField: () => { },

    selectNote: () => { },
    refreshNotes: () => { }
  });

interface NoteContextProviderProps {
  children: ReactNode;
}

export function NoteContextProvider({ children }: NoteContextProviderProps) {
  const [notes, setNotes] = useState<Note[]>([]);
  const [activeNoteId, setActiveNoteId] = useState<number>(1);
  const activeNote = useMemo<Note>(() => !activeNoteId ? generateEmptyNote() : (notes.find(({ ID }) => ID === activeNoteId) || generateEmptyNote())
    , [notes, activeNoteId])

  // const []
  const selectNote = useCallback((noteId: number) => setActiveNoteId(noteId), [setActiveNoteId])

  const updateNoteField = useCallback((name: string, value: any) => {
    const updatedNote = { ...activeNote, [name]: value }
    setNotes(notes => notes.map(note => note.ID === activeNoteId ? updatedNote : note))
  }, [activeNoteId, activeNote, setNotes])

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }


  const loadNotes = useCallback(async () => {
    try {
      const response = await axios.get(`${baseURL}/notes/`, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })

        .then(({ data }) => data);
      setNotes(response.data);
    } catch (ex: any) {
      ex?.name !== 'CanceledError' && console.log(ex);
    }
  }, [setNotes, userInfo?.accessToken]);

  useEffect(() => {
    loadNotes()
  }, [loadNotes]);

  const loadNote = useCallback(async (signal: AbortSignal) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
        signal: signal
      };
      const response = await axios.get(`${baseURL}/notes/${activeNoteId}`, config)
        .then(({ data }) => data);
      response.data.length && setNotes(notes => notes.map(note => note.ID === activeNoteId ? response.data[0] : note))
    } catch (ex: any) {
      ex?.name !== 'CanceledError' && console.log(ex);
    }
  }, [activeNoteId, userInfo?.accessToken]);

  useEffect(() => {

    const abortController = new AbortController()
    loadNote(abortController.signal)

    return () => abortController.abort()
  }, [loadNote])

  return (
    <NoteContext.Provider
      value={{
        notes,
        setNotes,
        activeNoteId,
        activeNote,

        updateNoteField,

        selectNote,
        refreshNotes: loadNotes
      }}>
      {children}
    </NoteContext.Provider>
  );
}

export const useNoteContext = (): NoteContextValues => {
  const context = useContext<NoteContextValues>(NoteContext);
  return context;
};
