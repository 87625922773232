import React from "react";
import ReactDOM from "react-dom/client";
import "styles/global.scss";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalContextProvider } from "./context";
import { AccountProvider } from "providers/AccountProvider";
import { AuthProvider } from "providers/AuthProvider";
import { PaymentDialogContextProvider } from "components/payment/PaymentDialogContextProvider";
import { NotificationValuesProvider } from "providers/NotificationValuesProvider";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <GlobalContextProvider>
    <AuthProvider>
      <AccountProvider>
        <NotificationValuesProvider>
          <PaymentDialogContextProvider>
            <ToastContainer autoClose={1000} hideProgressBar={true} />
            <Routes />
          </PaymentDialogContextProvider>
        </NotificationValuesProvider>
      </AccountProvider>
    </AuthProvider>
  </GlobalContextProvider>
);
