import { FaListUl, FaSpinner } from "react-icons/fa";
import Pickup from "./components/Pickup";
import Completed from "./components/Completed";
import Delivery from "./components/Delivery";
import { Menu, MenuHandler, Button, MenuList, MenuItem, Checkbox } from "@material-tailwind/react";
import axios from "axios";
import { useState, useEffect } from "react";
import { baseURL } from "config";
import { useGlobalContext } from "context";

const ActiveOrders = () => {
  const [pendingData, setPendingData] = useState([]);
  const [deliverydata, SetDeliveryData] = useState([]);
  const [completedata, SetCompleteData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [delivery, setDelivery] = useState(true);
  const [complete, setComplete] = useState(true);
  const [pickup, setPickup] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  useEffect(() => {
    // getDelivers();
    getPen();
    getDel();
    getCom();
    getOrderSetting();
    // eslint-disable-next-line
  }, []);

  const { dispatch } = useGlobalContext();

  const getCom = () => {
    setLoading(true);
    axios
      .get(`${baseURL}/orders/getCom`, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })
      .then((response) => {
        // const completeOrder = response.data.data.filter((item: any) => item["Status"] === "Completed");
        // console.log(completeOrder)
        // const sortedCompleteOrder = completeOrder.sort((a: any, b: any) => {
        //   return new Date(b.PickedUpTimestamp).getTime() - new Date(a.PickedUpTimestamp).getTime()
        // })
        const completeOrder = response.data.data.filter((item: any) => item["Status"] === "Completed");

        const sortedCompleteOrder = completeOrder.sort((a: any, b: any) => {
          const dateA = new Date(a.PickedUpTimestamp);
          const dateB = new Date(b.PickedUpTimestamp);

          // Compare using getTime() to consider both date and time
          return dateB.getTime() - dateA.getTime();
        });
        SetCompleteData(sortedCompleteOrder);
      })
      .catch((err) => {
        console.log(err.err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPen = () => {
    setLoading(true);
    axios
      .get(`${baseURL}/orders/getPen`, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })
      .then((response) => {
        const pendingOrder = response.data.data.filter((item: any) => item["Status"] === "Pending");
        const sortedPendingOrder = pendingOrder.sort((a: any, b: any) => {
          return new Date(a.DeliveryDate).getTime() - new Date(b.DeliveryDate).getTime()
        })
        setPendingData(sortedPendingOrder);
      })
      .catch((err) => {
        console.log(err.err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDel = () => {
    setLoading(true);
    axios
      .get(`${baseURL}/orders/getDel`, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })
      .then((response) => {
        const deliveryOrder = response.data.data.filter((item: any) => item["Status"] === "Delivered");
        const sortedDeliveryOrder = deliveryOrder.sort((a: any, b: any) => {
          return new Date(a.PickupDate).getTime() - new Date(b.PickupDate).getTime()
        })
        SetDeliveryData(sortedDeliveryOrder);
      })
      .catch((err) => {
        console.log(err.err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const getDelivers = () => {
  //   axios
  //     .get(`${baseURL}/order`)
  //     .then((response) => {
  //       setPendingData(
  //         response.data.data
  //           .filter((item: any) => item["Status"] === "Pending")
  //           .sort(
  //             (a: any, b: any) =>
  //               new Date(a.DeliveryDate).getTime() - new Date(b.DeliveryDate).getTime()
  //           )
  //       );
  //       SetDeliveryData(
  //         response.data.data
  //           .filter((item: any) => item["Status"] === "Delivered")
  //           .sort(
  //             (a: any, b: any) =>
  //               new Date(a.PickupDate).getTime() - new Date(b.PickupDate).getTime()
  //           )
  //       );
  //       SetCompleteData(
  //         response.data.data
  //           .filter((item: any) => item["Status"] === "Completed")
  //           .sort((a: any, b: any) => b.OrderNumber - a.OrderNumber)
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err.err);
  //     });
  // };

  const getOrderSetting = async () => {
    const settingData = await axios.get(`${baseURL}/setting/order-entry`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    if (!settingData.data.success) return;
    dispatch({
      type: "UPDATE_PRODUCTLIST",
      payload: {
        product_list: settingData.data.data.filter(
          (item: OrderSetting_interface) => item["TicketDescription"] === "Product"
        )
      }
    });
  };

  const handleSearch = async (str: string) => {
    await setSearchKeyword(str);
    axios
      .get(`${baseURL}/order`, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })
      .then((response) => {
        setPendingData(response.data.data.filter((item: any) => item["Status"] === "Pending"));
        SetDeliveryData(response.data.data.filter((item: any) => item["Status"] === "Delivered"));
        SetCompleteData(
          response.data.data.filter(
            (item: any) =>
              item["Status"] === "Completed" &&
              (item["CustomerName"].toLowerCase().includes(str.toLowerCase()) ||
                item["DeliveryAddress"].toLowerCase().includes(str.toLowerCase()))
          )
        );
      })
      .catch((err) => {
        console.log(err.err);
      });
  };

  const handleChange = (e: any) => {
    switch (e.target.value) {
      case "delivery":
        setDelivery(e.target.checked);
        break;
      case "complete":
        setComplete(e.target.checked);
        break;
      case "pickup":
        setPickup(e.target.checked);
        break;
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="flex items-center font-bold md:text-xl">
            <FaListUl className="w-1/2 h-6 mr-1 md:w-7 md:h-7 " /> Active Orders
          </h1>
        </div>
        <div>
          <Menu>
            <MenuHandler>
              <Button
                style={{
                  background: "#3B82F6",
                  width: "150px",
                  height: "40px",
                  justifyContent: "center",
                  fontSize: "17px"
                }}>
                Filter
              </Button>
            </MenuHandler>
            <MenuList>
              <MenuItem className="p-0">
                <label
                  htmlFor="item-1"
                  className="flex cursor-pointer items-center gap-2 p-2 text-black">
                  <Checkbox
                    ripple={false}
                    id="item-1"
                    name="delivery"
                    value="delivery"
                    checked={delivery}
                    onChange={(e: any) => handleChange(e)}
                    containerProps={{ className: "p-0" }}
                    className="hover:before:content-none"
                  />
                  Deliveries
                </label>
              </MenuItem>
              <MenuItem className="p-0">
                <label
                  htmlFor="item-2"
                  className="flex cursor-pointer items-center gap-2 p-2 text-black">
                  <Checkbox
                    ripple={false}
                    id="item-2"
                    name="pickup"
                    value="pickup"
                    checked={pickup}
                    onChange={(e: any) => handleChange(e)}
                    containerProps={{ className: "p-0" }}
                    className="hover:before:content-none"
                  />
                  Pickups
                </label>
              </MenuItem>
              <MenuItem className="p-0">
                <label
                  htmlFor="item-3"
                  className="flex cursor-pointer items-center gap-2 p-2 text-black">
                  <Checkbox
                    ripple={false}
                    id="item-3"
                    name="complete"
                    value="complete"
                    checked={complete}
                    onChange={(e: any) => handleChange(e)}
                    containerProps={{ className: "p-0" }}
                    className="hover:before:content-none"
                  />
                  Completed
                </label>
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>

      <div className="relative w-full space-y-4">
        <div className={`${!delivery ? "hidden" : "block"}`}>
          <Delivery pendingData={pendingData} getPen={getPen} getDel={getDel} />
        </div>
        <div className={`${!pickup ? "hidden" : "block"}`}>
          <Pickup deliveryData={deliverydata} getDel={getDel} getCom={getCom} />
        </div>
        <div className={`${!complete ? "hidden" : "block"}`}>
          <Completed
            mainData={completedata}
            getCom={getCom}
            searchKeyword={searchKeyword}
            handleSearch={handleSearch}
          />
        </div>
        <div
          className={`absolute inset-0 bg-gray-300/70 backdrop-blur-sm flex justify-center z-10 ${loading ? "" : "hidden"
            }`}>
          <FaSpinner className="w-10 h-10 animate-spin-slow text-white mt-[30vh]"></FaSpinner>
        </div>
      </div>
    </div>
  );
};

export default ActiveOrders;
