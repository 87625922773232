import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import OrderEntry from "pages/OrderEntry";
import Calendar from "pages/Calendar";
import ActiveOrders from "pages/ActiveOrders";
import Reports from "pages/Reports";
import Settings from "components/Settings";
import User from "components/User";
import UserManage from "components/User/components/UserManage";
import OrderEntrySetting from "components/Settings/components/OrderEntrySetting";
import DataSetting from "components/Settings/components/DataSetting";
import SystemSetting from "components/Settings/components/SystemSetting";
import Login from "pages/Login";
import Main from "layouts/Main";
import { LayoutProvider } from "providers/LayoutProvider";
import { useAuth } from "providers/AuthProvider";
import { FaSpinner } from "react-icons/fa";

function PrivateOutlet() {
  const { checking, authenticated } = useAuth();
  return checking ? (
    <>
      <div className="w-full h-full flex justify-center items-center text-primary">
        <div className="flex flex-col justify-center items-center space-y-2">
          <div><FaSpinner className="animate-spin-slow w-6 h-6" /></div>
          <div className="text-lg">Checking authentication ...</div>
        </div>
      </div>
    </>
  ) : authenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
}

const App = () => {
  return (
    <LayoutProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateOutlet />}>
            <Route path="/" element={<Main />}>
              <Route path="/" element={<Navigate to="/orders/new" />} />
              <Route path="/orders/:orderId?/:action?" element={<OrderEntry />} />
              <Route path="/orders/active" element={<ActiveOrders />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/users" element={<User />} />
              <Route path="/usermanage" element={<UserManage />} />
              <Route path="/settings/order-entry" element={<OrderEntrySetting />} />
              <Route path="/settings/data" element={<DataSetting />} />
              <Route path="/settings/system" element={<SystemSetting />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </LayoutProvider>
  );
};

export default App;
