interface BlockLoaderProps {
  loading: boolean;
}
const BlockLoader = ({ loading }: BlockLoaderProps) => (
  <div className={`absolute inset-0 transition-all z-10 ${loading ? "" : "delay-150 invisible"}`}>
    <div
      className={` w-full h-full bg-white/70 backdrop-blur-sm flex justify-center transition-opacity ${
        loading ? "opacity-100" : "opacity-0"
      }`}>
      {/* <FaSpinner className="w-10 h-10 animate-spin-slow text-white mt-[30vh]"></FaSpinner> */}
      <div className="block-loader mt-[30vh]"></div>
    </div>
  </div>
);
export default BlockLoader;
