import { useParams, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import axios from "axios";
import { generateEmptyOrderEntry, generateOrderEntryFromOrder } from "utils/orders.helper";
import { EMPTY_ORDER_ID } from "config/constants";
import { baseURL } from "config";
import { SetValue } from "config/types";

interface OrderContextValues {
  readOnly: boolean;
  setReadOnly: Dispatch<SetStateAction<boolean>>;
  renders: any;
  setRenders: any;

  newOrder: boolean;
  orderId: number;
  orderLoading: boolean;
  orderLoaded: boolean;

  orderEntry: any;
  setOrderEntry: SetValue<any>;
  updateOrderEntryField: (section: string, name: string, value: any) => void;
  resetOrderEntry: () => void;
  copyContact: () => void;

  customerLoading: boolean;

  saveOrder: () => void;

  previousOrders: any[];
  previousOrdersLoading: boolean;
  refreshPreviousOrders: () => void;

  payments: any[];
  paymentsLoading: boolean;
  refreshPayments: () => void;
}

const OrderContext: React.Context<OrderContextValues> = React.createContext<OrderContextValues>({
  newOrder: true,
  readOnly: false,
  setReadOnly: () => { },

  orderId: 0,
  orderLoading: false,
  orderLoaded: false,

  orderEntry: {},
  setOrderEntry: () => { },
  updateOrderEntryField: () => { },
  resetOrderEntry: () => { },
  copyContact: () => { },

  customerLoading: false,

  saveOrder: () => { },

  previousOrders: [],
  previousOrdersLoading: false,
  refreshPreviousOrders: () => { },

  payments: [],
  paymentsLoading: false,
  refreshPayments: () => { },
  renders: 0,
  setRenders: () => { }
});

interface OrderContextProviderProps {
  children: ReactNode;
}

export function OrderContextProvider({ children }: OrderContextProviderProps) {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const location = useLocation();
  const pathParams = useParams();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const [readOnly, setReadOnly] = useState<boolean>(false);

  const [orderLoading, setOrderLoading] = useState<boolean>(false);
  const [orderLoaded, setOrderLoaded] = useState<boolean>(false);
  const [orderEntry, setOrderEntry] = useState<any>(generateEmptyOrderEntry);

  const orderId = useMemo(() => {
    const orderId = parseInt(orderEntry.OrderNumber);
    return isNaN(orderId) ? 0 : orderId;
  }, [orderEntry]);
  const newOrder = useMemo(() => orderId <= EMPTY_ORDER_ID, [orderId]);

  const [customerLoading, setCustomerLoading] = useState<boolean>(false);

  const [renders, setRenders] = useState(0)

  const [previousOrders, setPreviousOrders] = useState<any>([]);
  const [previousOrdersLoading, setPreviousOrdersLoading] = useState<boolean>(false);
  const [previousOrdersRefreshIndicator, setPreviousOrdersRefreshIndicator] = useState<string>("");

  const [payments, setPayments] = useState<any>([]);
  const [paymentsLoading, setPaymentsLoading] = useState<boolean>(false);
  const [paymentsRefreshIndicator, setPaymentsRefreshIndicator] = useState<string>("");

  const updateOrderEntryField = useCallback(
    (section: string, name: string, value: any) => {
      setOrderEntry((entry: any) => ({
        ...entry,
        [section]: { ...entry[section], [name]: value }
      }));
    },
    [setOrderEntry]
  );

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  const resetOrderEntry = useCallback(() => {
    setOrderEntry(generateEmptyOrderEntry());
  }, [setOrderEntry]);

  const copyContact = useCallback(() => {
    setOrderEntry((entry: any) => ({
      ...entry,
      delivery_contact: {
        DeliveryContactPhone: entry.customer.PhoneNumber,
        DeliveryContact: entry.customer.CustomerName,
        DeliveryAddress: entry.customer.BillingAddress
      }
    }));
  }, [setOrderEntry]);

  const saveOrder = useCallback(() => { }, []);

  const loadPreviousOrders = useCallback(
    async (signal: AbortSignal) => {
      setPreviousOrdersLoading(true);
      setPreviousOrders([]);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo?.accessToken}`,
          },
          signal: signal
        };
        const response = await axios
          .get(`${baseURL}/order/by/${orderEntry.customer.PhoneNumber}`, config)
          .then(({ data }) => data);

        response.success && setPreviousOrders(response.data);
      } catch (ex: any) {
        ex?.code !== "ERR_CANCELED" && console.log(ex);
      } finally {
        setPreviousOrdersLoading(false);
      }
    },
    [orderEntry.customer.PhoneNumber, setPreviousOrders, setPreviousOrdersLoading]
  );

  const refreshPreviousOrders = useCallback(() => {
    setPreviousOrdersRefreshIndicator(new Date().getTime() + "" + Math.random());
  }, [setPreviousOrdersRefreshIndicator]);

  useEffect(() => {
    if (!orderEntry.customer.PhoneNumber || orderEntry.customer.PhoneNumber.length < 10) {
      setPreviousOrders([]);
      return;
    }

    const abortController = new AbortController();
    loadPreviousOrders(abortController.signal);

    return () => abortController.abort();
  }, [
    orderEntry.customer.PhoneNumber,
    previousOrdersRefreshIndicator,
    setPreviousOrders,
    loadPreviousOrders
  ]);

  const loadPayments = useCallback(
    async (signal: AbortSignal) => {
      setPaymentsLoading(true);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo?.accessToken}`,
          },
          signal: signal
        };
        const response = await axios
          .get(`${baseURL}/payment/${orderId}`, config)
          .then(({ data }) => data);

        response.success && setPayments(response.data);
      } catch (ex: any) {
        ex?.code !== "ERR_CANCELED" && console.log(ex);
      } finally {
        setPaymentsLoading(false);
      }
    },
    [orderId, setPaymentsLoading, setPayments]
  );

  const refreshPayments = useCallback(() => {
    setPaymentsRefreshIndicator(new Date().getTime() + "" + Math.random());
  }, [setPaymentsRefreshIndicator]);

  useEffect(() => {
    if (newOrder) {
      setPayments([]);
      return;
    }

    const abortController = new AbortController();
    loadPayments(abortController.signal);

    return () => abortController.abort();
  }, [newOrder, paymentsRefreshIndicator, setPayments, loadPayments]);

  const loadCustomer = useCallback(
    async (phone: string, signal: AbortSignal) => {
      setCustomerLoading(true);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo?.accessToken}`,
          },
          signal: signal
        };
        const customers = await axios
          .get(`${baseURL}/customer/search?keywords=${phone}`, config)
          .then(({ data }) => data.data);

        if (customers.length) {
          const customer: Customer = customers[0];
          setOrderEntry((previousEntry: any) => ({
            ...previousEntry,
            customer: {
              PhoneNumber: customer.PhoneNumber,
              CustomerName: customer.CustomerName,
              EmailAddress: customer.EmailAddress,
              BillingAddress: customer.BillingAddress,
              CustomerNotes: customer.CustomerNotes,
              DateAdded: customer.DateAdded,
              AddedBy: customer.AddedBy,
              LastModified: customer.LastModified,
              LastModifiedBy: customer.LastModifiedBy
            },
            delivery_contact: {
              DeliveryContactPhone: customer.DeliveryContactPhone,
              DeliveryContact: customer.DeliveryContact,
              DeliveryAddress: customer.DeliveryAddress,
              DeliveryNotes: ""
            }
          }));
        }
      } catch (ex: any) {
        ex?.code !== "ERR_CANCELED" && console.log(ex);
      } finally {
        setCustomerLoading(false);
      }
    },
    [setCustomerLoading, setOrderEntry]
  );

  const copyOrder = useCallback(
    async (orderId: string, signal: AbortSignal) => {
      let entry = null;

      setOrderLoaded(false);
      setOrderLoading(true);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo?.accessToken}`,
          },
          signal: signal
        };
        const response = await axios
          .get(`${baseURL}/order/${orderId}`, config)
          .then(({ data }) => data);
        if (response.success && response.data.length) {
          const order = response.data[0];
          entry = generateOrderEntryFromOrder(order);
          entry.OrderNumber = 0;
          entry.delivery_contact.DeliveryNotes = "";
          entry.order_info = {
            OrderDate: "",
            Status: "Quote",
            CreatedOn: "",
            CreatedBy: "",
            LastModifiedOn: "",
            LastModifiedBy: "",
            DeliveredTimestamp: "",
            DeliveredBy: "",
            PickedUpTimestamp: "",
            PickedUpby: "",
            PaymentType: "",
            isPickUp: false
          };

          setOrderEntry(entry);
          setReadOnly(false);
        } else {
          navigate("/orders/new");
        }
      } catch (ex: any) {
        ex?.code !== "ERR_CANCELED" && console.log(ex);
      } finally {
        setOrderLoading(false);
      }
    },
    [setOrderLoading, setOrderEntry, navigate]
  );

  const loadOrder = useCallback(
    async (orderId: number, signal: AbortSignal) => {
      let entry = null;

      setOrderLoaded(false);
      setOrderLoading(true);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo?.accessToken}`,
          },
          signal: signal
        };
        const response = await axios
          .get(`${baseURL}/order/${orderId}`, config)
          .then(({ data }) => data);
        if (response.success && response.data.length) {
          entry = generateOrderEntryFromOrder(response.data[0]);

          setOrderEntry(entry);
          setOrderLoaded(true);
        } else {
          navigate("/orders/new");
        }
      } catch (ex: any) {
        ex?.code !== "ERR_CANCELED" && console.log(ex);
      } finally {
        setOrderLoading(false);
      }
    },
    [setOrderLoading, setOrderLoaded, setOrderEntry, navigate]
  );

  useEffect(() => {
    const orderIdStr = pathParams.orderId?.toLowerCase();
    if (!orderIdStr) navigate("/orders/new");

    if (orderIdStr === "new") {
      const copyingOrderId = searchParams.get("copy") || "";
      if (copyingOrderId) {
        const abortController = new AbortController();
        copyOrder(copyingOrderId, abortController.signal);

        return () => abortController.abort();
      }

      const phone = searchParams.get("phone") || "";
      const entry = generateEmptyOrderEntry();
      entry.customer.PhoneNumber = phone;
      setOrderEntry(entry);
      setReadOnly(false);

      if (phone.length >= 10) {
        const abortController = new AbortController();
        loadCustomer(phone, abortController.signal);

        return () => abortController.abort();
      }
    } else {
      const orderId = parseInt(orderIdStr!);
      if (isNaN(orderId)) navigate("/orders/new");

      setReadOnly(pathParams.action?.toLowerCase() !== "edit");

      const abortController = new AbortController();
      loadOrder(orderId, abortController.signal);

      return () => abortController.abort();
    }
  }, [pathParams, searchParams, setOrderLoaded, copyOrder, loadCustomer, navigate, loadOrder]);

  return (
    <OrderContext.Provider
      value={{
        readOnly,
        setReadOnly,

        newOrder,
        orderId,
        orderLoading,
        orderLoaded,

        orderEntry,
        setOrderEntry,
        updateOrderEntryField,
        resetOrderEntry,
        copyContact,

        customerLoading,

        saveOrder,

        previousOrders,
        previousOrdersLoading,
        refreshPreviousOrders,

        payments,
        paymentsLoading,
        refreshPayments,
        renders,
        setRenders
      }}>
      {children}
    </OrderContext.Provider>
  );
}

export const useOrderContext = (): OrderContextValues => {
  const context = useContext<OrderContextValues>(OrderContext);
  return context;
};
