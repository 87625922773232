import React, { useEffect, useState } from "react";
import { FaLandmark } from "react-icons/fa";
import moment from "moment";
import { useOrderContext } from "./OrderContextProvider";
import { baseURL } from "config";
import axios from "axios";
import { calculateSummaryTotals } from "utils/orders.helper";
// import Amount from "components/_shared/Amount";
interface OrderSetting_interface {
  ItemDescription: string;
  Description: string;
  Value: string;
  TicketDescription: string;
  ID: number;
  NoteDescription: string;
}
const Summary = () => {
  const [orderDetailData, setOrderDetailData] = useState<OrderSetting_interface[]>([]);
  const [readOnlyField, setReadOnlyField] = useState(true);
  const [readOnlyFieldMiles, setReadOnlyFieldMiles] = useState(true);
  const [additionalDaysPrice, setAdditionalDaysPrice] = useState("");
  const [additionalMilesPrice, setAdditionalMilesPrice] = useState("");
  const { orderEntry, setOrderEntry } = useOrderContext();
  const { order_details } = orderEntry || {}
  const { DeliveryDate, PickupDate, MilesOneWay } = order_details || {}



  //twest
  function calculateDaysDifference(pickupDateStr: any, deliveryDateStr: any) {
    const formats = ['MM/DD/YYYY', 'YYYY-MM-DD']; // List of accepted date formats

    let pickupDate, deliveryDate;

    // Attempt to parse pickup date and delivery date in different formats
    for (const format of formats) {
      pickupDate = moment(pickupDateStr, format, true); // Parse without strict mode
      if (pickupDate.isValid()) break; // If parsing succeeds, break the loop
    }

    for (const format of formats) {
      deliveryDate = moment(deliveryDateStr, format, true); // Parse without strict mode
      if (deliveryDate.isValid()) break; // If parsing succeeds, break the loop
    }

    // Calculate the difference in days
    let daysDifference;
    if (deliveryDate) {
      daysDifference = deliveryDate.diff(pickupDate, 'days')
    }

    return daysDifference;
  }


  const differDays = calculateDaysDifference(DeliveryDate, PickupDate);

  let additionalDayCalculation: any;
  if (differDays) {
    additionalDayCalculation = differDays > 7 ? differDays - 7 : 0
  }
  const additionalMilage = MilesOneWay > 30 ? (MilesOneWay - 30) * 2 : 0;













  //twest

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }
  const getOrderSetting = async () => {
    const settingData = await axios.get(`${baseURL}/setting/order-entry`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    if (!settingData.data.success) return;
    setOrderDetailData(
      settingData.data.data.filter(
        (item: OrderSetting_interface) => item["TicketDescription"] === "OrderDetail"
      )
    );
  };
  let daysCost = 0;
  useEffect(() => {



    getOrderSetting();
    // eslint-disable-next-line
  }, [additionalDayCalculation, daysCost, orderEntry?.order_details?.AdditionalDayCost, orderEntry?.order_details?.ExtraMilesCost, orderEntry?.summary?.DaysCost, orderEntry?.order_details?.DeliveryDate, orderEntry?.order_details?.PickupDate]);



  // const summary: Summaries[] = [
  //   { delivery_date: '3/11/2023', pickup_date: '3/2', extra_miles: 20, dumperster : 325 ,days: 50, mileage: 23, shingles:150, concrete: 25, labor: 45, sales_tax: '-', total_cost: 518,
  //   payments: 325, balance: 193
  // }
  // ];

  function formatNumberWithOptionalDecimals(number: any) {
    // Check if the number has cents
    if (Number.isInteger(number)) {
      // If the number is an integer, return it as is
      return number.toFixed(2).toString();
    } else {
      // If the number has cents, format it with two decimal places
      return number.toFixed(2).toString();
    }
  }

  const additionalDaysCostx = orderEntry?.summary?.DaysCost.toFixed(2)
  const additionalMilageCost = orderEntry?.summary?.MileageCost ? orderEntry?.summary?.MileageCost.toFixed(2) : 0
  const balance = orderEntry?.summary?.BalanceCost?.toFixed(2)
  const payments = orderEntry?.summary?.Payments.toFixed(2)
  const salesTax = Number(orderEntry?.summary?.TrailerCost).toFixed(2)
  const totalCost = (Number(orderEntry?.summary?.TotalCost) - orderEntry?.order_details?.Discount).toPrecision(2)


  const handleInputChange = (e: any) => {
    const entry = { ...orderEntry };
    if (e.target.value) {
      entry.order_details.AdditionalDayCost = parseFloat(e.target.value);
    }
    setAdditionalDaysPrice(e.target.value);
    setReadOnlyField(!readOnlyField)
    if (orderEntry?.OrderNumber != 0) {
      fetch(`${baseURL}/order/${orderEntry?.OrderNumber}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${userInfo?.accessToken}`
        },
        body: JSON.stringify({
          AdditionalDayCost: e.target.value
        })
      })
    }



    if (additionalDayCalculation < 7) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      daysCost = 0;
    } else {
      if (orderEntry?.order_details?.AdditionalDayCost) {
        daysCost = additionalDayCalculation * orderEntry.order_details.AdditionalDayCost;
      } else {
        daysCost = additionalDayCalculation * 20;
      }
    }


    if (!isNaN(daysCost) || additionalDayCalculation !== undefined) {
      setOrderEntry((entry: any) => {
        const newEntry = {
          ...entry,
          order_details: {
            ...entry.order_details,
            NumberOfDays: additionalDayCalculation,
          },
          summary: {
            ...entry.summary,
            DaysCost: daysCost
          }
        };

        return calculateSummaryTotals(newEntry, +orderDetailData[0]?.Value);
      });
    }

  }
  const handleInputChangeMiles = (e: any) => {
    setReadOnlyFieldMiles(!readOnlyFieldMiles)
    const entry = { ...orderEntry };
    entry.order_details.ExtraMilesCost = parseFloat(e.target.value);
    setAdditionalMilesPrice(e.target.value)
    if (orderEntry?.OrderNumber != 0) {
      fetch(`${baseURL}/order/${orderEntry?.OrderNumber}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${userInfo?.accessToken}`
        },
        body: JSON.stringify({
          ExtraMilesCost: e.target.value
        })
      })
    }


    if (additionalDayCalculation < 7) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      daysCost = 0;
    } else {
      if (orderEntry?.order_details?.AdditionalDayCost) {
        daysCost = additionalDayCalculation * orderEntry.order_details.AdditionalDayCost;
      } else {
        daysCost = additionalDayCalculation * 20;
      }
    }

    if (!isNaN(daysCost) || additionalDayCalculation !== undefined) {
      setOrderEntry((entry: any) => {
        const newEntry = {
          ...entry,
          order_details: {
            ...entry.order_details,
            NumberOfDays: additionalDayCalculation,
          },
          summary: {
            ...entry.summary,
            DaysCost: daysCost
          }
        };

        return calculateSummaryTotals(newEntry, +orderDetailData[0]?.Value);
      });
    }
  }
  return (
    <div className="panel bg-gray-50 grow">
      <div className="flex items-center panel-header !bg-gray-100">
        <h3 className="flex items-center font-bold">
          <FaLandmark className="w-4 h-4 mr-1" />
          Summary
        </h3>
        <small className="ml-1 text-xs font-normal md:ml-2">
          ( Order Created:{" "}
          {orderEntry.order_info.CreatedOn
            ? moment(orderEntry.order_info.CreatedOn).format("MM/DD/YY")
            : ""}
          )
        </small>
      </div>
      <div className="space-y-3 panel-body grow">
        <div className="form-control form-control-text border-b border-b-gray-300">
          <div>
            <label>Delivery Date</label>
          </div>
          <div>
            <label>
              {orderEntry.order_details.DeliveryDate
                ? moment(orderEntry.order_details.DeliveryDate).format("MM/DD/YY")
                : ""}
            </label>
          </div>
        </div>
        <div className="form-control form-control-text border-b border-b-gray-300">
          <div>
            <label>Pickup Date</label>
          </div>
          <div>
            <label>
              {orderEntry.order_details.PickupDate
                ? moment(orderEntry.order_details.PickupDate).format("MM/DD/YY")
                : ""}
            </label>
          </div>
        </div>
        <div className="form-control form-control-text border-b border-b-gray-300">
          <div>
            <label>Product</label>
          </div>
          <div>
            <label>
              <span>$</span>
              {orderEntry.summary.ProductCost.toFixed(2)}
            </label>
          </div>
        </div>
        <div className="form-control form-control-text border-b border-b-gray-300">
          <div>
            <label>Additional Days</label> {
              readOnlyField ? <span> {additionalDayCalculation} @ <span className="text-blue-500 cursor-pointer font-bold" onClick={handleInputChange}>${orderEntry?.order_details?.AdditionalDayCost ? orderEntry?.order_details?.AdditionalDayCost : 20}</span> / Each</span> : <input type="text" autoFocus={true} onBlur={handleInputChange} value={additionalDaysPrice} />
            }
          </div>
          <div>
            <label>
              <span>$</span>
              {additionalDaysCostx}
            </label>
          </div>
        </div>
        <div className="form-control form-control-text border-b border-b-gray-300">
          <div>
            <label>Extra Miles</label> {
              readOnlyFieldMiles ? <span>{additionalMilage} @ <span className="text-blue-500 cursor-pointer font-bold" onClick={handleInputChangeMiles}>${orderEntry?.order_details?.ExtraMilesCost ? orderEntry?.order_details?.ExtraMilesCost : orderDetailData[1]?.Value}</span>/ Each</span> : <input type="text" autoFocus={true} onBlur={handleInputChangeMiles} value={additionalMilesPrice} />
            }
          </div>
          <div>
            <label>
              <span>$</span>
              {orderEntry.summary.MileageCost ? additionalMilageCost : 0}
            </label>
          </div>
        </div>
        <div className="form-control form-control-text border-b border-b-gray-300">
          <div>
            <label>Shingles</label>
          </div>
          <div>
            <label>
              <span>$</span>
              {orderEntry.summary.ShinglesCost ? orderEntry.summary.ShinglesCost.toFixed(2) : 0}
            </label>
          </div>
        </div>
        <div className="form-control form-control-text border-b border-b-gray-300">
          <div>
            <label>Concrete</label>
          </div>
          <div>
            <label>
              <span>$</span>
              {orderEntry.summary.ConcreteCost ? orderEntry.summary.ConcreteCost.toFixed(2) : 0}
            </label>
          </div>
        </div>
        <div className="form-control form-control-text border-b border-b-gray-300">
          <div>
            <label>Labor / Misc Cost</label>
          </div>
          <div>
            <label>
              <span>$</span>
              {orderEntry.summary.LaborMiscCost ? orderEntry.summary.LaborMiscCost.toFixed(2) : 0}
            </label>
          </div>
        </div>
        <div className="form-control form-control-text border-b border-b-gray-300">
          <div>
            <label>Discount</label>
          </div>
          <div>
            <label>
              <span>$</span>
              {orderEntry.order_details.Discount ? parseFloat(orderEntry?.order_details?.Discount).toFixed(2) : 0}
            </label>
          </div>
        </div>
        <div className="form-control form-control-text border-b border-b-gray-300 !mt-6">
          <div>
            <label>Sales Tax</label>
          </div>
          <div>
            <label>
              <span>$</span>
              {orderEntry.summary.TrailerCost ? salesTax : 0}
            </label>
          </div>
        </div>
        <div className="form-control form-control-text border-b border-b-gray-300">
          <div>
            <label>Total Cost</label>
          </div>
          <div>
            <label>
              <span>$</span>
              {orderEntry.summary.TotalCost ? (orderEntry.summary.TotalCost - orderEntry?.order_details?.Discount).toFixed(2) : 0}
            </label>
          </div>
        </div>
        <div className="form-control form-control-text border-b border-b-gray-300">
          <div>
            <label>Payments</label>
          </div>
          <div>
            <label>
              <span>$</span>
              {orderEntry.summary.Payments ? payments : 0}
            </label>
          </div>
        </div>
        <div className="form-control form-control-text border-b border-b-gray-300">
          <div>
            <label>Balance</label>
          </div>
          <div>
            <label>
              <span>$</span>
              {orderEntry.summary.BalanceCost ? (orderEntry.summary.BalanceCost - orderEntry?.order_details?.Discount).toFixed(2) : 0}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
