import { createContext, Dispatch, ReactNode, useContext, useReducer } from "react";
import reducer from "./reducer";

// Initial State
const initialState: State = {
  paymentmodal: false,
  menu: false,
  product_list: [
    // {
    //   ID: 0,
    //   ItemDescription: "",
    //   Value: "",
    //   TicketDescription: "",
    //   Type: "",
    //   createdAt: "",
    //   updatedAt: ""
    // }
  ],
  user: {
    ID: 0,
    Name: "",
    Email: "",
    Password: "",
    AccessType: "",
    Picture: "",
    isDeleted: false
  }
};

// Create Our context
const globalContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => {}
});

// Provider to wrap around our root react component
export const GlobalContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <globalContext.Provider
      value={{
        state,
        dispatch
      }}>
      {children}
    </globalContext.Provider>
  );
};

// Custom context hook
export const useGlobalContext: ContextHook = () => {
  const { state, dispatch } = useContext(globalContext);
  return { state, dispatch };
};
