import axios from "axios";
import { baseURL } from "config";
import { useEffect, useState } from "react";
import { FaCog, FaEdit, FaReply, FaSave } from "react-icons/fa";
import { toast } from "react-toastify";

const SystemSetting = () => {
  useEffect(() => {
    getSystemItem();
  }, []);
  const [systemInputValues, setSystemInputValues] = useState({
    companyAddress: "",
    companyCoverLogo: "",
    companySquareLogo: ""
  });
  const [editFlag, setEditFlag] = useState(false);
  const [readOnly, setReadOnly] = useState(true);

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  const getSystemItem = async () => {
    try {
      const response = await axios.get(`${baseURL}/setting/system`, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      });
      if (response.data.success) {
        setSystemInputValues(response.data.data[0]);
      }
    } catch (error) {
      console.log("getSystemItem Error::", error);
    }
  };

  const putAvatar = async (file: any, key: string) => {
    const data = {
      key: key
    };
    const preSignedURL = await axios.post(`${baseURL}/presignedUrl`, data, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    const myHeaders = new Headers({ "Content-Type": "image/jpeg" });
    return await fetch(preSignedURL.data, {
      method: "PUT",
      headers: myHeaders,
      body: file
    });
  };

  const onImageChange = async (e: any) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const key = "image/" + Date.now().toString() + "_" + file.name;
      putAvatar(file, key)
        .then((res) => {
          switch (e.target.name) {
            case "companyCoverLogo":
              updateCoverLogo(
                `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION_NAME}.amazonaws.com/${key}`
              );
              break;
            case "companySquareLogo":
              updateSquareLogo(
                `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION_NAME}.amazonaws.com/${key}`
              );
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          console.log("error:::", error);
        });
    }
  };


  const handleFileChange = async (e: any) => {
    let file = e.target.files[0];
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post(`${baseURL}/upload-to-linode`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.data) {
        switch (e.target.name) {
          case "companyCoverLogo":
            updateCoverLogo(
              response.data.data
            );
            break;
          case "companySquareLogo":
            updateSquareLogo(
              response.data.data
            );
            break;
          default:
            break;
        }
      }

      console.log(response.data);
      // Handle success response
    } catch (error) {
      console.error('Error uploading file:', error);
      // Handle error
    }
  };





  const updateCoverLogo = async (imageURL: string) => {
    const data = {
      ...systemInputValues,
      companyCoverLogo: imageURL
    };
    setSystemInputValues(data);

    const response: any = await axios.post(`${baseURL}/setting/system`, data, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    if (!response.data.success) return;
    toast.success("Coverlogo Updated Successfully");
  };

  const updateSquareLogo = async (imageURL: string) => {
    const data = {
      ...systemInputValues,
      companySquareLogo: imageURL
    };
    setSystemInputValues(data);

    const response: any = await axios.post(`${baseURL}/setting/system`, data, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    if (!response.data.success) return;
    toast.success("Squarelogo Updated Successfully");
  };

  const updateCompanyAddress = async () => {
    const response: any = await axios.post(`${baseURL}/setting/system`, systemInputValues, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    if (!response.data.success) return;
    toast.success("CompanyAddress Updated Successfully", {
      autoClose: 1000,
      hideProgressBar: true
    });
  };

  const onChange = (e: any) => {
    setSystemInputValues({
      ...systemInputValues,
      companyAddress: e.target.value
    });
  };

  const saveCompanyAddress = () => {
    updateCompanyAddress();
    setEditFlag(false);
    setReadOnly(true);
  };

  return (
    <>
      <div className="flex items-center">
        <h1 className="flex items-center font-bold md:text-xl">
          <FaCog className="h-6 mr-1 md:w-7 md:h-7 " /> System
        </h1>
      </div>
      <div className="sm:px-8 sm:py-3">
        <h1 className="flex items-center text-lg font-bold">Company Setup</h1>
        <div className="py-5 space-y-10">
          <div className="flex flex-col gap-5 sm:flex-row">
            <div className="w-60 ">
              <label className="">Company Address</label>
            </div>
            <input
              name="companyAddress"
              className="!w-[300px] min-[426px]:!w-[400px]"
              onChange={(e) => onChange(e)}
              type="text"
              readOnly={readOnly}
              value={systemInputValues.companyAddress}
            />
            <div className="flex items-center gap-4 ">
              <button
                hidden={editFlag}
                className="bg-orange-500 btn btn-icon hover:bg-orange-700"
                onClick={() => {
                  setEditFlag(true);
                  setReadOnly(false);
                }}>
                <FaEdit className="w-4 h-4" />
              </button>
              <button
                hidden={!editFlag}
                className="bg-[#3B82F6] btn btn-icon hover:bg-[#1f75ff]"
                onClick={() => saveCompanyAddress()}>
                <FaSave className="w-4 h-4" />
              </button>
              <button
                hidden={!editFlag}
                className="bg-orange-500 btn btn-icon hover:bg-orange-700"
                onClick={() => {
                  setEditFlag(false);
                  setReadOnly(true);
                  getSystemItem();
                }}>
                <FaReply className="w-4 h-4" />
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-5 sm:flex-row">
            <div className="min-w-[240px] ">
              <label className="">Company Cover Logo</label>
            </div>
            <div className="flex flex-col gap-4">
              <input
                type="file"
                onChange={(e) => handleFileChange(e)}
                name="companyCoverLogo"
                id="companyCoverLogo"
              />
              <img
                className="w-36- h-36"
                src={systemInputValues.companyCoverLogo}
                alt="Company Cover Logo"
              />
            </div>
          </div>
          <div className="flex flex-col gap-5 sm:flex-row">
            <div className="w-60 ">
              <label className="">Company Square Logo</label>
            </div>
            <div className="flex flex-col gap-4">
              <input
                type="file"
                onChange={(e) => handleFileChange(e)}
                name="companySquareLogo"
                id="companySquareLogo"
              />
              <img
                className="w-36 h-36"
                src={systemInputValues.companySquareLogo}
                alt="Company Square Logo"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SystemSetting;
