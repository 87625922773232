import moment from "moment";
import { useOrderContext } from "./OrderContextProvider";
import { useEffect, useMemo, useState } from "react";
import { FaRegClock } from "react-icons/fa";
import { baseURL } from "config";
import axios from "axios";

export default function DeliveryNotes(orderHistories: any, updateKey: any) {
  console.log(orderHistories.orderHistories)
  const { newOrder, orderEntry, payments, previousOrders, renders } = useOrderContext();

  useEffect(() => {

  }, [updateKey, orderHistories.orderHistories])

  const currentOrder = previousOrders.find((prevOrder: any) => prevOrder.OrderNumber == orderEntry?.OrderNumber)

  const note = useMemo(() => {
    if (newOrder) return "";
    const notes = [];

    notes.push(
      `- Created on ${moment(orderEntry.order_info.CreatedOn).format("MM/DD/YY h:mm A")} by ${orderEntry.order_info?.CreatedBy
      }`
    );

    let invoiceViewed = false;
    console.log(payments)
    orderEntry.order_info?.DeliveredTimestamp &&
      notes.push(
        `- Delivered on ${moment(orderEntry.order_info?.DeliveredTimestamp).format(
          "MM/DD/YY h:mm A"
        )} by ${orderEntry.order_info?.DeliveredBy}`
      );
    payments?.length > 0 &&
      // eslint-disable-next-line array-callback-return
      payments.map((payment: any) => {
        notes.push(
          `- Payment: ${payment?.PaymentType} $${payment?.PaymentAmount} on ${moment(payment?.createdAt).format('M/D/YY h:mm A')} by ${payment?.PaymentAddedBy}`
        );
      })

    orderHistories?.orderHistories?.length > 0 && orderHistories?.orderHistories.map((history: any) => {
      if (history?.ModifiedType == "sms") {
        notes.push(
          `- Invoice Sent via SMS on ${moment(history?.createdAt).format('M/D/YY h:mm A')} by ${history?.ModifiedBy}`
        );
      }
      if (history?.ModifiedType == "email") {
        notes.push(
          `- Invoice Sent via Email on ${moment(history?.createdAt).format('M/D/YY h:mm A')} by ${history?.ModifiedBy}`
        );
      }
      if (history?.ModifiedType == "cancel-order") {
        notes.push(
          `- Order Canceled on ${moment(history?.createdAt).format('M/D/YY h:mm A')} by ${currentOrder?.LastModifiedBy}`
        );
      }
      if (history?.ModifiedType == "update-order") {
        notes.push(
          `- Order Updated on ${moment(history?.createdAt).format('M/D/YY h:mm A')} by ${currentOrder?.LastModifiedBy}`
        );
      }
      if (history?.ModifiedType == "invoice-view") {
        if (!invoiceViewed && history?.ModifiedType == "invoice-view") {
          notes.push(
            `- Invoice Viewed on ${moment(history?.createdAt).format('M/D/YY h:mm A')} by ${history?.ModifiedBy}`
          );
          invoiceViewed = true;
        }
      }
    })


    orderEntry.order_info.PickedUpTimestamp &&
      notes.push(
        `- Picked up on ${orderEntry.order_info.PickedUpTimestamp
          ? moment(orderEntry.order_info.PickedUpTimestamp).format("MM/DD/YY h:mm A")
          : ""
        }  by ${orderEntry.order_info?.PickedUpby}`
      );

    orderEntry.delivery_contact?.DeliveryNotes &&
      notes.push(`- Delivery Notes: ${orderEntry.delivery_contact?.DeliveryNotes}`);

    return notes.join("<br />");

  }, [currentOrder?.LastModifiedBy, newOrder, orderEntry.delivery_contact?.DeliveryNotes, orderEntry.order_info?.CreatedBy, orderEntry.order_info.CreatedOn, orderEntry.order_info?.DeliveredBy, orderEntry.order_info?.DeliveredTimestamp, orderEntry.order_info.PickedUpTimestamp, orderEntry.order_info?.PickedUpby, orderHistories?.orderHistories, payments]);

  return (
    <div className="panel bg-gray-50">
      <div className="flex items-center justify-between panel-header !bg-gray-100">
        <h3 className="flex items-center font-bold">
          <FaRegClock className="w-4 h-4 mr-1" />
          Order History
        </h3>
      </div>
      <div
        className="panel-body bg-gray-50/50 h-[240px] overflow-auto"
        dangerouslySetInnerHTML={{ __html: note }}></div>
    </div>
  );
};
