import { Dialog, Transition } from '@headlessui/react'
import { FaClosedCaptioning, FaEdit, FaWindowClose, FaTrash, FaTrashRestoreAlt, FaTrashRestore, FaTrashAlt } from "react-icons/fa";
import axios from 'axios';
import { useOrderContext } from 'components/OrderEntry/components/OrderContextProvider';
import { baseURL } from 'config';
import { useGlobalContext } from 'context';
import { Fragment, useCallback, useEffect, useState } from 'react'

export default function AddressModal({ closeModal, openModal, isOpen, setIsOpen, handlePreviousAddressClick, addresses, setFetchData, fetchData }) {
    const [editMode, setEditMode] = useState(false);
    const [billingAddressNote, setBillingAddressNote] = useState('');
    const [deliveryAddressNote, setDeliveryAddressNote] = useState('');
    const { orderEntry } = useOrderContext();


    const userInfo = JSON.parse(localStorage.getItem("USER"));

    // Function to handle changes in the input field
    const handleInputChange = (event) => {
        setBillingAddressNote(event.target.value);
    };
    const handleDeliveryInputChange = (event) => {
        setDeliveryAddressNote(event.target.value);
    };


    const handleEditNote = () => {

    }

    const handleEditMode = () => {
        setEditMode(true)
    }
    const saveAddressNote = (orderNumber) => {
        setEditMode(false)
        const addressDetails = {
            BillingAddressNote: billingAddressNote
        };
        fetch(`${baseURL}/address/${orderNumber}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userInfo?.accessToken}`
            },
            body: JSON.stringify({ addressDetails })
        })
            .then(res => res.json())
            .then(data => setFetchData(!fetchData))
    }

    const saveDeliveryAddressNote = (orderNumber) => {
        setEditMode(false)
        const addressDetails = {
            DeliveryAddressNote: deliveryAddressNote
        };
        fetch(`${baseURL}/address/${orderNumber}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userInfo?.accessToken}`
            },
            body: JSON.stringify({ addressDetails })
        })
            .then(res => res.json())
            .then(data => setFetchData(!fetchData))
    }

    const deleteAddress = (orderNumber, address) => {
        const trim = address.trim()
        const selectedAddress = addresses.filter(add => add.addressId == orderNumber);
        const addressArray = selectedAddress[0].BillingAddress.split("+");
        let indexToRemove = addressArray.indexOf(address);
        if (indexToRemove !== -1) {
            addressArray.splice(indexToRemove, 1);
        }
        let resultString = addressArray.join(" + ");
        setEditMode(false)
        const addressDetails = {
            BillingAddress: resultString,
            BillingAddressNote: null
        };
        fetch(`${baseURL}/address/${orderNumber}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userInfo?.accessToken}`
            },
            body: JSON.stringify({ addressDetails })
        })
            .then(res => res.json())
            .then(data => setFetchData(!fetchData))
        closeModal()
    }

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10 " onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-2 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-lg:mx-6 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/2 my-14 sm:my-14 transform overflow-hidden rounded-2xl bg-white px-1 md:px-4 py-2 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        <div className='px-1 md:px-4 flex flex-col items-center'>
                                            <h1 className='text-3xl'>Select Address</h1>
                                            <table className="w-full text-end">
                                                <thead className='!bg-black'>
                                                    <tr className='!bg-black'>
                                                        <th className="table-th text-start w-10/12">Address</th>
                                                        <th className="table-th text-start">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {addresses ? addresses.map((address) => {
                                                        return (
                                                            address?.BillingAddress ?
                                                                <>
                                                                    <tr >
                                                                        <td className="!px-1 table-td text-start cursor-pointer col-span-6">
                                                                            <div className='flex flex-col md:flex-row items-center'>
                                                                                <p onClick={() => handlePreviousAddressClick(address?.BillingAddress.split("+")[0])}>{address?.BillingAddress.split("+")[0]}</p>
                                                                                <div className='flex items-center'>
                                                                                    <span>-</span>
                                                                                    <input type='text' className={`${editMode ? "border border-gray-300 bg-white px-1 md:px-4 py-2 rounded-md focus:outline-none focus:border-blue-500" : "border-none bg-transparent focus:outline-none text-blue-500"} `} defaultValue={address?.BillingAddressNote} onChange={handleInputChange}
                                                                                        disabled={!editMode}
                                                                                    />
                                                                                    {editMode ? <img src="/assets/save.png" alt="" onClick={() => saveAddressNote(address?.addressId)} className='w-8 h-8' /> : null}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="table-td ">
                                                                            <div className='flex justify-center items-center gap-2'>
                                                                                <FaEdit
                                                                                    className="w-6 h-6 cursor-pointer"
                                                                                    onClick={() => handleEditMode()}
                                                                                />
                                                                                <FaTrashAlt onClick={() => deleteAddress(address?.addressId, address?.BillingAddress.split("+")[0])} className="w-6 h-6 cursor-pointer" />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    {address?.BillingAddress.split("+")[1] ?
                                                                        <tr >
                                                                            <td className="!px-1 table-td text-start cursor-pointer col-span-6">
                                                                                <div className='flex flex-col md:flex-row items-center'>
                                                                                    <p onClick={() => handlePreviousAddressClick(address?.BillingAddress.split("+")[1])}>{address?.BillingAddress.split("+")[1]}</p>
                                                                                    <div className='flex items-center'>
                                                                                        <span>-</span>
                                                                                        <input type='text' className={`${editMode ? "border border-gray-300 bg-white px-1 md:px-4 py-2 rounded-md focus:outline-none focus:border-blue-500" : "border-none bg-transparent focus:outline-none text-blue-500"} `} defaultValue={address?.DeliveryAddressNote} onChange={handleDeliveryInputChange}
                                                                                            disabled={!editMode}
                                                                                        />
                                                                                        {editMode ? <img src="/assets/save.png" alt="" onClick={() => saveDeliveryAddressNote(address?.addressId)} className='w-8 h-8' /> : null}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="table-td ">
                                                                                <div className='flex justify-center items-center gap-2'>
                                                                                    <FaEdit
                                                                                        className="w-6 h-6 cursor-pointer"
                                                                                        onClick={() => handleEditMode()}
                                                                                    />
                                                                                    <FaTrashAlt onClick={() => deleteAddress(address?.addressId, address?.BillingAddress.split("+")[1])} className="w-6 h-6 cursor-pointer" />
                                                                                </div>
                                                                            </td>
                                                                        </tr> : null
                                                                    }
                                                                </>
                                                                : null
                                                        )
                                                    }) : null}

                                                </tbody>
                                            </table>
                                        </div>
                                    </Dialog.Title>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

        </>
    )
}

