import { Outlet } from "react-router-dom";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import { useLayout } from "providers/LayoutProvider";
import useScroll from "hooks/scroll.hook";
// import { NotificationValuesProvider } from "providers/NotificationValuesProvider";
import { Drawer } from "@material-tailwind/react";
import Notes from "components/Notes";

const Main = () => {
  const {
    mobileMenuVisible,
    toggleMobileMenuVisible,
    searchFocused,
    notesPanelVisible,
    closeNotesPanel
  } = useLayout();
  const scrolling = useScroll(74);

  return (
    <div
      className={`flex w-full min-h-screen ${scrolling ? "scrolling" : ""}  ${
        searchFocused ? "search-focused" : ""
      }`}>
      <div
        className={`fixed inset-0 z-40 transition duration-75 lg:hidden ${
          mobileMenuVisible ? "translate-x-0" : "-translate-x-full delay-300"
        } lg:translate-x-0`}
        onClick={toggleMobileMenuVisible}>
        <div
          className={`w-full h-full transition duration-300 bg-black/25 ${
            mobileMenuVisible ? "opacity-100" : "opacity-0"
          }`}></div>
      </div>
      <div
        className={`fixed bottom-0 top-0 z-50 shrink-0 transition duration-300 lg:static ${
          mobileMenuVisible ? "translate-x-0 opacity-100" : "-translate-x-full opacity-0"
        } lg:translate-x-0 lg:opacity-100`}>
        <div className="relative h-full">
          <Sidebar />
        </div>
      </div>
      <div className="grow flex flex-col w-full">
        <Navbar />
        <div className="grow p-4 w-full relative">
          <Outlet />
        </div>
        <Drawer
          placement="right"
          open={notesPanelVisible}
          size={600}
          onClose={closeNotesPanel}
          overlayProps={{ className: "fixed" }}>
          <Notes />
        </Drawer>
      </div>
    </div>
  );
};

export default Main;
