import React, { useCallback, useEffect, useState } from "react";
import {
  EventApi,
  // DateSelectArg,
  EventClickArg,
  EventInput
} from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { createEventId } from "./components/event-utils";
import "./components/customStyle.scss";
import { FaCalendar, FaSpinner } from "react-icons/fa";
// import { Switch } from "@headlessui/react";
import { useGlobalContext } from "context";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { baseURL } from "config";
import { useLayout } from "providers/LayoutProvider";

const Calendar: React.FC = () => {
  const { state, dispatch } = useGlobalContext();

  const [settingsLoading, setSettingsLoading] = useState<boolean>(false);
  const [ordersLoading, setOrdersLoading] = useState<boolean>(false);

  const { sidebarMinimized } = useLayout();

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }


  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 150);
  }, [sidebarMinimized]);

  const navigate = useNavigate();

  const [enabled, setEnabled] = useState(true);
  const [eventData, setEventData] = useState<EventInput>([]);
  const [ordersMonthly, setOrdersMonthly] = useState<any>([]);

  const loadOrderSettings = useCallback(async () => {
    setSettingsLoading(true);
    try {
      const settingData = await axios.get(`${baseURL}/setting/order-entry`, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      });
      if (!settingData.data.success) return;
      dispatch({
        type: "UPDATE_PRODUCTLIST",
        payload: {
          product_list: settingData.data.data.filter(
            (item: OrderSetting_interface) => item["TicketDescription"] === "Product"
          )
        }
      });
    } finally {
      setSettingsLoading(false);
    }
  }, [dispatch, setSettingsLoading]);

  const parseOrderEvent = useCallback(async () => {
    var events: EventInput = [];
    await ordersMonthly.forEach((element: any) => {
      if (element.PickupDate) {
        events.push({
          id: createEventId(),
          title: `${element.OrderNumber} ${element.MorningAfternoon ? "-" + element.MorningAfternoon : "-Any"
            }-${state.product_list.find((index) => index.ID === parseInt(element["DumpSterSize"]))
              ?.ItemDescription
            }-${element.DeliveryAddress?.split(",")[1] ? element.DeliveryAddress?.split(",")[1] : ""
            }-${element.DeliveryAddress?.split(",")[0]}-$${element.BalanceCost}`,
          start: `${moment(element.DeliveryDate).format("YYYY-MM-DD")}`,
          end: `${moment(element.PickupDate).add(1, "days").format("YYYY-MM-DD")}`,
          status: element.Status,
          pickup: element.isPickUp
        });
      }
    });
    setEventData(events);
  }, [state.product_list, ordersMonthly]);

  useEffect(() => {
    loadOrderSettings();
  }, [loadOrderSettings]);

  useEffect(() => {
    if (settingsLoading || ordersLoading) return;

    parseOrderEvent();
  }, [settingsLoading, ordersLoading, state.product_list, ordersMonthly, parseOrderEvent]);

  const renderEventContent = (eventInfo: any) => {
    let text = eventInfo.event.title;
    const first = text?.split("-", 1).join("-").length;
    const second = text?.split("-", 2).join("-").length;
    const third = text?.split("-", 3).join("-").length;
    const fourth = text?.split("-", 4).join("-").length;
    const fifth = text?.split("-", 5).join("-").length;
    let MorningAfternoon = text?.slice(first + 1, second);
    let icon =
      MorningAfternoon === "Morning" ? (
        <p
          title="Morning"
          className="inline-block w-8 h-6 px-1 pb-2 border-2 border-white border-solid rounded-tl-lg rounded-br-lg">
          AM
        </p>
      ) : MorningAfternoon === "Afternoon" ? (
        <p
          title="Afternoon"
          className="inline-block w-8 h-6 px-1 border-2 border-white border-solid rounded-tl-lg rounded-br-lg">
          PM
        </p>
      ) : (
        <p
          title="Any"
          className="inline-block h-6 px-1 pb-1 border-2 border-white border-solid rounded-tl-lg rounded-br-lg w-9">
          ANY{" "}
        </p>
      );
    let result = (
      <div>
        {/* {text?.slice(0, first + 1)?.toUpperCase()} */}
        {icon}
        {text?.slice(second, third)?.toUpperCase()}
        {text?.slice(third, fourth).toUpperCase()}
        {text?.slice(fourth, fifth).replace(/[0-9]/g, "").toUpperCase()}
        {text?.slice(fifth).toUpperCase()}
      </div>
    );
    return result;
  };

  const getDaysBetweenDates = (startDate: any, endDate: any) => {
    var now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("YYYY-MM-DD"));
      now.add(1, "days");
    }
    return dates;
  };

  const convertEvent = () => {
    var convertedEvent: EventInput = [];
    eventData.forEach((item: any) => {
      const dates = getDaysBetweenDates(moment(item.start), moment(item.end));
      // eslint-disable-next-line
      dates.map((date: Date, index: number) => {
        if (index === 0) {
          convertedEvent.push({
            id: createEventId(),
            title: item.title,
            start: date,
            color: "#22c55e"
          });
        } else if (index === dates.length - 2) {
          convertedEvent.push({
            id: createEventId(),
            title: item.title,
            start: date,
            color: "red"
          });
        } else {
          convertedEvent.push({
            id: createEventId(),
            title: item.start,
            start: date,
            color: "white",
            editable: false,
            selectable: false
          });
        }
      });
    });
    setEventData(convertedEvent);
  };

  const onChangeSwitch = () => {
    enabled ? convertEvent() : parseOrderEvent();
    setEnabled(!enabled);
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    const item = ordersMonthly.filter(
      (item: any) => item["OrderNumber"] === +clickInfo.event.title.split("-")[0]
    )[0];
    if (!item?.OrderNumber) return;

    navigate(`/orders/${item.OrderNumber}`);
  };

  const handleMonthChange = useCallback(
    (payload: any) => {
      try {
        setOrdersLoading(true);

        switch (payload.view.type) {
          case "timeGridDay":
            setEnabled(true);
            return;
          case "dayGridMonth":
          case "timeGridWeek":
            const data = {
              startDate: moment(payload.startStr).format("L"),
              endDate: moment(payload.endStr).format("L")
            };
            axios
              .post(`${baseURL}/order/monthly`, data, {
                headers: {
                  Authorization: `Bearer ${userInfo?.accessToken}`,
                },
              })
              .then((response) => {
                setOrdersMonthly(
                  response.data.data.filter(
                    (item: any) => item["Status"] === "Pending" || item["Status"] === "Delivered"
                  )
                );
              })
              .catch((err) => {
                console.log(err.err);
              });
            setEnabled(true);
        }
      } finally {
        setOrdersLoading(false);
      }
    },
    [setOrdersLoading, setEnabled, setOrdersMonthly]
  );

  const handleEventDidMount = ({ event }: { event: EventApi }) => {
    let eventColor = "";

    if (event.extendedProps.status === "Pending") {
      if (event.extendedProps.pickup === true) {
        eventColor = "	#bb16bc"; // Set Color for pickupready events
      } else {
        eventColor = "#009900"; // Set color for pending events
      }
    } else if (event.extendedProps.status === "Delivered") {
      if (event.extendedProps.pickup === true) {
        eventColor = "	#bb16bc";  // Set Color for pickupready events
      } else {
        eventColor = "#FFFFF"; // Set color for delivered events
      }
    }

    event.setProp("backgroundColor", eventColor);
  };

  return (
    <div className="relative">
      <div className="space-y-4">
        <div className="flex items-center">
          <div className="">
            <h1 className="flex items-center font-bold md:text-xl">
              <FaCalendar className="w-1/2 h-6 mr-1 md:w-7 md:h-7 " /> Calendar
            </h1>
          </div>
        </div>
        {/* <div className="absolute flex items-center place-items-center right-4 max-[528px]:pt-[98px] max-[571px]:pt-[70px] max-[768px]:pt-16 md:right-[202px] md:pt-0 h-[41px]"> */}
        {/* <div className="absolute flex items-center place-items-center right-[202px] mt-1 h-[41px]">
          <Switch
            checked={enabled}
            onChange={onChangeSwitch}
            className={`${enabled ? " bg-green-500" : "bg-red-500"}
                relative inline-flex h-[40px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 btn-switch`}>
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${enabled ? "translate-x-8" : "translate-x-0"}
                  pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full -ml-8 bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div> */}
        <div className="calendar-widget">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "today prevYear,prev,next,nextYear",
              center: "title",
              right: "dayGridMonth,dayGridWeek,dayGridDay"
            }}
            customButtons={{
              OnOff: {
                text: `${enabled ? "ON" : "OFF"}`,
                click: () => onChangeSwitch
              }
            }}
            initialView="dayGridMonth"
            editable={false}
            selectable={true}
            selectMirror={true}
            // dayMaxEvents={true}
            events={eventData}
            eventDidMount={handleEventDidMount}
            eventContent={renderEventContent}
            // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
            // select={handleDateSelect}
            eventClick={handleEventClick}
            // eventColor={eventData['status'] === "Pending" ? "red" : "green"}
            eventOrder={["start"]}
            datesSet={handleMonthChange}
          // eventStartEditable = {true}
          // eventContent={renderEventContent} // custom render function
          // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          />
        </div>
      </div>
      <div
        className={`absolute inset-0 bg-white/90 flex justify-center items-center z-10 ${settingsLoading || ordersLoading ? "" : "hidden"
          }`}>
        <FaSpinner className="w-12 h-12 animate-spin-slow text-primary-2"></FaSpinner>
      </div>
    </div>
  );
};

export default Calendar;