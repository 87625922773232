import { Account } from "config/types";
import React, { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthProvider";
import { EMPTY_ACCOUNT } from "config/constants";

interface AccountContext {
  account: Account;
  loaded: boolean;
  loading: boolean;
  refresh: () => void;
}
// eslint-disable-next-line
const AccountContext: React.Context<AccountContext> = React.createContext<AccountContext>({
  account: EMPTY_ACCOUNT,
  loaded: false,
  loading: false,
  refresh: () => {}
});

interface AccountProvider {
  children: ReactNode;
}
// eslint-disable-next-line
export function AccountProvider({ children }: AccountProvider) {
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [account, setAccount] = useState<Account>(EMPTY_ACCOUNT);

  const { token } = useAuth();

  const loadAccount = useCallback(async () => {
    setLoading(true);
    try {
      const userInfo: Account = (token as unknown as Account);

      setAccount(userInfo);

      setLoaded(true);
    } catch (ex) {
      console.log(ex);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    loadAccount();
  }, [loadAccount]);

  return (
    <AccountContext.Provider
      value={{
        account,
        loaded,
        loading,
        refresh: loadAccount
      }}>
      {children}
    </AccountContext.Provider>
  );
}

export const useAccount = (): AccountContext => {
  const context = useContext<AccountContext>(AccountContext);
  return context;
};
