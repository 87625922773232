import { FaBorderAll } from "react-icons/fa";
import { useGlobalContext } from "../../../context";
import CreatableSelect from "react-select/creatable";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { baseURL } from "config";
import { useOrderContext } from "./OrderContextProvider";
import { calculateSummaryTotals } from "utils/orders.helper";

// interface Payment {
//   PaymentDate: string;
//   PaymentType: string;
//   PaymentAmount: number;
// }

interface myProps { }

const daysOptions = [
  { label: "2", value: "2" },
  { label: "7", value: "7" }
];

const OrderDetail: React.FC<myProps> = () => {
  const { orderEntry, setOrderEntry, readOnly } = useOrderContext();
  const { state, dispatch } = useGlobalContext();
  const { order_details } = orderEntry || {}
  const { DeliveryDate, PickupDate, MilesOneWay } = order_details || {}
  const [orderDetailData, setOrderDetailData] = useState<OrderSetting_interface[]>([]);
  const [ProductData, setProductData] = useState<OrderSetting_interface[]>([]);

  const [pickupDate, setPickupDate] = useState(moment(orderEntry.order_details.DeliveryDate).format("YYYY-MM-DD"));

  const handle7DaysButtonClick = () => {
    const today = moment().format("YYYY-MM-DD");
    const newDate = moment(orderEntry.order_details.DeliveryDate).format("YYYY-MM-DD") == "Invalid date" ? moment(today).add(7, 'days').format("YYYY-MM-DD") : moment(pickupDate).add(7, 'days').format("YYYY-MM-DD");
    setPickupDate(newDate);
    handleChange({ target: { name: 'DeliveryDate', value: newDate } });
  };

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  const getOrderSetting = useCallback(async () => {
    const settingData = await axios.get(`${baseURL}/setting/order-entry`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    if (!settingData.data.success) return;
    setOrderDetailData(
      settingData.data.data.filter(
        (item: OrderSetting_interface) => item["TicketDescription"] === "OrderDetail"
      )
    );
    setProductData(
      settingData.data.data.filter(
        (item: OrderSetting_interface) => item["TicketDescription"] === "Product"
      )
    );
    dispatch({
      type: "UPDATE_PRODUCTLIST",
      payload: {
        product_list: settingData.data.data.filter(
          (item: OrderSetting_interface) => item["TicketDescription"] === "Product"
        )
      }
    });
  }, [setOrderDetailData, setProductData, dispatch]);

  //twest
  function calculateDaysDifference(pickupDateStr: any, deliveryDateStr: any) {
    const formats = ['MM/DD/YYYY', 'YYYY-MM-DD']; // List of accepted date formats

    let pickupDate, deliveryDate;

    // Attempt to parse pickup date and delivery date in different formats
    for (const format of formats) {
      pickupDate = moment(pickupDateStr, format, true); // Parse without strict mode
      if (pickupDate.isValid()) break; // If parsing succeeds, break the loop
    }

    for (const format of formats) {
      deliveryDate = moment(deliveryDateStr, format, true); // Parse without strict mode
      if (deliveryDate.isValid()) break; // If parsing succeeds, break the loop
    }

    // Calculate the difference in days
    let daysDifference;
    if (deliveryDate) {
      daysDifference = deliveryDate.diff(pickupDate, 'days')
    }

    return daysDifference;
  }


  const differDays = calculateDaysDifference(DeliveryDate, PickupDate);
  let additionalDayCalculation: any;
  if (differDays) {
    additionalDayCalculation = differDays > 7 ? differDays - 7 : 0
  }
  const additionalMilage = MilesOneWay > 30 ? MilesOneWay - 30 : 0;













  //twest
  let daysCost = 0;
  useEffect(() => {
    getOrderSetting();
    if (orderEntry?.order_details?.MilesOneWay < 30) orderEntry.summary.MileageCost = 0;
    else {
      if (orderEntry?.order_details?.ExtraMilesCost) {
        orderEntry.summary.MileageCost = parseFloat(
          ((orderEntry?.order_details?.MilesOneWay - 30) * 2 * +orderEntry?.order_details?.ExtraMilesCost).toFixed(2)
        );
      } else if (orderEntry.summary.MileageCost) {
        orderEntry.summary.MileageCost = parseFloat(
          ((orderEntry?.order_details?.MilesOneWay - 30) * 2 * +orderDetailData[1]?.Value).toFixed(2)
        );
      }

    }
    if (additionalDayCalculation > 0) {
      if (orderEntry?.order_details?.AdditionalDayCost) {
        orderEntry.summary.DaysCost = additionalDayCalculation * orderEntry.order_details.AdditionalDayCost;
      } else {
        orderEntry.summary.DaysCost = additionalDayCalculation * 20;
      }
    }

  }, [getOrderSetting, differDays, orderEntry?.order_details?.ExtraMilesCost, orderEntry.summary, additionalDayCalculation]);

  const handleNumberOfDays = (e: any) => {
    if (/^[0-9]*$/.test(e?.value) === false || e?.value === undefined) {
      setOrderEntry((entry: any) => {
        const newEntry = {
          ...entry,
          order_details: {
            ...entry.order_details,
            NumberOfDays: ""
          },
          summary: {
            ...entry.summary,
            DaysCost: 0
          }
        };

        return calculateSummaryTotals(newEntry, +orderDetailData[0]?.Value);
      });

      return;
    }

    let daysCost = 0;
    if (e?.value <= 2) {
      daysCost = -orderDetailData[3].Value;
    } else if (e?.value < 7) {
      daysCost = 0;
    } else {
      if (orderEntry?.order_details?.AdditionalDayCost) {
        daysCost = (e?.value - 7) * orderEntry.order_details.AdditionalDayCost;
      } else {
        daysCost = (e?.value - 7) * 200;
      }

    }

    setOrderEntry((entry: any) => {
      const newEntry = {
        ...entry,
        order_details: {
          ...entry.order_details,
          NumberOfDays: e?.value,
          PickupDate: orderEntry.order_details.PickupDate
            ? moment(orderEntry.order_details.DeliveryDate).add(e?.value, "days").format("YYYY-MM-DD")
            : ""
        },
        summary: {
          ...entry.summary,
          DaysCost: daysCost
        }
      };

      return calculateSummaryTotals(newEntry, +orderDetailData[0]?.Value);
    });
  };

  const handleChange = (e: any) => {
    const entry = { ...orderEntry };

    const { name, value } = e.target;
    entry.order_details[name] = value;

    switch (name) {
      case "DumpSterSize":
        const id = parseInt(value);
        const item = state.product_list.find((item) => item.ID === id);
        if (item !== undefined) {
          localStorage.setItem("selectedProduct", JSON.stringify(item));
        }
        if (item) {
          entry.summary.ProductCost = +parseInt(item.Value);
        }
        break;
      case "MilesOneWay":
        if (value < 30) entry.summary.MileageCost = 0;
        else {
          if (orderEntry?.order_details?.ExtraMilesCost) {
            entry.summary.MileageCost = parseFloat(
              ((value - 30) * 2 * +orderEntry?.order_details?.ExtraMilesCost).toFixed(2)
            );
          } else {
            entry.summary.MileageCost = parseFloat(
              ((value - 30) * 2 * +orderDetailData[1].Value).toFixed(2)
            );
          }

        }

        break;
      case "Shingles":
        if (value <= 0 || value > 50) entry.summary.ShinglesCost = 0;
        else entry.summary.ShinglesCost = (Math.floor((value - 1) / 10) + 1) * 30;
        break;
      case "Concrete":
        entry.summary.ConcreteCost = Math.ceil(parseInt(value) * +orderDetailData[2].Value);
        break;
      case "LaborMisc":
        entry.summary.LaborMiscCost = parseFloat(value);
        break;
      case "Discount":
        entry.summary.Discount = parseFloat(value);
        break;
      case "DeliveryDate":
        if (differDays) {
          entry.order_details.NumberOfDays = differDays;
        }
        const deliveryDate = moment(value, "YYYY-MM-DD");
          const pickupDate = deliveryDate.clone().add(7, 'days').format("YYYY-MM-DD");
          entry.order_details.PickupDate = pickupDate;
          entry.order_details.DeliveryDate = value;

        break;
      case "PickupDate":
        if (differDays) {
          entry.order_details.NumberOfDays = differDays;
        }

        break;
      default:
        break;
    }

    setOrderEntry(calculateSummaryTotals(entry, +orderDetailData[0]?.Value));
  };
  return (
    <div className="panel grow">
      <div className="flex items-center justify-between panel-header">
        <h3 className="flex items-center font-bold">
          <FaBorderAll className="w-4 h-4 mr-1" />
          Order Details
        </h3>
      </div>
      <div className="space-y-4 panel-body grow">
        <div className="form-control">
          <div>
            <label>Product</label>
          </div>
          <div className="input-inline-small">
            <select
              disabled={readOnly}
              placeholder="select"
              name="DumpSterSize"
              value={orderEntry.order_details.DumpSterSize}
              onChange={(e) => handleChange(e)}>
              <option value="" disabled hidden>
                Select Products
              </option>
              {/* <option key = "10" value = "10">10</option>
              <option key = "15" value = "15">15</option>
              <option key = "20" value = "20">20</option>
              <option key = "30" value = "30">30</option>
              <option key = "40" value = "40">40</option> */}
              {ProductData.map((item: OrderSetting_interface) => (
                <option key={item.ID} value={item.ID}>
                  {item.ItemDescription}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* <div className="form-control">
          <div>
            <label>Days</label>
          </div>
          <div className="input-inline-small">
            <CreatableSelect
              isClearable
              onChange={(e: any) => handleNumberOfDays(e)}
              options={daysOptions}
              value={{
                label:
                  orderEntry.order_details.NumberOfDays === ""
                    ? "Select Days"
                    : orderEntry.order_details.NumberOfDays,
                value: orderEntry.order_details.NumberOfDays
              }}
              name="NumberOfDays"
              isDisabled={readOnly}
            />
          </div>
        </div> */}
        <div className="form-control">
          <div>
            <label title="Delivery Date">Delivery Date</label>
          </div>
          <div className="input-inline-small">
            {/* <input type="text" name="DeliveryDate" onChange={(e)=>handleChange(e)} /> */}
            <input
              readOnly={readOnly}
              type="date"
              name="DeliveryDate"
              required
              pattern="\d{4}--\d{2}--\d{2}"
              value={moment(orderEntry.order_details.DeliveryDate).format("YYYY-MM-DD")}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="form-control">
          <div>
            <label title="Morning / Afternoon">Mor / Aft</label>
          </div>
          <div className="input-inline-small">
            <select
              disabled={readOnly}
              placeholder="select"
              name="MorningAfternoon"
              value={orderEntry.order_details.MorningAfternoon}
              onChange={(e) => handleChange(e)}>
              <option value="" disabled hidden>
                Select AM or PM
              </option>
              <option key="Any" value="Any">
                Any
              </option>
              <option key="Morning" value="Morning">
                Morning
              </option>
              <option key="Afternoon" value="Afternoon">
                Afternoon
              </option>
            </select>
          </div>
        </div>
        {/* <div className="form-control">
          <div>
            <label title="Pickup Date">Pickup Date</label>
          </div>
          <div>
            <input
              readOnly={readOnly}
              type="date"
              name="PickupDate"
              required
              pattern="\d{4}-\d{2}-\d{2}"
              value={moment(orderEntry.order_details.PickupDate).format("YYYY-MM-DD")}
              onChange={(e) => handleChange(e.target.value)}
            />
          </div>
        </div> */}
        <div className="form-control">
          <div>
            <label title="Pickup Date">Pickup Date</label>
          </div>
          <div className="input-inline-small">
            {/* <input type="text" name="DeliveryDate" onChange={(e)=>handleChange(e)} /> */}
            <input
              readOnly={readOnly}
              type="date"
              name="PickupDate"
              required
              pattern="\d{4}--\d{2}--\d{2}"
              value={moment(orderEntry.order_details.PickupDate).format("YYYY-MM-DD")}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="form-control">
          <div>
            <label>Miles One Way</label>
          </div>
          <div className="input-inline-small">
            <input
              type="number"
              readOnly={readOnly}
              name="MilesOneWay"
              min={0}
              step={0.01}
              placeholder="Miles One Way"
              value={orderEntry.order_details.MilesOneWay}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="form-control">
          <div>
            <label>Sq. Of Shingles</label>
          </div>
          <div className="input-inline-small">
            <input
              type="number"
              readOnly={readOnly}
              name="Shingles"
              placeholder="Sq. Of Shingles"
              min={0}
              max={50}
              value={orderEntry.order_details.Shingles}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="form-control">
          <div>
            <label>Concrete (Tons)</label>
          </div>
          <div className="input-inline-small">
            <input
              type="number"
              readOnly={readOnly}
              name="Concrete"
              min={0}
              placeholder="Concrete"
              value={orderEntry.order_details.Concrete}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="form-control">
          <div>
            <label>Labor / Misc</label>
          </div>
          <div className="input-inline-small">
            <input
              type="number"
              readOnly={readOnly}
              name="LaborMisc"
              min={0}
              placeholder="Labor / Misc"
              value={orderEntry.order_details.LaborMisc}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="form-control">
          <div>
            <label>Discount</label>
          </div>
          <div className="input-inline-small">
            <input
              type="number"
              readOnly={readOnly}
              name="Discount"
              min={0}
              placeholder="Discount"
              value={orderEntry.order_details.Discount}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        {/* <div className="form-control">
          <div>
            <label title="Delivery Date">Delivery Date</label> 
          </div>
          <div className="input-inline-small">
            <input
              readOnly={readOnly}
              type="date"
              name="DeliveryDate"
              required
              pattern="\d{4}--\d{2}--\d{2}"
              value={moment(orderEntry.order_details.DeliveryDate).format("YYYY-MM-DD")}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div> */}
        {/* <div className="form-control">
          <div>
            <label title="Morning / Afternoon">Mor / Aft</label>
          </div>
          <div className="input-inline-small">
            <select
              disabled={readOnly}
              placeholder="select"
              name="MorningAfternoon"
              value={orderEntry.order_details.MorningAfternoon}
              onChange={(e) => handleChange(e)}>
              <option value="" disabled hidden>
                Select AM or PM
              </option>
              <option key="Any" value="Any">
                Any
              </option>
              <option key="Morning" value="Morning">
                Morning
              </option>
              <option key="Afternoon" value="Afternoon">
                Afternoon
              </option>
            </select>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default OrderDetail;
