import { FaUser } from "react-icons/fa";
import Administration from "./components/Administration";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context";

const User = () => {
  const { state, dispatch } = useGlobalContext();
  const navigate = useNavigate();
  const handleAddUser = () => {
    navigate("/usermanage");
    dispatch({
      type: "TOGGLEUSER",
      ...state,
      payload: {
        user: {
          ID: 0
        }
      }
    });
  };

  return (
    <>
      <div className="flex items-center justify-between ">
        <div>
          <h1 className="flex items-center font-bold md:text-xl ">
            <FaUser className="w-1/2 h-6 mr-1 md:w-7 md:h-7 " /> User
          </h1>
        </div>
        <div>
          <button onClick={() => handleAddUser()} className="btn btn-info">
            Add User
          </button>
        </div>
      </div>
      <div className="flex justify-between w-full mt-2">
        <div className="relative grid w-full h-full">
          <Administration />
        </div>
      </div>
    </>
  );
};

export default User;
