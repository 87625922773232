import { Fragment, useCallback, useState } from "react";

import {
  FaBookOpen,
  FaCreditCard,
  FaDollarSign,
  FaEdit,
  FaRegTrashAlt,
  FaPlus,
  FaSpinner
} from "react-icons/fa";
import axios from "utils/axios.helper";
import { toast } from "react-toastify";
import moment from "moment";
import { useOrderContext } from "./OrderContextProvider";
import { usePaymentDialogContext } from "components/payment/PaymentDialogContextProvider";
import { Payment } from "config/types";
import { EMPTY_PAYMENT_ID } from "config/constants";
import { Dialog, Transition } from "@headlessui/react";
import { generateOrderEntryFromOrder } from "utils/orders.helper";

const Payments = () => {
  const {
    orderId,

    orderEntry,
    setOrderEntry,
    payments,
    refreshPayments
  } = useOrderContext();

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  const [paymentDeleteModalVisible, setPaymentDelelteModalVisible] = useState(false);

  const { open: openPaymentDialog } = usePaymentDialogContext();

  const onAfterPaymentSaved = useCallback(
    (payment: Payment, order: any) => {
      refreshPayments();
      const updatedEntry = generateOrderEntryFromOrder(order);
      setOrderEntry((entry: any) => ({ ...entry, summary: updatedEntry.summary }));
    },
    [refreshPayments, setOrderEntry]
  );

  const [deletingPayementId, setDeletingPaymentId] = useState<string>(EMPTY_PAYMENT_ID);

  return (
    <div className="panel">
      <div className="flex items-center justify-between panel-header">
        <h3 className="flex items-center font-bold">
          <FaBookOpen className="w-4 h-4 mr-1" />
          Payments
        </h3>
        <div className="text-right">
          <button
            className="bg-orange-500 btn btn-icon hover:bg-orange-700 !p-1"
            onClick={() => {
              orderEntry.OrderNumber
                ? openPaymentDialog(orderId, onAfterPaymentSaved)
                : toast.error("Please select order!");
            }}>
            <FaPlus className="w-3 h-3" />
          </button>
        </div>
      </div>
      <div className="overflow-auto h-[96px]">
        <div className="space-y-2 panel-body">
          {payments.map((payment: Payment, i: Number) => (
            <div key={`payment-item-${i}`} className="flex items-center justify-between gap-1">
              <div className="flex items-center">
                <span className="flex-none w-4 mr-1" title={payment.PaymentType}>
                  {payment.PaymentType === "CASH" ? (
                    <FaDollarSign className="w-4 h-4" />
                  ) : (
                    <FaCreditCard className="w-4 h-4" />
                  )}
                </span>
                <div className="flex gap-x-1">
                  <span>{moment(payment.PaymentDate).format("MM/DD/YY")}</span>
                  <span className="w-20 text-right">${payment.PaymentAmount.toFixed(2)}</span>
                </div>
              </div>
              <div className="flex">
                <button
                  className="text-blue-500 btn-text"
                  onClick={() => {
                    openPaymentDialog(
                      {
                        PaymentID: payment.PaymentID,
                        PaymentDate: payment.PaymentDate,
                        PaymentAmount: payment.PaymentAmount,
                        PaymentType: payment.PaymentType,
                        PaymentNote: payment.PaymentNote,
                        PaymentAddedBy: userInfo?.Name
                      } as unknown as Payment,
                      onAfterPaymentSaved
                    );
                  }}>
                  <FaEdit className="w-4 h-4" />
                </button>
                <button
                  className="ml-1 text-red-500 btn-text"
                  onClick={() => {
                    setDeletingPaymentId(payment.PaymentID!);
                    setPaymentDelelteModalVisible(true);
                  }}>
                  <FaRegTrashAlt className="w-4 h-4" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <PaymentDeleteDialog
        visible={paymentDeleteModalVisible}
        onClose={() => setPaymentDelelteModalVisible(false)}
        paymentId={deletingPayementId}
      />
    </div>
  );
};

interface PaymentDeleteDialogProps {
  visible: boolean;
  onClose: () => void;
  paymentId: string;
}
const PaymentDeleteDialog = ({ visible, onClose, paymentId }: PaymentDeleteDialogProps) => {
  const [deleting, setDeleting] = useState<boolean>(false);

  const { refreshPayments, setOrderEntry } = useOrderContext();
  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  const deletePayment = useCallback(async () => {
    setDeleting(true);

    try {
      const response = await axios
        .delete(`/payment/${paymentId}`, {
          headers: {
            Authorization: `Bearer ${userInfo?.accessToken}`,
          },
        })
        .then(({ data }) => data);
      if (response.success) {
        toast.success(response.message);
        refreshPayments();

        const updatedEntry = generateOrderEntryFromOrder(response.order);
        setOrderEntry((entry: any) => ({ ...entry, summary: updatedEntry.summary }));

        onClose();
      } else {
        toast.warn(response.message);
      }
    } catch (ex) {
      console.log(ex);
      toast.error("An error occurred while deleting the payment.");
    } finally {
      setDeleting(false);
    }
  }, [setDeleting, paymentId, refreshPayments, onClose, setOrderEntry]);

  return (
    <Transition show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-[60]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black opacity-50" aria-hidden="true"></div>
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-6 md:p-8">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Panel
                className={`relative w-[500px] max-sm:w-[300px] rounded-xl bg-white p-6 shadow-xl shadow-white/5 md:p-8`}>
                <div className="text-center">
                  <svg
                    aria-hidden="true"
                    className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    Are you sure you want to delete this payment?
                  </h3>
                  <div className="flex justify-between">
                    <button
                      onClick={onClose}
                      disabled={deleting}
                      className="btn text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                      No, cancel
                    </button>
                    <button
                      onClick={deletePayment}
                      disabled={deleting}
                      className="btn text-white bg-orange-500 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium items-center px-5 py-2.5 text-center">
                      {deleting && <FaSpinner className="w-4 h-4 mr-1" />} Yes, I'm sure
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Payments;
