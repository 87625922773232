import { FaSwatchbook, FaSave, FaFile, FaTimes, FaEye, FaEdit, FaTelegram, FaPhone } from "react-icons/fa";
import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import CustomerInfo from "./components/CustomerInfo";
import DeliveryInfo from "./components/DeliveryInfo";
import OrderDetail from "./components/OrderDetail";
import OrderNotes from "./components/OrderNotes";
import Payments from "./components/Payments";
import PreviousOrders from "./components/PreviousOrders";
import Summary from "./components/Summary";
import CustomerNotes from "./components/CustomerNotes";
import DeliveryNotes from "./components/DeliveryNotes";
import axios from "axios";
import { baseURL } from "config";
import { toast } from "react-toastify";
import moment from "moment";

import { OrderContextProvider, useOrderContext } from "./components/OrderContextProvider";
import BlockLoader from "components/_shared/BlockLoader";
import { OrderStatuses } from "config/types";
import { useNavigate } from "react-router-dom";
import BillModal from "components/modal/BillModal";
import { generateEmptyOrderEntry } from "utils/orders.helper";
import AddressModal from "components/modal/AddressModal";
import DeliveryAddressModal from "components/modal/DeliveryAddressModal";
const ws = new WebSocket('wss://matrix.normfred.net/socket');

interface CallerInfo {
  Call_ID: string;
  NameOfCaller: string;
  PhoneNumber: string;
  createdAt?: string;
  updatedAt?: string;
}

const OrderEntry = () => {
  //address start
  const [showPreviousAddresses, setShowPreviousAddresses] = useState(false);
  const [orderHistories, setOrderHistories] = useState([])
  const [updateKey, setUpdateKey] = useState(0);
  const [fetchData, setFetchData] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selecteDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [userTyping, setUserTyping] = useState(false);
  //address end
  const [incomingCalls, setIncomingCalls] = useState<CallerInfo[]>([]);
  const [allCallerHistory, setAllCallerHistory] = useState<CallerInfo[]>([]);
  const [showCallList, setShowCallList] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    orderLoading,
    orderEntry,
    setOrderEntry,
    updateOrderEntryField,
    readOnly,
    setReadOnly,
    refreshPreviousOrders,
    setRenders,
    renders
  } = useOrderContext();

  let [isOpen, setIsOpen] = useState(false)
  let [isAddressModalOpen, setIsAddressModalOpen] = useState(false)
  let [isDeliveryAddressModalOpen, setIsDeliveryAddressModalOpen] = useState(false)
  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  function closeAddressModal() {
    setIsAddressModalOpen(false)
  }

  function openAddressModal() {
    setIsAddressModalOpen(true)
  }
  function closeDeliveryAddressModal() {
    setIsDeliveryAddressModalOpen(false)
  }

  function openDeliveryAddressModal() {
    setIsDeliveryAddressModalOpen(true)
  }

  const [customers, setCustomers] = useState<Customer[]>([]);
  const storage_string = localStorage.getItem("USER");
  const storage = storage_string ? JSON.parse(storage_string) : [];

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }



  /// caller id related work start
  useEffect(() => {
    ws.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.onmessage = event => {
      const data = JSON.parse(event.data);
      if (data.type === 'incomingCall') {
        setIncomingCalls(prevCalls => [...prevCalls, data.callerInfo]);
      }
    };

    return () => {
      ws.close();
    };
  }, []);

  /// caller id related work stop

  //address related work start
  const handlePreviousAddressClick = (address: any) => {

    updateOrderEntryField("customer", "BillingAddress", address);
    // setSelectedAddress(address)
    setShowPreviousAddresses(false);
    setIsAddressModalOpen(false)
  };
  const handlePreviousDeliveryAddressClick = (address: any) => {
    updateOrderEntryField("delivery_contact", "DeliveryAddress", address);
    // setSelectedDeliveryAddress(address)
    setShowPreviousAddresses(false);
    setIsDeliveryAddressModalOpen(false)
  };
  //address related work stop

  const getCustomers = async () => {
    const resp = await axios.get(`${baseURL}/customer/`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    if (!resp) return;
    if (resp.data.success) {
      setCustomers(resp.data.data);
    }
  };

  const updateCustomer = async () => {
    var customer = {
      ...orderEntry.customer,
      PhoneNumber: orderEntry.customer.PhoneNumber?.slice(-10),
      ...orderEntry.delivery_contact,
      DeliveryContactPhone: orderEntry.delivery_contact.DeliveryContactPhone?.slice(-10),
      DateAdded: orderEntry.customer.DateAdded
        ? orderEntry.customer.DateAdded
        : new Date().toISOString(),
      AddedBy: orderEntry.customer.AddedBy ? orderEntry.customer.AddedBy : storage["Name"],
      LastModified: new Date().toISOString(),
      LastModifiedBy: storage["Name"]
    };
    const updateCustomer = await axios.put(
      `${baseURL}/customer/${orderEntry.customer.PhoneNumber?.slice(-10)}`,
      customer, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    }
    );
    if (!updateCustomer) {
      toast.error("Customer already exist.");
      return;
    } else {
      if (updateCustomer.data.success) {
      } else {
        toast.error(updateCustomer.data.message);
      }
    }
  };

  let productName: any;
  let productString = localStorage.getItem("selectedProduct");
  if (productString !== null) {
    productName = JSON.parse(productString)
  }

  const createRandomPassword = () => {
    const password = Math.floor(Math.random() * 900000) + 100000;
    return password
  }

  const generatedPassword = createRandomPassword();

  const storeAddress = async (orderNumber: number) => {
    const addressDetailsx = {
      BillingAddress: orderEntry.customer.BillingAddress,
      CustomerPhoneNumber: orderEntry.customer.PhoneNumber?.slice(-10),
      DeliveryAddress: orderEntry.delivery_contact.DeliveryAddress,
      OrderNumber: orderNumber,
    };
    fetch(`${baseURL}/address`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userInfo?.accessToken}`
      },
      body: JSON.stringify({ addressDetailsx })
    })
      .then(res => res.json())
      .then(data => console.log(data))
  }

  const createOrder = async (status = OrderStatuses.PENDING) => {
    if (!orderEntry.customer.PhoneNumber) {
      toast.error("PhoneNumber is required!");
      return;
    }
    const order = {
      ...orderEntry.customer,
      PhoneNumber: orderEntry.customer.PhoneNumber?.slice(-10),
      Password: generatedPassword,
      ...orderEntry.delivery_contact,
      DeliveryContactPhone: orderEntry.delivery_contact.DeliveryContactPhone?.slice(-10),
      ...orderEntry.order_details,
      DeliveryDate: orderEntry.order_details.DeliveryDate
        ? moment(orderEntry.order_details.DeliveryDate).format("YYYY-MM-DD")
        : "",
      ...orderEntry.summary,
      ...orderEntry.order_info,
      Status: status,
      CreatedBy: storage["Name"],
      CreatedOn: moment(new Date()).format("MM/DD/YY h:mm A"),
      OrderDate: new Date().toISOString(),
      DateAdded: new Date().toISOString(),
      AddedBy: storage["Name"],
      ProductName: "productName?.ItemDescription"
    };
    try {
      const response = await axios.post(`${baseURL}/order`, order, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })
        .then(({ data }) => data);
      if (!response.success) {
        toast.error(response.message);
        return;
      }
      storeAddress(response?.data?.OrderNumber)

      toast.success(response.message);

      navigate(`/orders/${response.data.OrderNumber}/edit`);
      refreshPreviousOrders();
    } catch (ex) {
      console.log(ex);
      toast.error("Create Order Failed!");
    }
  };

  // test

  const updateAddress = async () => {
    const addressDetails = {
      BillingAddress: orderEntry.customer.BillingAddress,
      CustomerPhoneNumber: orderEntry.customer.PhoneNumber?.slice(-10),
      DeliveryAddress: orderEntry.delivery_contact.DeliveryAddress?.slice(-10),
    };
    fetch(`${baseURL}/address/${orderEntry?.OrderNumber}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userInfo?.accessToken}`
      },
      body: JSON.stringify({ addressDetails })
    })
      .then(res => res.json())
      .then(data => setFetchData(!fetchData))
  }

  const queryNumber = orderEntry.customer.PhoneNumber?.slice(-10)
  useEffect(() => {
    fetch(`${baseURL}/address/${queryNumber}`)
      .then(res => res.json())
      .then(data => {
        setAddresses(data?.data)
      })
  }, [queryNumber, fetchData])
  //test

  const update = async (paymentFlag: boolean) => {
    var Status =
      orderEntry.order_info.Status === "Pending"
        ? "Pending"
        : orderEntry.order_info.Status === "Cancel"
          ? "Pending"
          : orderEntry.order_info.Status === "Quote"
            ? paymentFlag
              ? "Quote"
              : "Pending"
            : orderEntry.order_info.Status;
    var order = {
      ...orderEntry.customer,
      PhoneNumber: orderEntry.customer.PhoneNumber?.slice(-10),
      ...orderEntry.delivery_contact,
      DeliveryContactPhone: orderEntry.delivery_contact.DeliveryContactPhone?.slice(-10),
      ...orderEntry.order_details,
      ...orderEntry.summary,
      ...orderEntry.order_info,
      Status,
      CreatedBy: storage["Name"],
      LastModifiedOn: moment(new Date()).format("MM/DD/YY h:mm A"),
      LastModifiedBy: storage["Name"],
      DeliveryDate: orderEntry.order_details.DeliveryDate
        ? moment(orderEntry.order_details.DeliveryDate).format("L")
        : "",
      PickupDate: orderEntry.order_details.PickupDate
        ? moment(orderEntry.order_details.PickupDate).format("L")
        : ","
    };
    const updateOrder = await axios.put(`${baseURL}/order/${orderEntry.OrderNumber}`, order, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    if (!updateOrder) {
      toast.error("Customer already exist.");
      return;
    } else {
      if (updateOrder.data.success) {
        if (!paymentFlag) toast.success(updateOrder.data.message);
        refreshPreviousOrders();
        updateOrderEntryField("order_info", "Status", Status);
        storeAddress(updateOrder?.data?.data?.OrderNumber)
      } else {
        toast.error(updateOrder.data.message);
      }
    }
    updateCustomer();
  };

  const handleCancel = async () => {
    var order = {
      ...orderEntry.customer,
      PhoneNumber: orderEntry.customer.PhoneNumber?.slice(-10),
      ...orderEntry.delivery_contact,
      DeliveryContactPhone: orderEntry.delivery_contact.DeliveryContactPhone?.slice(-10),
      ...orderEntry.order_details,
      ...orderEntry.summary,
      ...orderEntry.order_info,
      Status: "Cancel",
      PickupDate: orderEntry.order_details.PickupDate.replaceAll("-", "/"),
      CreatedBy: storage["Name"],
      LastModifiedOn: new Date().toISOString(),
      LastModifiedBy: storage["Name"]
    };

    const updateOrder = await axios.put(`${baseURL}/order/${orderEntry.OrderNumber}`, order, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    if (!updateOrder) {
      toast.error("Customer already exist.");
      return;
    } else {
      if (updateOrder.data.success) {
        toast.success("Order canceled successfully");
        refreshPreviousOrders();
        updateOrderEntryField("order_info", "Status", "Cancel");
      } else {
        toast.error(updateOrder.data.message);
      }
    }
  };

  const handlePick = async () => {
    const isPickUp = !orderEntry.order_info.isPickUp;
    updateOrderEntryField("order_info", "isPickUp", isPickUp);
    const updateOrder = await axios.put(`${baseURL}/order/${orderEntry.OrderNumber}`, { isPickUp }, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    if (!updateOrder) {
      toast.error("Customer already exist.");
      return;
    } else {
      if (updateOrder.data.success) {
        toast.success(updateOrder.data.message);

        refreshPreviousOrders();
      } else {
        toast.error(updateOrder.data.message);
      }
    }
  };


  /// caller id functionality start
  const fetchIncomingCalls = async () => {
    try {
      const response = await axios.get(`${baseURL}/incomingcall`);
      setIncomingCalls(response.data?.callerInfo);
    } catch (error) {
      console.error('Error fetching incoming calls:', error);
    }
  };


  const handleCustomersCall = () => {
    setLoading(true)
    fetch(`${baseURL}/get-caller-info`)
      .then(res => res.json())
      .then(data => {
        if (data?.success) {
          setAllCallerHistory(data?.callerInfo)
          setLoading(false)
        } else {
          setLoading(false)
          toast.error("There was an error!")
        }

      })
  }

  const handleCustomer = (customerNumber: any) => {
    fetch(`${baseURL}/get-caller-details?number=${customerNumber}`)
      .then(res => res.json())
      .then(data => {
        if (data?.message == "Old customer") {
          const { PhoneNumber, CustomerName, EmailAddress, BillingAddress, DeliveryContactPhone, DeliveryContact, DeliveryAddress } = data?.data || {};
          setOrderEntry({
            ...generateEmptyOrderEntry(),
            customer: {
              PhoneNumber: PhoneNumber,
              CustomerName: CustomerName,
              EmailAddress: EmailAddress,
              BillingAddress: BillingAddress,
              CustomerNotes: "",
              DateAdded: "",
              AddedBy: "",
              LastModified: "",
              LastModifiedBy: ""
            },

            delivery_contact: {
              DeliveryContactPhone: DeliveryContactPhone,
              DeliveryContact: DeliveryContact,
              DeliveryAddress: DeliveryAddress,
              DeliveryNotes: ""
            }
          });
        } else {
          const { NameOfCaller, PhoneNumber } = data?.newCusomerData?.customerInfo || {};
          const processNumber = PhoneNumber.slice(6, 16)
          setOrderEntry({
            ...generateEmptyOrderEntry(),
            customer: {
              PhoneNumber: processNumber,
              CustomerName: NameOfCaller,
              EmailAddress: "",
              BillingAddress: "",
              CustomerNotes: "",
              DateAdded: "",
              AddedBy: "",
              LastModified: "",
              LastModifiedBy: ""
            },
            delivery_contact: {
              DeliveryContactPhone: "",
              DeliveryContact: "",
              DeliveryAddress: "",
              DeliveryNotes: ""
            }
          });
        }
      })
    setReadOnly(false)
  }

  // const getOrderHistory = () => {
  //   fetch(`${baseURL}/orderHistory/${orderEntry?.OrderNumber}`)
  //     .then(res => res.json())
  //     .then(data  => {setOrderHistories(data)})
  // }
  useEffect(() => {
    setLoading(true);
    if (orderEntry?.OrderNumber) {
      axios.get(`${baseURL}/orderHistory/${orderEntry.OrderNumber}`)
        .then(response => {
          setOrderHistories(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching order history:', error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [orderEntry?.OrderNumber]);

  const postOrderHistory = async (action: string) => {
    try {
      const response = await axios.post(`${baseURL}/orderHistory`, {
        OrderNumber: orderEntry?.OrderNumber,
        ModifiedType: action,
        ModifiedBy: userInfo?.Name
      });

      if (response.status === 200) {
        // Refetch order history after successful POST request
        setUpdateKey(prev => prev + 1)
        const fetchResponse = await axios.get(`${baseURL}/orderHistory/${orderEntry?.OrderNumber}`);
        axios.get(`${baseURL}/orderHistory/${orderEntry.OrderNumber}`)
        .then(response => {
          setOrderHistories(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching order history:', error);
          setLoading(false);
        });
        setOrderHistories(fetchResponse.data);
      } else {
        console.error('Failed to post order history:', response.statusText);
      }
    } catch (error) {
      console.error('Error posting order history:', error);
    }
  };




  /// caller id functionality stop

  return (
    <>
      <div className="flex items-center justify-between overflow-visible -z-50	">
        <div className="flex items-center">
          <h1 className="flex items-center font-bold md:text-xl">
            <FaSwatchbook className="w-6 h-6 mr-1 md:w-7 md:h-7" />
            {!orderEntry.OrderNumber ? "New Order" : `Order ${orderEntry.OrderNumber}`}
          </h1>

          {/* test start  */}
          <div className="text-right">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="justify-center rounded-md text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                  <button className="ml-2" onClick={handleCustomersCall}><img src="/assets/phone-ringing-icon.gif" alt="" className="w-12 h-12 -mt-1" /></button>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute z-10 w-56 md:w-[300px] h-96 overflow-y-scroll -left-20 md:left-0 mt-2 md:w-80 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                  <div className="px-1 py-1 ">
                    {
                      // eslint-disable-next-line array-callback-return
                      allCallerHistory.map((callerData) => {
                        return <Menu.Item key={callerData?.Call_ID}>
                          {({ active }) => (
                            <div
                              className={`${active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                } group flex w-full items-center rounded-md px-2 py-2 text-xs md:text-sm flex justify-between gap-2 items-center cursor-pointer`}
                              onClick={() => handleCustomer(callerData?.PhoneNumber.slice(6, 16))}
                            >
                              <div className="flex w-full justify-between items-center">
                                <div>
                                  <p>{callerData?.NameOfCaller}</p>
                                  <p className="text-[10px]">{moment(callerData?.createdAt).format("YYYY-MM-DD h:mmA")}</p>
                                </div>
                                <p>{callerData?.PhoneNumber.slice(5, 16)}</p>
                              </div>
                              <FaPhone className="w-4 h-4" />
                            </div>
                          )}
                        </Menu.Item>
                      })
                    }
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          {/* test end  */}
        </div>
        {/* <div className="">
          <button className="btn btn-danger rounded-full">
            <FaPlus className="w-4 h-4 mr-1" /> Reload
          </button>
        </div> */}
        <div className="flex items-center">
          {orderEntry.OrderNumber ? (
            <span
              className={`${orderEntry.order_info.Status === "Cancel"
                ? "bg-gray-300"
                : orderEntry.order_info.Status === "Quote"
                  ? "bg-gray-500"
                  : orderEntry.order_info.Status === "Pending"
                    ? "bg-yellow-500"
                    : orderEntry.order_info.Status === "Delivered"
                      ? "bg-blue-500"
                      : orderEntry.order_info.Status === "Completed"
                        ? "bg-green-500"
                        : "bg-gray-500"
                } text-white px-3 rounded-full font-normal text-xs leading-none h-6 flex items-center opacity-75`}>
              {orderEntry.order_info.Status.toUpperCase()}
            </span>
          ) : (
            ""
          )}
          <span className="flex items-center ml-1">
            {!orderEntry.OrderNumber ? (
              <span
                className="bg-red-500 rounded-full px-3 text-white font-normal text-xs leading-none h-6 flex items-center opacity-75"
                title="New Order">
                NEW
              </span>
            ) : readOnly ? (
              <button
                className="btn btn-icon bg-gray-300 p-[6px] text-black"
                title="View Mode"
                onClick={() => setReadOnly(false)}>
                <FaEye className="w-4 h-4" />
              </button>
            ) : (
              <button
                className="btn btn-icon bg-blue-500 p-[6px]"
                title="Edit Mode"
                onClick={() => setReadOnly(true)}>
                <FaEdit className="w-4 h-4" />
              </button>
            )}
          </span>
        </div>
      </div>
      <div className="space-y-4 w-full pt-4">
        <div className="grid w-full grid-cols-1 gap-4 xl:grid-cols-2">
          <div className="grid grid-cols-1 gap-4 xl:col-span-1 md:grid-cols-2">
            <div className="relative flex flex-col justify-between space-y-4">
              <CustomerInfo customers={customers} getCustomers={getCustomers} openAddressModal={openAddressModal} selectedAddress={selectedAddress} addresses={addresses} storeAddress={storeAddress} />
              <DeliveryInfo addresses={addresses} openDeliveryAddressModal={openDeliveryAddressModal} closeDeliveryAddressModal={closeDeliveryAddressModal} selectedAddress={selecteDeliveryAddress} />
              <CustomerNotes />
            </div>
            <div className="relative flex flex-col justify-between space-y-4">
              <OrderDetail />
              <OrderNotes />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 xl:col-span-1 md:grid-cols-2">
            <div className="relative flex flex-col justify-between space-y-4">
              <Summary />
              <Payments />
            </div>
            <div className="relative flex flex-col justify-between space-y-4">
              <DeliveryNotes orderHistories={orderHistories} updateKey={updateKey} />
              <PreviousOrders />
            </div>
          </div>
        </div>
      </div>

      <div className={`h-[72px] lg:h-auto`}>
        <div
          className={`flex justify-center gap-2 bg-white fixed w-full bottom-0 left-0 py-4 shadow-[0_0_6px_0px_#0000003f] xl:static xl:shadow-none`}>
          {!orderEntry.OrderNumber ? (
            <div className="flex gap-1">
              <div className="flex gap-1">
                <button
                  className="btn w-40 btn-info rounded-md"
                  onClick={() => createOrder(OrderStatuses.QUOTE)}>
                  <FaFile className="w-4 h-4 mr-1" />
                  Save As Quote
                </button>
                <button className="btn w-40 btn-primary rounded-md" onClick={() => {
                  createOrder()
                }}>
                  <FaSave className="w-4 h-4 mr-1" />
                  Save Order
                </button>
              </div>
            </div>
          ) : (
            <div className="flex gap-1">
              {orderEntry.order_info.Status !== "Quote" && (
                <label className="mr-2 flex items-center rounded-md cursor-pointer select-none bg-gray-300 pl-3 pr-2">
                  <span className="mr-3 pl-1">Pickup Ready?</span>
                  <span className="flex items-center relative">
                    <input
                      type="checkbox"
                      className={`select-none appearance-none border-none checked:bg-none transition-colors cursor-pointer w-[78px] h-7 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-50 checked:text-green-400 bg-gray-400`}
                      checked={orderEntry.order_info.isPickUp}
                      onChange={handlePick}
                    />
                    <span className="absolute text-xs uppercase left-0 text-white pl-3">Yes</span>
                    <span className="absolute text-xs uppercase right-0 text-black pr-3">NO</span>
                    <span
                      className={`${orderEntry.order_info.isPickUp ? "right-0" : "right-[39px]"
                        } w-[39px] h-7 absolute rounded-lg transition-all bg-gray-200`}
                    />
                  </span>
                </label>
              )}
              <div className="text-right md:hidden">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="btn w-20 btn-info rounded-md inline-flex justify-center px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                      MORE
                      <ChevronDownIcon
                        className="-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 -top-[170px] mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                      <div className="px-1 py-1 flex flex-col items-center gap-2 ">
                        <Menu.Item>
                          <button
                            className="btn w-[180px] btn-info rounded-md"
                            onClick={openModal}>
                            <FaTelegram className="w-4 h-4 mr-1" />
                            Send
                          </button>
                        </Menu.Item>
                        <Menu.Item>
                          <div>
                            {orderEntry.order_info.Status !== "Cancel" && (
                              <button
                                className="justify-center w-[180px] btn btn-danger rounded-md"
                                onClick={() => {
                                  handleCancel()
                                  postOrderHistory("cancel-order")
                                }}>
                                <FaTimes className="w-4 h-4 mr-1" />
                                Cancel Order
                              </button>
                            )}
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          {readOnly ? (
                            <button
                              className="btn bg-blue-500 w-[180px] rounded-md"
                              onClick={() => setReadOnly(false)}>
                              <FaEdit className="w-4 h-4 mr-1" /> Edit
                            </button>
                          ) : (
                            <button
                              className="btn flex justify-center w-[180px] btn-primary rounded-md"
                              disabled={readOnly}
                              onClick={() => {
                                update(false)
                                postOrderHistory("update-order")
                              }}>
                              <FaSave className="w-4 h-4 mr-1" />
                              Update
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="hidden md:flex gap-1">
                <button
                  className="btn w-40 btn-info rounded-md"
                  onClick={openModal}>
                  <FaTelegram className="w-4 h-4 mr-1" />
                  Send
                </button>
                {orderEntry.order_info.Status !== "Cancel" && (
                  <button
                    className="justify-center w-[180px] btn btn-danger rounded-md"
                    onClick={() => {
                      handleCancel()
                      postOrderHistory("cancel-order")
                    }}>
                    <FaTimes className="w-4 h-4 mr-1" />
                    Cancel Order
                  </button>
                )}

                {readOnly ? (
                  <button
                    className="btn btn-info bg-blue-500 w-[180px] rounded-md"
                    onClick={() => setReadOnly(false)}>
                    <FaEdit className="w-4 h-4 mr-1" /> Edit
                  </button>
                ) : (
                  <button
                    className="btn flex justify-center w-[180px] btn-primary rounded-md"
                    disabled={readOnly}
                    onClick={() => {
                      update(false)
                      postOrderHistory("update-order")
                    }}>
                    <FaSave className="w-4 h-4 mr-1" />
                    Update
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <div className="w-[200px] -mt-60 z-50 h-[500px] bg-black">

      </div> */}
      <BlockLoader loading={orderLoading} />
      <BillModal openModal={openModal} closeModal={closeModal} postOrderHistory={postOrderHistory} isOpen={isOpen} setIsOpen={setIsOpen} updateKey={updateKey} setUpdateKey={setUpdateKey} />
      <AddressModal openModal={openAddressModal} handlePreviousAddressClick={handlePreviousAddressClick} closeModal={closeAddressModal} isOpen={isAddressModalOpen} setIsOpen={setIsAddressModalOpen} addresses={addresses} setFetchData={setFetchData} fetchData={fetchData} />
      <DeliveryAddressModal setFetchData={setFetchData} fetchData={fetchData} openModal={openDeliveryAddressModal} handlePreviousAddressClick={handlePreviousDeliveryAddressClick} closeModal={closeDeliveryAddressModal} isOpen={isDeliveryAddressModalOpen} setIsOpen={setIsAddressModalOpen} addresses={addresses} />
    </>
  );
};

const OrderEntryWithContext = () => (
  <OrderContextProvider>
    <OrderEntry />
  </OrderContextProvider>
);

export default OrderEntryWithContext;