import { FaChartBar, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import Chart from "./components/chart";
import { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { baseURL } from "config";
import Receivable from "./components/Receivable";

interface YearlyData {
  [year: string]: {
    totalCost: number;
    totalOrders: number;
  };
}


const Reports = () => {
  useEffect(() => {
    yearReport("Sales");
    getOrders();
  }, []);
  const [category, setCategory] = useState<string[]>([]);
  const [series, setSeries] = useState<serie[]>([]);

  const [currentDate, setCurrentDate] = useState(moment(new Date()).format("MMMM YYYY DD"));
  const [startWeek, setStartWeek] = useState("");
  const [endWeek, setEndWeek] = useState("");

  const [reportType, setReportType] = useState("YEAR");
  const [reportsBy, setReportsBy] = useState("Sales");

  const [completedata, SetCompleteData] = useState([]);
  // const [searchKeyword, setSearchKeyword] = useState("");
  const [total, setTotal] = useState(0);

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  const getOrders = () => {
    axios
      .get(`${baseURL}/order`, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })
      .then((response) => {
        SetCompleteData(
          response.data.data
            .filter((item: any) => item["Status"] === "Completed" && parseFloat(item["BalanceCost"]) !== 0)
            .sort((a: any, b: any) => b.OrderNumber - a.OrderNumber)
        );
        var sum = 0;
        response.data.data
          .filter((item: any) => item["Status"] === "Completed")
          .forEach((item: any) => {
            sum += parseFloat(item["BalanceCost"]);
          });
        setTotal(sum);
      })
      .catch((err) => {
        console.log(err.err);
      });
  };

  const tenYearReport = async (ReportsBy: string) => {
    const currentMonthsOrders: any = await axios.get(
      `${baseURL}/report/getSalesByTenYear`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    }
    );
    const yearCategory = currentMonthsOrders.data.data.map((item: any) => item.year);
    setCategory(yearCategory);

    const totalCostSeries: number[] = currentMonthsOrders.data.data.map((item: any) => parseInt(item.totalCost));

    // You can also create a series for total orders if needed
    const totalOrderSeries: number[] = currentMonthsOrders.data.data.map((item: any) => item.totalOrder);

    switch (ReportsBy) {
      case "Sales":
        setSeries([
          {
            name: "TotalCost",
            data: totalCostSeries,
          },
        ]);
        break;
      case "Orders":
        setSeries([
          {
            name: "TotalOrder",
            data: totalOrderSeries,
          },
        ]);

        break;
      default:
        break;
    }



    setReportType("10YEAR");
    // setCurrentDate("Last 10 Years");
  };





  const yearReport = async (ReportsBy: string) => {
    var seriesPrevious: number[] = new Array(12).fill(0);
    var seriesCurrent: number[] = new Array(12).fill(0);
    setCategory([
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ]);
    //previousYear
    const previousYearOrders: any = await axios.get(
      `${baseURL}/report/getSalesByYear/${moment(new Date()).add(-1, "years").format("YYYY")}`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    }
    );
    if (!previousYearOrders.data.success) return;
    switch (ReportsBy) {
      case "Sales":
        previousYearOrders.data.data.forEach((item: any) => {
          seriesPrevious[+moment(item.DeliveryDate).format("M") - 1] += parseInt(item.TotalCost);
        });
        break;
      case "Orders":
        previousYearOrders.data.data.forEach((item: any) => {
          seriesPrevious[+moment(item.DeliveryDate).format("M") - 1] += 1;
        });
        break;
      default:
        break;
    }

    //currentYear
    const currentYearOrders: any = await axios.get(
      `${baseURL}/report/getSalesByYear/${moment(new Date()).format("YYYY")}`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    }
    );
    if (!currentYearOrders.data.success) return;
    switch (ReportsBy) {
      case "Sales":
        currentYearOrders.data.data.forEach((item: any) => {
          seriesCurrent[+moment(item.DeliveryDate).format("M") - 1] += parseInt(item.TotalCost);
        });
        break;
      case "Orders":
        currentYearOrders.data.data.forEach((item: any) => {
          seriesCurrent[+moment(item.DeliveryDate).format("M") - 1] += 1;
        });
        break;
      default:
        break;
    }

    setSeries([
      {
        name: moment(new Date()).add(-1, "years").format("YYYY"),
        data: seriesPrevious
      },
      {
        name: moment(new Date()).format("YYYY"),
        data: seriesCurrent
      }
    ]);
    setReportType("YEAR");
    setCurrentDate(moment(new Date()).format("YYYY"));
  };

  const yearChange = async (flag: boolean) => {
    var seriesPrevious: number[] = new Array(12).fill(0);
    var seriesCurrent: number[] = new Array(12).fill(0);

    var currentYear: string = "";
    if (flag) {
      setCurrentDate(moment(currentDate).add(1, "years").format("YYYY"));
      currentYear = moment(currentDate).add(1, "years").format("YYYY");
    } else {
      setCurrentDate(moment(currentDate).add(-1, "years").format("YYYY"));
      currentYear = moment(currentDate).add(-1, "years").format("YYYY");
    }

    // console.log('currendDate:::', moment(new Date(currentYear )).add(1, 'years').format("YYYY"))
    //previousYear
    const previousYearOrders: any = await axios.get(
      `${baseURL}/report/getSalesByYear/${moment(new Date(currentYear)).format("YYYY")}`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    }
    );
    if (!previousYearOrders.data.success) return;
    switch (reportsBy) {
      case "Sales":
        previousYearOrders.data.data.forEach((item: any) => {
          seriesPrevious[+moment(item.DeliveryDate).format("M") - 1] += parseInt(item.TotalCost);
        });
        break;
      case "Orders":
        previousYearOrders.data.data.forEach((item: any) => {
          seriesPrevious[+moment(item.DeliveryDate).format("M") - 1] += 1;
        });
        break;
      default:
        break;
    }

    // console.log('seriesPrevious', seriesPrevious)
    // currentYear
    const currentYearOrders: any = await axios.get(
      `${baseURL}/report/getSalesByYear/${moment(new Date(currentYear))
        .add(1, "years")
        .format("YYYY")}`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    }
    );
    if (!currentYearOrders.data.success) return;
    switch (reportsBy) {
      case "Sales":
        currentYearOrders.data.data.forEach((item: any) => {
          seriesCurrent[+moment(item.DeliveryDate).format("M") - 1] += parseInt(item.TotalCost);
        });
        break;
      case "Orders":
        currentYearOrders.data.data.forEach((item: any) => {
          seriesCurrent[+moment(item.DeliveryDate).format("M") - 1] += 1;
        });
        break;
      default:
        break;
    }

    // console.log('seriesCurrent', seriesCurrent)

    setSeries([
      {
        name: moment(new Date(currentYear)).format("YYYY"),
        data: seriesPrevious
      },
      {
        name: moment(new Date(currentYear)).add(1, "years").format("YYYY"),
        data: seriesCurrent
      }
    ]);
  };

  const monthReport = async (ReportsBy: string) => {
    const daysOfMonth = moment(new Date()).daysInMonth();
    const monthCategory = Array.from({ length: +daysOfMonth }, (x, i) => (i + 1).toString());
    setCategory(monthCategory);

    var series: number[] = new Array(+daysOfMonth).fill(0);
    const currentMonthsOrders: any = await axios.get(
      `${baseURL}/report/getSalesByMonth/${moment(new Date()).format("YYYY-MM")}`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    }
    );
    if (!currentMonthsOrders.data.success) return;

    switch (ReportsBy) {
      case "Sales":
        currentMonthsOrders.data.data.forEach((item: any) => {
          item.DeliveryDate = moment(item.DeliveryDate).format("YYYY-MM");
          series[+moment(item.DeliveryDate).format("D") - 1] += parseInt(item.TotalCost);
        });
        break;
      case "Orders":
        currentMonthsOrders.data.data.forEach((item: any) => {
          item.DeliveryDate = moment(item.DeliveryDate).format("YYYY-MM");
          series[+moment(item.DeliveryDate).format("D") - 1] += 1;
        });
        break;
      default:
        break;
    }
    // currentMonthsOrders.data.data.forEach((item: any) => {
    //   series[+moment(item.DeliveryDate).format("D")-1] += item.TotalCost
    // })
    setSeries([
      {
        name: "TotalCost",
        data: series
      }
    ]);
    setReportType("MONTH");
    setCurrentDate(moment(new Date()).format("MMMM YYYY"));
  };

  const monthChange = async (flag: boolean) => {
    var currentMonth: string = "";
    if (flag) {
      setCurrentDate(moment(currentDate).add(1, "months").format("MMMM YYYY"));
      currentMonth = moment(currentDate).add(1, "months").format("YYYY-MM");
    } else {
      setCurrentDate(moment(currentDate).add(-1, "months").format("MMMM YYYY"));
      currentMonth = moment(currentDate).add(-1, "months").format("YYYY-MM");
    }

    const daysOfMonth = moment(currentMonth, "YYYY-MM").daysInMonth();
    const monthCategory = Array.from({ length: +daysOfMonth }, (x, i) => (i + 1).toString());
    setCategory(monthCategory);

    var series: number[] = new Array(+daysOfMonth).fill(0);
    const currentMonthsOrders: any = await axios.get(
      `${baseURL}/report/getSalesByMonth/${currentMonth}`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    }
    );
    if (!currentMonthsOrders.data.success) return;
    switch (reportsBy) {
      case "Sales":
        currentMonthsOrders.data.data.forEach((item: any) => {
          series[+moment(item.DeliveryDate).format("D") - 1] += parseInt(item.TotalCost);
        });
        break;
      case "Orders":
        currentMonthsOrders.data.data.forEach((item: any) => {
          series[+moment(item.DeliveryDate).format("D") - 1] += 1;
        });
        break;
      default:
        break;
    }
    // currentMonthsOrders.data.data.forEach((item: any) => {
    //   series[+moment(item.DeliveryDate).format("D")-1] += item.TotalCost
    // })
    setSeries([
      {
        name: "TotalCost",
        data: series
      }
    ]);
  };

  const weekReport = async (ReportsBy: string) => {
    setCategory(["Sun", "Mon", "Tue", "Web", "Thr", "Fri", "Sat"]);
    var data = {
      startDate: moment(new Date()).startOf("week").format("MM/DD/YYYY"),
      endDate: moment(new Date()).endOf("week").format("MM/DD/YYYY")
    };

    const currentWeekOrder: any = await axios.post(`${baseURL}/report/getSalesByWeek/`, data, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    if (!currentWeekOrder.data.success) return;

    var series: number[] = new Array(7).fill(0);
    switch (ReportsBy) {
      case "Sales":
        currentWeekOrder.data.data.forEach((item: any) => {
          series[
            +moment(new Date(item.DeliveryDate).getTime() - new Date(data.startDate).getTime()) /
            1000 /
            60 /
            60 /
            24
          ] += parseInt(item.TotalCost);
        });
        break;
      case "Orders":
        currentWeekOrder.data.data.forEach((item: any) => {
          series[
            +moment(new Date(item.DeliveryDate).getTime() - new Date(data.startDate).getTime()) /
            1000 /
            60 /
            60 /
            24
          ] += 1;
        });
        break;
      default:
        break;
    }

    setSeries([
      {
        name: "TotalCost",
        data: series
      }
    ]);

    setReportType("WEEK");
    setStartWeek(moment().startOf("week").format("MM/DD/YY"));
    setEndWeek(moment().endOf("week").format("MM/DD/YY"));
  };

  const computeRangeWeek = async (flag: boolean) => {
    var data: any = {};
    if (flag) {
      setStartWeek(moment(startWeek).add(7, "days").format("MM/DD/YY"));
      setEndWeek(moment(endWeek).add(7, "days").format("MM/DD/YY"));
      data = {
        startDate: moment(startWeek).add(7, "days").format("MM/DD/YY"),
        endDate: moment(endWeek).add(7, "days").format("MM/DD/YY")
      };
    } else {
      setStartWeek(moment(startWeek).add(-7, "days").format("MM/DD/YY"));
      setEndWeek(moment(endWeek).add(-7, "days").format("MM/DD/YY"));
      data = {
        startDate: moment(startWeek).add(-7, "days").format("MM/DD/YY"),
        endDate: moment(endWeek).add(-7, "days").format("MM/DD/YY")
      };
    }

    const currentWeekOrder: any = await axios.post(`${baseURL}/report/getSalesByWeek/`, data, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    if (!currentWeekOrder.data.success) return;
    var series: number[] = new Array(7).fill(0);
    switch (reportsBy) {
      case "Sales":
        currentWeekOrder.data.data.forEach((item: any) => {
          series[
            +moment(new Date(item.DeliveryDate).getTime() - new Date(data.startDate).getTime()) /
            1000 /
            60 /
            60 /
            24
          ] += parseInt(item.TotalCost);
        });
        break;
      case "Orders":
        currentWeekOrder.data.data.forEach((item: any) => {
          series[
            +moment(new Date(item.DeliveryDate).getTime() - new Date(data.startDate).getTime()) /
            1000 /
            60 /
            60 /
            24
          ] += 1;
        });
        break;
      default:
        break;
    }
    // currentWeekOrder.data.data.forEach((item: any) => {
    //   series[+moment(new Date(item.DeliveryDate).getTime() - new Date(data.startDate).getTime())/1000/60/60/24 -1] += item.TotalCost
    // })

    setSeries([
      {
        name: "TotalCost",
        data: series
      }
    ]);
  };

  console.log(reportsBy)

  const changeDate = (flag: boolean) => {
    switch (reportType) {
      case "YEAR":
        yearChange(flag);
        break;
      case "MONTH":
        monthChange(flag);
        break;
      case "WEEK":
        computeRangeWeek(flag);
        break;
      default:
        break;
    }
  };

  const handleChange = (e: any) => {
    setReportsBy(e.target.value);
    switch (reportType) {
      case "10YEAR":
        tenYearReport(e.target.value);
        console.log(e.target.value)
        break
      case "YEAR":
        yearReport(e.target.value);
        break;
      case "MONTH":
        monthReport(e.target.value);
        break;
      case "WEEK":
        weekReport(e.target.value);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <div className="flex text-lg flex-col items-center justify-between w-full font-bold min-[500px]:flex-row mt-2">
        <div className="flex mb-[0px] justify-start max-[500px]:mb-[10px] max-[500px]:w-full">
          <FaChartBar className="w-7 h-7" />
          <h1>Reports:</h1>
          <select
            placeholder="select"
            name="reportsBy"
            value={reportsBy}
            onChange={(e) => handleChange(e)}
            className="-mt-1 text-base border-none w-36">
            <option key="Sales" value="Sales">
              Sales
            </option>
            <option key="Orders" value="Orders">
              Orders
            </option>
            <option key="Receivables" value="Receivables">
              Receivables
            </option>
          </select>
        </div>
        <div className={`flex flex-row space-x-2 ${reportsBy === "Receivables" ? "hidden" : ""}`}>
          <button
            hidden={reportsBy === "Receivables"}
            className="text-xs sm:text-sm btn btn-info w-30 "
            onClick={() => tenYearReport(reportsBy)}
          >
            10 Year
          </button>
          <button
            hidden={reportsBy === "Receivables"}
            className="text-xs sm:text-sm btn btn-info w-30 "
            onClick={() => yearReport(reportsBy)}>
            Year
          </button>
          <button
            hidden={reportsBy === "Receivables"}
            className="text-xs sm:text-sm btn btn-info "
            onClick={() => monthReport(reportsBy)}>
            Month
          </button>
          <button
            hidden={reportsBy === "Receivables"}
            className="text-xs sm:text-sm btn btn-info "
            onClick={() => weekReport(reportsBy)}>
            Week
          </button>
        </div>
      </div>

      {
        reportType !== "10YEAR" ?
          <div className={`flex justify-center w-full  ${reportsBy === "Receivables" ? "hidden" : ""}`}>
            <button
              className="items-center mt-1 mr-3 text-2xl text-teal-500 btn-text"
              onClick={() => changeDate(false)}>
              <FaAngleLeft />
            </button>
            <span
              className="items-center text-3xl text-center min-w-[220px]">
              {reportType === "WEEK" ? startWeek + " - " + endWeek : currentDate}
            </span>
            <button
              className="items-center mt-1 ml-3 text-2xl text-teal-500 btn-text"
              onClick={() => changeDate(true)}>
              <FaAngleRight />
            </button>
          </div> : null
      }

      <div className="h-[77vh] ">
        <div className="flex h-[77vh]">
          <div className="w-full h-full mx-auto" hidden={reportsBy === "Receivables"}>
            <Chart category={category} series={series} mode={reportType} />
          </div>
          <div className="w-full h-full mx-auto" hidden={reportsBy !== "Receivables"}>
            <Receivable
              mainData={completedata}
              total={total}
              // searchKeyword={searchKeyword}
              search={getOrders}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
