import { Fragment, useEffect, useState } from "react";
import "./styles.css";
import { useOrderContext } from "./OrderContextProvider";
import { generateEmptyOrderEntry } from "utils/orders.helper";

interface myprops {
  // maxLength: string;
  value: any;
  customers: Array<Customer>;
  onSelect: (customer: Customer) => void;
  handleChange: (event: any) => void;
}
const Auto: React.FC<myprops> = ({ customers, onSelect, handleChange, value }) => {
  const { setOrderEntry } = useOrderContext();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState<any>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState(value);

  useEffect(() => {
    setUserInput(value);
  }, [value]);


  const onChange = (e: any) => {
    const userInput = e.currentTarget.value;

    setFilteredSuggestions(
      customers.filter(
        (customer) =>
          customer.PhoneNumber.replace(/\s/g, "")
            .toLowerCase()
            .indexOf(userInput.replace(/\s/g, "").toLowerCase()) > -1
      )
    );
    setSelectedIndex(0);
    setShowSuggestions(true);
    setUserInput(e.currentTarget.value.replace(/\s/g, ""));

    handleChange(e);
  };

  const selectCustomer = (customer: Customer) => {
    setSelectedIndex(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(customer.PhoneNumber);
    onSelect(customer);
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      if (filteredSuggestions[selectedIndex]) {
        setUserInput(filteredSuggestions[selectedIndex].PhoneNumber);
      } else {
        if (userInput.length === 10) {
          const entry = generateEmptyOrderEntry();
          entry.customer.PhoneNumber = userInput;
          setOrderEntry(entry);
        }
      }

      setSelectedIndex(0);
      setShowSuggestions(false);

      onSelect(filteredSuggestions[selectedIndex]);
    } else if (e.keyCode === 38) {
      setSelectedIndex(selectedIndex - 1);
    } else if (e.keyCode === 40) {
      if (selectedIndex - 1 === filteredSuggestions.length) {
        return;
      }
      setSelectedIndex(selectedIndex + 1);
    }
  };

  let suggestionsListComponent;
  if (showSuggestions && userInput) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className="suggestions">
          {filteredSuggestions.map((suggestion: Customer, index: number) => {
            return (
              <li
                className={index === selectedIndex ? "suggestion-active" : ""}
                key={`customer-suggestion-list-item-${suggestion.PhoneNumber}`}
                onClick={(e: any) => selectCustomer(suggestion)}>
                {suggestion.PhoneNumber}
              </li>
            );
          })}
        </ul>
      );
    }
  }

  return (
    <Fragment>
      <input
        type="text"
        onChange={(e) => onChange(e)}
        onKeyDown={onKeyDown}
        value={userInput}
        maxLength={10}
        name="PhoneNumber"
        autoComplete="nope"
        placeholder="Phone Number"
        id="CustomerPhoneNumberInput"
        pattern="[0-9]*"
      />
      {suggestionsListComponent}
    </Fragment>
  );
};
export default Auto;
