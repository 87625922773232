import axios from "axios";
import { useLayout } from "providers/LayoutProvider";
import { baseURL } from "config";
import { SEARCH_TIMEOUT } from "config/constants";
import { useState, useCallback, useEffect, useMemo, Fragment, useRef, } from "react";
import { FaInfoCircle, FaPlus, FaSearch, FaSpinner, FaSwatchbook, FaUserTie, FaPhone } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Transition } from '@headlessui/react'
import { toast } from "react-toastify";
// const ws = new WebSocket('ws://localhost:8080/');
// const ws = new WebSocket('ws://dev.matrix.normfred.net:8080');

// interface CallerInfo {
//   Call_ID: string;
//   NameOfCaller: string;
//   PhoneNumber: string;
//   createdAt?: string;
//   updatedAt?: string;
// }

const Search = () => {
  // const [incomingCalls, setIncomingCalls] = useState<CallerInfo[]>([]);
  // const [allCallerHistory, setAllCallerHistory] = useState<CallerInfo[]>([]);
  // const [showCallList, setShowCallList] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { searchFocused, setSearchFocused } = useLayout();

  const [keywords, setKeywords] = useState("");
  const keywordsSearchable = useMemo(() => keywords.length >= 3, [keywords]);

  const [searchingEnabled, setSearchingEnabled] = useState<boolean>(false);

  const searchResultOpen = useMemo<boolean>(
    () => keywordsSearchable && searchFocused,
    [keywordsSearchable, searchFocused]
  );

  const [customersSearching, setCustomersSearching] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Customer[]>([]);

  const [ordersSearching, setOrdersSearching] = useState<boolean>(false);
  const [orders, setOrders] = useState<any[]>([]);

  const searching = useMemo(
    () => customersSearching && ordersSearching,
    [customersSearching, ordersSearching]
  );

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  // get caller info
  // useEffect(() => {
  //   const fetchIncomingCalls = async () => {
  //     try {
  //       const response = await axios.get(`${baseURL}/incomingcall`);
  //       setIncomingCalls(response.data?.callerInfo);
  //     } catch (error) {
  //       console.error('Error fetching incoming calls:', error);
  //     }
  //   };

  //   fetchIncomingCalls();
  // }, []);

  // useEffect(() => {
  //   ws.onopen = () => {
  //     console.log('WebSocket connected');
  //   };

  //   ws.onmessage = event => {
  //     const data = JSON.parse(event.data);
  //     if (data.type === 'incomingCall') {
  //       setIncomingCalls(prevCalls => [...prevCalls, data.callerInfo]);
  //     }
  //   };

  //   return () => {
  //     ws.close();
  //   };
  // }, []);

  // const fetchIncomingCalls = async () => {
  //   try {
  //     const response = await axios.get(`${baseURL}/incomingcall`);
  //     setIncomingCalls(response.data?.callerInfo);
  //     // fetch(`${baseURL}/save-caller-info`, {
  //     //   method: "POST",
  //     //   headers: {
  //     //     "content-type": "application/json"
  //     //   },
  //     //   body: JSON.stringify(response.data?.callerInfo)
  //     // })
  //   } catch (error) {
  //     console.error('Error fetching incoming calls:', error);
  //   }
  // };

  // const handleCustomersCall = () => {
  //   setLoading(true)
  //   fetch(`${baseURL}/get-caller-info`)
  //     .then(res => res.json())
  //     .then(data => {
  //       if (data?.success) {
  //         console.log(data)
  //         setAllCallerHistory(data?.callerInfo)
  //         setLoading(false)
  //       } else {
  //         setLoading(false)
  //         toast.error("There was an error!")
  //       }

  //     })
  // }

  // const handleCustomer = (customerNumber: any) => {
  //   fetch(`http://localhost:4000/api/get-caller-details?number=${customerNumber}`)
  //     .then(res => res.json())
  //     .then(data => {
  //       console.log(data)
  //     })
  // }


  // console.log(incomingCalls)

  const searchCustomers = useCallback(
    async (signal: AbortSignal) => {
      setCustomersSearching(true);

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo?.accessToken}`,
          },
          signal: signal
        };
        const customers = await axios
          .get(`${baseURL}/customer/search?keywords=${keywords}`, config)
          .then(({ data }) => data.data);

        setCustomers(customers);
      } catch (ex: any) {
        ex?.code !== "ERR_CANCELED" && console.log("Error occurred while searching!", ex);
      }

      setCustomersSearching(false);
    },
    [keywords, setCustomers, setCustomersSearching]
  );

  const searchOrders = useCallback(
    async (signal: AbortSignal) => {
      setOrdersSearching(true);

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo?.accessToken}`,
          },
          signal: signal
        };
        const orders = await axios
          .get(`${baseURL}/order/search?keywords=${keywords}`, config)
          .then(({ data }) => data.data);

        setOrders(orders);
      } catch (ex: any) {
        ex?.code !== "ERR_CANCELED" && console.log("Error occurred while searching!", ex);
      }

      setOrdersSearching(false);
    },
    [keywords, setOrders, setOrdersSearching]
  );

  // const clearSearch = useCallback(() => {
  //   setCustomers([]);
  //   setOrders([]);
  // }, [setCustomers, setOrders]);

  useEffect(() => {
    setSearchingEnabled(keywordsSearchable);
    setSearchFocused(true);
  }, [keywords, keywordsSearchable, setSearchFocused, setSearchingEnabled]);

  useEffect(() => {
    if (!searchingEnabled) return;

    let abortController: AbortController | null = null;
    const timeoutHandler = setTimeout(() => {
      abortController = new AbortController();
      searchCustomers(abortController.signal);
      searchOrders(abortController.signal);
    }, SEARCH_TIMEOUT);

    return () => {
      clearTimeout(timeoutHandler);
      abortController && abortController.abort();
    };
  }, [searchingEnabled, searchCustomers, searchOrders]);

  const closeSearchResult = useCallback(() => {
    setSearchFocused(false);
    setSearchingEnabled(false);
  }, [setSearchFocused, setSearchingEnabled]);

  const moveToNewOrder = useCallback(
    (phone: string) => {
      navigate(`/orders/new?phone=${phone}`);
      closeSearchResult();
    },
    [navigate, closeSearchResult]
  );

  const moveToOrder = useCallback(
    (orderId: string) => {
      navigate(`/orders/${orderId}`);
      closeSearchResult();
    },
    [navigate, closeSearchResult]
  );

  useEffect(() => {
    if (!searchResultOpen) return;

    const keydown = (e: any) => {
      switch (e.keyCode) {
        case 13: // Enter
          moveToNewOrder(keywords);
          break;
        // case 27: // ESC
        //   closeSearchResult();
        //   break;
        default:
          break;
      }
    };
    document.addEventListener("keydown", keydown);
    return () => {
      document.removeEventListener("keydown", keydown);
    };
  }, [keywords, searchResultOpen, moveToNewOrder, closeSearchResult]);

  useEffect(() => {
    const blur = () => {
      closeSearchResult();
    };
    document.addEventListener("click", blur);
    return () => {
      document.removeEventListener("click", blur);
    };
  }, [closeSearchResult]);

  return (
    <div className={`grow search-panel-wrapper`}>
      <div
        className={`search-input-result-wrapper ${searchResultOpen ? "search-result-open" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <div className="search-input-wrapper">
          <input
            type="text"
            onChange={(e) => setKeywords(e.target.value)}
            onFocus={() => setSearchFocused(true)}
            onClick={() => setSearchFocused(true)}
            value={keywords}
            className="input-keywords"
            placeholder="Search customers by phone and namex ..."
            maxLength={30}
          />
          <FaSearch
            className={`w-4 h-4 absolute top-3 right-3 text-gray-400 transition ${searching ? "opacity-0" : "opacity-100"
              }`}
          />
          <FaSpinner
            className={`w-4 h-4 absolute top-3 right-3 text-gray-400 transition animate-spin-slow ${searching ? "opacity-100" : "opacity-0"
              }`}
          />
        </div>
        <div className="search-result-wrapper">
          <div className="search-result-inner">
            <ul className="search-result">
              <li>
                <a
                  href="/"
                  className={`flex items-center w-full underline ${!customers.length ? "text-red-500" : ""}`}
                  onClick={(e) => {
                    e.preventDefault();
                    moveToNewOrder(keywords);
                  }}>
                  <FaInfoCircle className="w-4 h-4 mr-2" /> Create a new order with "{keywords}" by clicking here or [Enter]
                </a>
              </li>
              {customers.length ? (
                customers.map((customer, i) => {
                  return (
                    <li key={`search-result-customer-${i}`}>
                      <a
                        href="/"
                        className="flex leading-none items-center gap-2 text-black/75 transition-colors hover:text-black hover:no-underline border-b border-b-gray-300 hover:border-b-gray-500 pb-1"
                        onClick={(e) => {
                          e.preventDefault();
                          moveToNewOrder(customer.PhoneNumber);
                        }}>
                        <div>
                          {/* <span className="bg-blue-400 text-white flex justify-center items-center rounded-lg w-8 h-8 mr-2">
                            C
                          </span> */}
                          <FaUserTie className="w-4 h-4" />
                        </div>
                        <div className="min-w-0">
                          <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                            <span className="">
                              {customer.PhoneNumber}, {customer.CustomerName}
                            </span>
                          </div>
                          <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                            <small className="opacity-75">{customer.BillingAddress}</small>
                          </div>
                        </div>
                      </a>
                    </li>
                  );
                })
              ) : (
                <li className="">
                  <span className="italic">No customers found.</span>
                </li>
              )}

              {orders.length ? (
                orders.map((order, i) => {
                  return (
                    <li key={`search-result-order-${i}`}>
                      <a
                        href="/"
                        className="flex leading-none items-center gap-2 text-black/75 transition-colors hover:text-black hover:no-underline border-b border-b-gray-300 hover:border-b-gray-500 pb-1"
                        onClick={(e) => {
                          e.preventDefault();
                          moveToOrder(order.OrderNumber);
                        }}>
                        <div>
                          {/* <span className="bg-blue-400 text-white flex justify-center items-center rounded-lg w-8 h-8 mr-2">
                            C
                          </span> */}
                          <FaSwatchbook className="w-4 h-4" />
                        </div>
                        <div className="min-w-0">
                          <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                            <span
                              className={`${order.Status === "Cancel"
                                ? "bg-gray-300"
                                : order.Status === "Quote"
                                  ? "bg-gray-500"
                                  : order.Status === "Pending"
                                    ? "bg-yellow-500"
                                    : order.Status === "Delivered"
                                      ? "bg-blue-500"
                                      : order.Status === "Completed"
                                        ? "bg-green-500"
                                        : "bg-gray-500"
                                } text-white px-1 py-[2px] rounded text-xs leading-none inline-block mr-1 w-[74px] text-center`}>
                              {order.Status.toUpperCase()}
                            </span>
                            <span className="mr-1">[<span>{order.OrderNumber}</span>]</span>
                            <span>
                              {order.PhoneNumber}, {order.CustomerName}
                            </span>
                          </div>
                          <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                            <small className="opacity-75">{order.BillingAddress}</small>
                          </div>
                        </div>
                      </a>
                    </li>
                  );
                })
              ) : (
                <li>
                  <span className="italic">No orders found.</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>

      <Link
        to="/orders/new"
        className="btn btn-danger rounded-full ml-2 p-0 w-[37px] h-[37px]"
        title="Create New Order">
        <FaPlus className="w-4 h-4" />
      </Link>
      {/* {incomingCalls.length > 0 && <button className="ml-2"><img src="/assets/phone-ringing-icon.gif" alt="" className="w-12 h-12 -mt-3" /></button>} */}
      {/* .......... */}
      {/* <div className="-mt-1 text-right">
        <Menu as="div" className="relative text-left">
          <div>
            <Menu.Button className="justify-center rounded-md text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
              {incomingCalls.length > 0 && <button className="ml-2" onClick={handleCustomersCall}><img src="/assets/phone-ringing-icon.gif" alt="" className="w-12 h-12 -mt-3" /></button>}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-[300px] flex justify-center origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <div className="py-1 ">
                {
                  // eslint-disable-next-line array-callback-return
                  allCallerHistory.map((callerData) => {
                    return <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active ? 'bg-violet-500 text-white' : 'text-gray-900'
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm flex gap-2`}
                          onClick={() => handleCustomer(callerData?.PhoneNumber.slice(6, 16))}
                        >
                          <p>{callerData?.NameOfCaller}</p>
                          <p>{callerData?.PhoneNumber.slice(5, 16)}</p>
                          <FaPhone className="w-4 h-4" />
                        </button>
                      )}
                    </Menu.Item>
                  })
                }

              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div> */}
    </div>
  );
};

export default Search;
