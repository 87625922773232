
interface Actives {
  item: string;
  value: string;

}

const Item = () => {
  // const onChange = (string:val) => {
  //   return val;
  // };

  const actives: Actives[] = [
    { item: "10", value: "325" },
    { item: "15", value: "350" },
    { item: "20", value: "425" },
    { item: "30", value: "475" },
    { item: "40", value: "525" },
    { item: "45", value: "550" },
    { item: "50", value: "575" },
    { item: "60", value: "625" },
    { item: "70", value: "675" },
    { item: "75", value: "700" },
    { item: "80", value: "725" },
    { item: "85", value: "750" },
    { item: "90", value: "775" },
    { item: "100", value: "825" },

  ];

  return (
    <div className='flex justify-between '>
      <div className="relative w-full ">
        <div>
          <table className='w-full'>
            <thead>
              <tr>
                <th className='text-center'>Dumpster Description</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {actives.map(({ item, value }, i) => (
                <tr key={i} className='text-center '>
                  <td className='text-center'>{item}</td>
                  <td className="text-center">{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Item;
