import { FaEdit } from "react-icons/fa";
import axios from "axios";
import { baseURL } from "config";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../context";

const Administration = () => {
  const { state, dispatch } = useGlobalContext();
  const [user, setUser] = useState<user_interface[]>([]);
  const navigate = useNavigate();

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  const getUser = () => {
    axios
      .get(`${baseURL}/user`, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })
      .then((response) => {
        setUser(response.data.data);
      })
      .catch((err) => {
        console.log(err.err);
      });
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  const handleEdit = async (item: any) => {
    dispatch({
      type: "TOGGLEUSER",
      ...state,
      payload: {
        user: {
          ID: item.ID,
          isDeleted: item.isDeleted,
          Name: item.Name,
          Email: item.Email,
          Password: item.Password,
          AccessType: item.AccessType,
          Picture: item.Picture
        }
      }
    });

    navigate("/usermanage");
  };

  return (
    <div className="flex w-full">
      <div className="w-full space-y-1">
        <div>
          <table className="flex flex-row flex-no-wrap w-full overflow-hidden text-center xl:bg-white">
            <thead className="text-center text-white h-[75px]">
              {user.map((item, index) => {
                return (
                  <tr className="flex flex-col mb-2 bg-teal-400 rounded-l-lg flex-no wrap xl:table-row xl:rounded-none xl:mb-0">
                    <th className="p-3 text-center h-[75px] ">Picture</th>
                    <th className="p-3 text-center h-[75px]">LoginID</th>
                    <th className="p-3 text-center h-[75px]">Name</th>
                    <th className="p-3 text-center h-[75px]">Status</th>
                    <th className="p-3 text-center h-[75px]">Action</th>
                  </tr>
                );
              })}
            </thead>
            <tbody className="flex-1 xl:flex-none">
              {user.map((item, i) => (
                <tr key={i} className="flex flex-col mb-2 flex-no wrap xl:table-row xl:mb-0">
                  <td className="p-3 border border-grey-light hover:bg-gray-100 h-[75px] ">
                    <img
                      className="inline-flex w-10 h-12 rounded-full"
                      alt="Name"
                      src={item["Picture"]}
                    />
                  </td>
                  <td className="p-3 border border-grey-light hover:bg-gray-100 h-[75px] text-lg">
                    {item["Email"].split("@")[0]}
                    <br />@{item["Email"].split("@")[1]}
                  </td>
                  <td className="p-3 border border-grey-light hover:bg-gray-100 h-[75px] text-2xl">
                    {item["Name"]}
                  </td>
                  <td className="p-3 border border-grey-light hover:bg-gray-100 h-[75px] text-2xl">
                    {item["isDeleted"] ? (
                      <div className="text-red-400">Suspended</div>
                    ) : (
                      <p className="text-blue-400 ">Active</p>
                    )}
                  </td>
                  <td className="p-3 border border-grey-light hover:bg-gray-100 h-[75px] text-2xl">
                    <button
                      className="inline-flex hover:shadow-none"
                      onClick={() => handleEdit(item)}>
                      <FaEdit className="w-6 h-6 text-blue-500 hover:text-blue-700 btn btn-text hover:shadow-none" />
                    </button>
                    {/* {item["Name"]} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Administration;
