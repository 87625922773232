const reducer: ReducerType = (state, action) => {
  switch (action.type) {

    case "TOGGLEPAYMENTMODAL":
      return {
        ...state,
        paymentmodal: action.payload.value
      };
    case "TOGGLEUSER":
      return {
        ...state,
        user: action.payload.user
      };
    case "TOGGLEMENU":
      return {
        ...state,
        menu: action.payload.value
      };
    case "UPDATE_PRODUCTLIST":
      return {
        ...state,
        product_list: action.payload.product_list
      };
    default:
      return state;
  }
};

export default reducer;
