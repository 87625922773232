import { useEffect, useState } from "react";

const useScroll = (threshold: number): boolean => {
  const [scrolling, setScrolling] = useState<boolean>(false);
  useEffect(() => {
    function onScroll() {
      setScrolling(window.scrollY > threshold);
    }

    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", onScroll);

    onScroll();

    return function () {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", onScroll);
    };
    // eslint-disable-next-line
  });

  return scrolling;
};

export default useScroll;