import { ReactElement, useCallback, useMemo, useState } from "react";
import {
  FaCalendar,
  FaChartBar,
  FaChevronDown,
  FaChevronLeft,
  FaCog,
  FaCogs,
  FaFileExport,
  FaListUl,
  FaSlidersH,
  FaSwatchbook,
  FaUsers
} from "react-icons/fa";
import { BiLogOutCircle } from "react-icons/bi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAccount } from "providers/AccountProvider";
import { Role } from "config/types";
import { useAuth } from "providers/AuthProvider";
import { useLayout } from "providers/LayoutProvider";
import { ENVIRONMENT, VERSION } from "config/constants";

interface MenuItem {
  title: string;
  icon: ReactElement;
  path: string;
  permissions?: string[];
  children?: MenuItem[];
}

const MENU_ITEMS: MenuItem[] = [
  {
    title: "Order Entry",
    icon: <FaSwatchbook className="w-6 h-6" />,
    path: "/orders/new",
    permissions: []
  },
  {
    title: "Active Orders",
    icon: <FaListUl className="w-6 h-6" />,
    path: "/orders/active",
    permissions: ["Delivery Driver"]
  },
  {
    title: "Calendar",
    icon: <FaCalendar className="w-6 h-6" />,
    path: "/calendar",
    permissions: []
  },
  {
    title: "Reports ",
    icon: <FaChartBar className="w-6 h-6" />,
    path: "/reports",
    permissions: ["Order Taker", "Delivery Driver"]
  },
  {
    title: "Settings",
    icon: <FaCog className="w-6 h-6" />,
    path: "/settings",
    permissions: [],
    children: [
      {
        title: "Order Entry",
        icon: <FaSlidersH className="w-6 h-6" />,
        path: "/settings/order-entry",
        permissions: []
      },
      {
        title: "Data",
        icon: <FaFileExport className="w-6 h-6" />,
        path: "/settings/data",
        permissions: []
      },
      {
        title: "System",
        icon: <FaCogs className="w-6 h-6" />,
        path: "/settings/system",
        permissions: []
      },
      {
        title: "Users",
        icon: <FaUsers className="w-6 h-6" />,
        path: "/users",
        permissions: ["Order Taker", "Delivery Driver"]
      }
    ]
  }
];

const Sidebar = () => {
  const location = useLocation();

  const currentPath = useMemo(() => location.pathname, [location]);

  const [expandedMenuItemPaths, setExpandedMenuItemPaths] = useState<string[]>(() => {
    let paths: string[] = currentPath.split("/");

    if (paths[0] === "") paths.shift();
    if (paths.length && paths[paths.length - 1] === "") paths.pop();

    if (paths.length < 2) return [];
    paths.pop();

    paths[0] = `/${paths[0]}`;
    for (let i = 1; i < paths.length - 1; i++) {
      paths[i] = `${paths[i - 1]}/${paths[i]}`;
    }

    return paths;
  });

  const handleExpandableMenuItemClick = useCallback(
    (menuItemPath: string) => {
      setExpandedMenuItemPaths((menuItemPaths) =>
        menuItemPaths.indexOf(menuItemPath) > -1
          ? menuItemPaths.filter((mip) => mip !== menuItemPath)
          : [...menuItemPaths, menuItemPath]
      );
    },
    [setExpandedMenuItemPaths]
  );

  const isMenuItemExpanded = useCallback(
    (menuItemPath: string) => expandedMenuItemPaths.indexOf(menuItemPath) > -1,
    [expandedMenuItemPaths]
  );

  const { account } = useAccount();

  const getAuthorizedMenuItems = useCallback(
    (menuItems: MenuItem[]) => {
      if (account.AccessType === Role.ADMINISTRATOR) return menuItems;

      const authorizedMenuItems: MenuItem[] = [];
      menuItems.forEach((menuItem) => {
        if (menuItem.permissions!.indexOf(account.AccessType) < 0) return;

        authorizedMenuItems.push({
          ...menuItem,
          children: menuItem.children && getAuthorizedMenuItems(menuItem.children)
        });
      });

      return authorizedMenuItems;
    },
    [account]
  );

  const authorizedMenuItems: MenuItem[] = useMemo(
    () => getAuthorizedMenuItems(MENU_ITEMS),
    [getAuthorizedMenuItems]
  );

  const { clearToken } = useAuth();
  const navigate = useNavigate();

  const logout = useCallback(() => {
    clearToken();
    navigate("/login");
  }, [clearToken, navigate]);

  const { toggleMobileMenuVisible, sidebarMinimized, toggleSidebarMinimized, settings } =
    useLayout();

  return (
    <div className={`sidebar ${sidebarMinimized ? "minimized" : ""}`}>
      <div className="top-section">
        <div className="logo-wrapper">
          {settings.companyCoverLogo && (
            <img alt="Company Logo" className="logo" src={settings.companyCoverLogo} />
          )}
          {settings.companySquareLogo && (
            <img alt="Company Logo" className="logo-small" src={settings.companySquareLogo} />
          )}
        </div>
      </div>
      <div className="navigation">
        <ul className={`menu-item-list`}>
          <MenuItemElements
            menuItems={authorizedMenuItems}
            currentPath={currentPath}
            isMenuItemExpanded={isMenuItemExpanded}
            handleExpandableMenuItemClick={handleExpandableMenuItemClick}
            minimized={sidebarMinimized}
          />
          {/* <li key={`menu-item-logout`} className={`menu-item`}>
            <a
              href="#"
              className={`menu-item-inner`}
              onClick={(e) => {
                e.preventDefault();
                logout();
              }}>
              <MenuItemInnerElement
                menuItem={{
                  title: "Log Out",
                  icon: <FaSignOutAlt className="w-6 h-6" />,
                  path: "/logout"
                }}
              />
            </a>
          </li> */}
        </ul>
      </div>
      <div className="user-info">
        <p className="user-name">{account.Name}</p>
        <img
          alt={`${account.Name ? `${account.Name}'s ` : ""}Avatar`}
          className="avatar"
          src={account.Picture}
        />
        <a
          href="/"
          className={`btn-logout`}
          onClick={(e) => {
            e.preventDefault();
            logout();
          }}>
          <span className="">
            <BiLogOutCircle className="w-6 h-6" />
          </span>
          <span className="logout-title">Log Out</span>
        </a>
      </div>
      <div className="sidebar-width-toggle-wrapper">
        <div className="sidebar-width-toggle-inner">
          <span
            className={`flex lg:hidden sidebar-width-toggle ${
              sidebarMinimized ? "rotate-180" : ""
            }`}
            onClick={toggleMobileMenuVisible}>
            <FaChevronLeft className="w-6 h-6" />
          </span>
          <span
            className={`hidden lg:flex sidebar-width-toggle ${
              sidebarMinimized ? "rotate-180" : ""
            }`}
            onClick={toggleSidebarMinimized}>
            <FaChevronLeft className="w-6 h-6" />
          </span>
        </div>
      </div>
      <div className="text-center p-2 lg:p-4">
        v{VERSION}{" "}
        {/* <span
          className={`${
            ENVIRONMENT === "LIVE" ? "bg-green-500" : "bg-red-500"
          } rounded text-white px-2 py-[1px] font-normal text-xs`}>
          {ENVIRONMENT}
        </span> */}
      </div>
    </div>
  );
};

const MenuItemInnerElement = ({ menuItem }: { menuItem: MenuItem }) => (
  <>
    <span className="p-2">{menuItem.icon}</span>
    <span className="menu-item-title">{menuItem.title}</span>
  </>
);

const MenuItemElements = ({
  menuItems,
  currentPath,
  isMenuItemExpanded,
  handleExpandableMenuItemClick,
  minimized,
  depth = 1
}: {
  menuItems: MenuItem[];
  currentPath: string;
  isMenuItemExpanded: Function;
  handleExpandableMenuItemClick: Function;
  minimized: boolean;
  depth?: number;
}) => {
  const { toggleMobileMenuVisible} = useLayout();
  return (
    <>
      {menuItems.map((menuItem: MenuItem, index) => {
        const selected =
          menuItem.path === currentPath ||
          (menuItem.path === "/orders/new" &&
            currentPath.startsWith("/orders/") &&
            currentPath !== "/orders/active");

        return (
          <li
            key={`menu-item-${menuItem.path.replace(/\//, "")}`}
            className={`menu-item ${menuItem.children ? "menu-item-has-children" : ""} ${
              selected ? "active" : ""
            } ${isMenuItemExpanded(menuItem.path) ? "expanded" : ""}`}>
            {!menuItem.children ? (
              <Link to={menuItem.path} className={`menu-item-inner`} onClick={toggleMobileMenuVisible}>
                <MenuItemInnerElement menuItem={menuItem} />
              </Link>
            ) : (
              <>
                <a
                  href="/"
                  className={`menu-item-inner`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleExpandableMenuItemClick(menuItem.path);
                  }}>
                  <MenuItemInnerElement menuItem={menuItem} />
                  <span className="menu-item-toggle-children">
                    <FaChevronDown className="w-2 h-2" />
                  </span>
                </a>
                <ul
                  className={`menu-item-list sub-menu-item-list sub-menu-item-list-${depth}`}
                  style={{ marginLeft: (!minimized ? 16 : 0) * depth + "px" }}>
                  <MenuItemElements
                    menuItems={menuItem.children}
                    currentPath={currentPath}
                    isMenuItemExpanded={isMenuItemExpanded}
                    handleExpandableMenuItemClick={handleExpandableMenuItemClick}
                    minimized={minimized}
                    depth={depth + 1}
                  />
                </ul>
              </>
            )}
          </li>
        );
      })}
    </>
  );
};

export default Sidebar;
