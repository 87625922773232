import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { baseURL } from "config";
import { useAuth } from "providers/AuthProvider";

const Login = () => {
  const [email, SetEmail] = useState("");
  const [password, SetPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getSystemItem();
  }, []);

  const [systemInputValues, setSystemInputValues] = useState({
    companyAddress: "",
    companyCoverLogo: "",
    companySquareLogo: ""
  });

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  const getSystemItem = async () => {
    try {
      const response = await axios.get(`${baseURL}/setting/system`, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      });
      if (response.data.success) {
        setSystemInputValues(response.data.data[0]);
      }
    } catch (error) {
      console.log("getSystemItem Error::", error);
    }
  };

  const { storeToken } = useAuth();

  const signin = () => {
    axios
      .post(`${baseURL}/login`, {
        email: email,
        password: password
      }, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })
      .then((response) => {
        if (response.data.data.isDeleted) {
          toast.error("You are suspended to login. Please contact admin", {
            autoClose: 1000,
            hideProgressBar: true
          });
          return;
        }
        if (response.data.success) {
          storeToken(response.data.data);
          navigate("/");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") signin()
  };

  const backgroundStyle = {
    backgroundImage: 'url("https://nfdmatrix.us-ord-1.linodeobjects.com/image/3HeL.gif")',
    backgroundSize: 'cover', // or 'contain', depending on your needs
    backgroundPosition: 'center center',
    /* You can also add more styling such as backgroundRepeat, backgroundColor, etc. */
  };

  // console.log(systemInputValues.companyCoverLogo)

  return (
    <div className="flex w-full justify-center items-center h-screen " style={backgroundStyle} >
      <div className="w-full h-screen xl:w-1/2">
        <div className="flex justify-center mt-32">
          <img src={systemInputValues.companyCoverLogo} alt="logo" className="w-4/5 mb-4" />
        </div>
        <div className="flex flex-col justify-center w-2/3 mx-auto text-white h-1/3 xl:w-1/2">
          <div className="mt-10">
            {/* <form onSubmit={() => Signin}> */}

            <div>
              <label className="mb-2.5 block font-extrabold" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                onKeyDown={handleKeyDown}
                onChange={(e) => SetEmail(e.target.value)}
                className="inline-block w-full rounded-full bg-white p-2.5 leading-none text-black placeholder-indigo-900 shadow placeholder:opacity-30"
                placeholder="mail@user.com"
              />
            </div>
            <div className="mt-4">
              <label className="mb-2.5 block font-extrabold" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                id="password"
                onKeyDown={handleKeyDown}
                onChange={(e) => SetPassword(e.target.value)}
                className="inline-block w-full rounded-full bg-white p-2.5 leading-none text-black placeholder-indigo-900 shadow"
              />
            </div>
            <div className="flex flex-col justify-between w-full mt-4 sm:flex-row">
              <div>
                <input type="checkbox" id="remember" />
                <label htmlFor="remember" className="mx-2 text-sm">
                  Remember me
                </label>
              </div>
              <div>
                {/* <a href="#" className="text-sm hover:text-gray-200">Forgot password</a> */}
              </div>
            </div>
            <div className="my-10">
              <button
                onClick={signin}
                className="flex justify-center w-full p-5 bg-orange-600 rounded-full hover:bg-orange-800">
                Login
              </button>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
      {/* <div className="hidden lg:w-1/2 lg:block">
        <img
          src="https://images.unsplash.com/photo-1577495508048-b635879837f1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=675&q=80"
          alt="login"
          className="w-full h-full"></img>
      </div> */}
    </div>
  );
};

export default Login;
