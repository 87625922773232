import { EMPTY_NOTIFICATION_VALUES } from "config/constants";
import { NotificationValues } from "config/types";
import React, { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import axios from "utils/axios.helper";

interface NotificationValuesContext {
  values: NotificationValues;
  refresh: () => void;
}
// eslint-disable-next-line
const NotificationValuesContext: React.Context<NotificationValuesContext> =
  // eslint-disable-next-line
  React.createContext<NotificationValuesContext>({
    values: EMPTY_NOTIFICATION_VALUES,
    refresh: () => { }
  });

interface NotificationValuesProvider {
  children: ReactNode;
}
// eslint-disable-next-line
export function NotificationValuesProvider({ children }: NotificationValuesProvider) {
  const [values, setValues] = useState<NotificationValues>(EMPTY_NOTIFICATION_VALUES);

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  const loadPendingOrderCount = useCallback(async (): Promise<number> => {
    try {
      const response = await axios.get(`/orders/getPending`, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })
        .then(({ data }) => data);
      return response.data;
    } catch (ex) {
      console.log(ex);
    }
    return 0;
  }, []);

  const loadDeliveredOrderCount = useCallback(async (): Promise<number> => {
    try {
      const response = await axios.get(`/orders/getDelivered`, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })
        .then(({ data }) => data);
      return response.data;
    } catch (ex) {
      console.log(ex);
    }
    return 0;
  }, []);

  const loadCompletedOrderCount = useCallback(async (): Promise<number> => {
    try {
      const response = await axios.get(`/orders/getCompleted`, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      })
        .then(({ data }) => data);
      return response.data;
    } catch (ex) {
      console.log(ex);
    }
    return 0;
  }, []);

  const load = useCallback(async () => {
    const [pendingOrderCount, deliveredOrderCount, completedOrderCount] = await Promise.all([
      loadPendingOrderCount(),
      loadDeliveredOrderCount(),
      loadCompletedOrderCount()
    ]);
    setValues({ pendingOrderCount, deliveredOrderCount, completedOrderCount });
  }, [loadPendingOrderCount, loadDeliveredOrderCount, loadCompletedOrderCount]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <NotificationValuesContext.Provider
      value={{
        values,
        refresh: load
      }}>
      {children}
    </NotificationValuesContext.Provider>
  );
}

export const useNotificationValues = (): NotificationValuesContext => {
  const context = useContext<NotificationValuesContext>(NotificationValuesContext);
  return context;
};
