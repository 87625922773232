import { EventInput } from '@fullcalendar/core'

let eventGuid = 0

export const INITIAL_EVENTS: EventInput[] = [
  {
    id: createEventId(),
    title: 'PAID-CITY-STREET-ORDER-NUMBER',
    start: '2023-03-06',
    end:"2023-03-09",
  },
  {
    id: createEventId(),
    title: 'PAID-CITY-STREET-ORDER-NUMBER',
    start: '2023-03-10',
    end:"2023-03-12"
  },
  {
    id: createEventId(),
    title: 'PAID-CITY-STREET-ORDER-NUMBER',
    start: '2023-03-07',
    end:"2023-03-12"
  },
  {
    id: createEventId(),
    title: 'PAID-CITY-STREET-ORDER-NUMBER',
    start: '2023-03-10',
    end:"2023-03-12",
  },
  {
    id: createEventId(),
    title: 'PAID-CITY-STREET-ORDER-NUMBER',
    start: '2023-03-07',
    end:"2023-03-12"
  },

  {
    id: createEventId(),
    title: '$ 375-CITY-STREET-ORDER-NUMBER',
    start: '2023-03-14',
    end:"2023-03-18"
  },
  {
    id: createEventId(),
    title: '$ 375-CITY-STREET-ORDER-NUMBER',
    start: '2023-03-14',
    end:"2023-03-18",
  },
  {
    id: createEventId(),
    title: 'PAID-CITY-STREET-ORDER-NUMBER',
    start: '2023-03-19',
    end:"2023-03-22",
  },
  {
    id: createEventId(),
    title: 'PAID-CITY-STREET-ORDER-NUMBER',
    start: '2023-03-19',
    end:"2023-03-26",
  },
  {
    id: createEventId(),
    title: '$375-CITY-STREET-ORDER-NUMBER',
    start: '2023-03-19',
    end:"2023-03-24",
  },

]

export function createEventId() {
  return String(eventGuid++)
}