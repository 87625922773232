import { Dialog, Transition } from '@headlessui/react'
import { FaClosedCaptioning, FaWindowClose } from "react-icons/fa";
import axios from 'axios';
import { useOrderContext } from 'components/OrderEntry/components/OrderContextProvider';
import { baseURL } from 'config';
import { useGlobalContext } from 'context';
import { Fragment, useCallback, useEffect, useState } from 'react'
import { toast } from "react-toastify";
import { useLayout } from 'providers/LayoutProvider';
import moment from 'moment';

export default function BillModal({ closeModal, openModal, isOpen, setIsOpen, updateKey, setUpdateKey, postOrderHistory }) {
    console.log(updateKey)
    const [ProductData, setProductData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [orderDetailData, setOrderDetailData] = useState([]);
    const { state, dispatch } = useGlobalContext();
    const { orderEntry, payments } = useOrderContext();
    const { settings } =
        useLayout();
    const { order_details, summary, customer, delivery_contact } = orderEntry || {};
    const { EmailAddress, CustomerName, PhoneNumber, Password, BillingAddress } = customer || {};
    const { DeliveryAddress, DeliveryContact, DeliveryContactPhone } = delivery_contact || {}
    const phoneNumberLength = PhoneNumber.length
    const deliveryPhoneNumberLength = DeliveryContactPhone && DeliveryContactPhone?.length
    const processCustomerPhoneNumber = `${PhoneNumber.slice(0, 3)}-${PhoneNumber.slice(3, 6)}-${PhoneNumber.slice(6, phoneNumberLength)}`
    const processDeliveryPhoneNumber = DeliveryContactPhone && `${DeliveryContactPhone.slice(0, 3)}-${DeliveryContactPhone.slice(3, 6)}-${DeliveryContactPhone.slice(6, deliveryPhoneNumberLength)}`
    const userInfoString = localStorage.getItem("USER");
    let userInfo;
    if (userInfoString !== null) {
        userInfo = JSON.parse(userInfoString);
    }
    const getOrderSetting = async () => {
        const settingData = await axios.get(`${baseURL}/setting/order-entry`, {
            headers: {
                Authorization: `Bearer ${userInfo?.accessToken}`,
            },
        });
        if (!settingData.data.success) return;
        setOrderDetailData(
            settingData.data.data.filter(
                (item) => item["TicketDescription"] === "OrderDetail"
            )
        );
    };
    // const postOrderHistory = async (action) => {

    //     fetch(`${baseURL}/orderHistory`, {
    //         method: "POST",
    //         headers: {
    //             Authorization: `Bearer ${userInfo?.accessToken}`,
    //             "content-type": "application/json"
    //         },
    //         body: JSON.stringify({
    //             OrderNumber: orderEntry?.OrderNumber,
    //             ModifiedType: action,
    //             ModifiedBy: userInfo?.Name
    //         })

    //     })
    //         .then(res => res.json())
    //         .then(data => setUpdateKey(updateKey.updateKey + 1))
    // };

    useEffect(() => {
        getOrderSetting();
        // eslint-disable-next-line
    }, [orderEntry?.order_details?.AdditionalDayCost, orderEntry?.order_details?.ExtraMilesCost]);
    useEffect(() => {
        setLoading(true)
        fetch(`${baseURL}/setting/order-entry`, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${userInfo.accessToken}`
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data?.data) {
                    setProductData(data?.data)
                    setLoading(false)
                } else {
                    setLoading(false)
                }

            })

    }, [userInfo.accessToken])

    if (loading) {
        return <p>loading...</p>
    }

    // Function to encrypt a number into a string
    const encryptToString = (number) => {
        const encryptedString = number.toString(16).split('').map(char => {
            return String.fromCharCode(char.charCodeAt(0) + 97); // Convert each digit to a character code and offset by 97 (ASCII 'a')
        }).join('');
        return btoa(encryptedString); // Encode the string to Base64 before returning
    }



    const productName = ProductData.find((item) => item.ID == order_details?.DumpSterSize);

    const { LaborMisc, MilesOneWay, NumberOfDays, Shingles, Concrete, DeliveryDate, PickupDate } = order_details || {};
    const { ProductCost, ConcreteCost, BalanceCost, DaysCost, LaborMiscCost, ShinglesCost, MileageCost, TrailerCost, TotalCost } = summary || {};


    const encryptOrderNumber = encryptToString(orderEntry?.OrderNumber)

    const orderData = {
        LaborMisc, MilesOneWay, NumberOfDays, Shingles, Concrete, DeliveryDate, PickupDate, ProductCost, ConcreteCost, BalanceCost, DaysCost, LaborMiscCost, ShinglesCost, MileageCost, productName, orderEntry, EmailAddress, Password, CustomerName, encryptOrderNumber, PhoneNumber
    }
    const smsData = {
        CustomerName,
        PhoneNumber,
        Password,
        orderEntry
    }
    function formatNumberWithOptionalDecimals(number) {
        // Check if the number has cents
        if (Number.isInteger(number)) {
            // If the number is an integer, return it as is
            return number.toString();
        } else {
            // If the number has cents, format it with two decimal places
            return number;
        }
    }
    const additionalDaysCostx = formatNumberWithOptionalDecimals(orderEntry?.summary?.DaysCost)
    const additionalMilageCost = formatNumberWithOptionalDecimals(orderEntry?.summary?.MileageCost)
    const handleMailSend = () => {
        fetch(`${baseURL}/send-mail`, {
            method: 'POST', // Assuming you're sending data using POST method
            headers: {
                'Authorization': `Bearer ${userInfo.accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(orderData)
        })
            .then(res => res.json())
            .then(data => {
                if (data?.success) {
                    setIsOpen(false)
                    toast.success("Email sent successfully !")
                } else {
                    setIsOpen(false)
                    toast.error("There was an error !")
                }
            })
    }
    const handleSmsSend = () => {
        fetch(`${baseURL}/send-sms`, {
            method: 'POST', // Assuming you're sending data using POST method
            headers: {
                'Authorization': `Bearer ${userInfo.accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(smsData)
        })
            .then(res => res.json())
            .then(data => {
                if (data?.success) {
                    setIsOpen(false)
                    toast.success("SMS sent successfully !")
                } else {
                    setIsOpen(false)
                    toast.error("There was an error !")
                }
            })
    }


    // Function to decrypt a string back to a number
    const decryptFromString = (encryptedString) => {
        const decryptedNumber = parseInt(encryptedString.split('').map(char => {
            return String.fromCharCode(char.charCodeAt(0) - 97);
        }).join(''), 16);
        return decryptedNumber;
    }

    // Encrypt the number
    const number = 19178;
    const encryptedString = encryptToString(number);

    // Decrypt the string
    const decryptedNumber = decryptFromString(encryptedString);

    //twest
    function calculateDaysDifference(pickupDateStr, deliveryDateStr) {
        const formats = ['MM/DD/YYYY', 'YYYY-MM-DD']; // List of accepted date formats

        let pickupDate, deliveryDate;

        // Attempt to parse pickup date and delivery date in different formats
        for (const format of formats) {
            pickupDate = moment(pickupDateStr, format, true); // Parse without strict mode
            if (pickupDate.isValid()) break; // If parsing succeeds, break the loop
        }

        for (const format of formats) {
            deliveryDate = moment(deliveryDateStr, format, true); // Parse without strict mode
            if (deliveryDate.isValid()) break; // If parsing succeeds, break the loop
        }

        // Calculate the difference in days
        let daysDifference;
        if (deliveryDate) {
            daysDifference = deliveryDate.diff(pickupDate, 'days')
        }

        return daysDifference;
    }


    const differDays = calculateDaysDifference(DeliveryDate, PickupDate);


    let additionalDayCalculation;
    if (differDays) {
        additionalDayCalculation = differDays > 7 ? differDays - 7 : 0
    }

    let fixedDayCost = 20;
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10 " onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-2 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-lg:mx-6 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/2 my-14 sm:my-14 transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        <div>
                                            <div className='flex justify-between md:justify-end'>
                                                <div className='flex gap-1'>
                                                    <button
                                                        className="btn lg:w-40 btn-info rounded-full"
                                                        onClick={() => {
                                                            handleMailSend()
                                                            postOrderHistory("email")
                                                        }}
                                                    >
                                                        EMAIL
                                                    </button>
                                                    <button
                                                        className="btn lg:w-40 btn-info rounded-full"
                                                        onClick={() => {
                                                            handleSmsSend();
                                                            postOrderHistory("sms")
                                                        }}
                                                    >
                                                        SMS
                                                    </button>

                                                </div>
                                                <img src="/assets/211652_close_icon.png" alt="" className='w-10 h-10 md:hidden' onClick={() => setIsOpen(false)} />
                                            </div>
                                            <div className='mt-10'>
                                                <img src={settings?.companyCoverLogo} alt="" className='h-10' />
                                                <div className='flex flex-col md:flex-row xl:gap-36 mt-10'>
                                                    <div>
                                                        <p className='font-bold'>Norman Frederick Dumpsters, LLC</p>
                                                        <p>1450 S 25th Street</p>
                                                        <p>Saginaw, MI 48601</p>
                                                        <p>989-272-3441</p>
                                                    </div>
                                                    <div className='md:flex-1 flex-col md:flex-row'>
                                                        <div className='flex justify-end text-right'>
                                                            <div className='w-56'>
                                                                <p className='font-bold'>{CustomerName}</p>
                                                                <p>{BillingAddress}</p>
                                                                <p>{processCustomerPhoneNumber}</p>

                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div className=' flex justify-end border-b-2 border-dashed border-[#3B82F6] mb-1 text-2xl'>
                                                            <p className='mr-2 mb-1'>Invoice {orderEntry?.OrderNumber}</p>
                                                        </div>
                                                        <div className=' flex justify-end border-b-2 border-dashed border-[#3B82F6] mb-1'>
                                                            <p className='mr-2 mb-1'><span className='font-bold'>Order Date</span> {orderEntry?.order_info?.CreatedOn.slice(0, 8)}</p>
                                                        </div>
                                                        <div className=' flex justify-end border-b-2 border-dashed border-[#3B82F6] mb-1'>
                                                            <p className='mr-2 mb-1'><span className='font-bold'>Delivery Date</span> {DeliveryDate}</p>
                                                        </div>
                                                        <div className=' flex justify-end border-b-2 border-dashed border-[#3B82F6]'>
                                                            <p className='mr-2 mb-1'><span className='font-bold'>Pickup Date</span> {PickupDate}</p>
                                                        </div>
                                                        {
                                                            BillingAddress != DeliveryAddress ? <>
                                                                <div className=' flex text-sm text-end justify-end border-b-2 border-dashed border-[#3B82F6]'>
                                                                    <p className='mr-2 mb-1'><span className='font-bold'>Delivery Address: </span>{DeliveryAddress}</p>
                                                                </div> </> : null
                                                        }
                                                        {
                                                            (DeliveryContactPhone != PhoneNumber || DeliveryContact != CustomerName) ?
                                                                <div className=' text-sm flex text-end justify-end border-b-2 border-dashed border-[#3B82F6]'>
                                                                    <p className='mr-2 mb-1'><span className='font-bold'>Delivery Contact: </span>{processDeliveryPhoneNumber} {DeliveryContact}</p>
                                                                </div> : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="w-full mt-10 overflow-x-auto">
                                                    <table className="w-full text-end">
                                                        <thead>
                                                            <tr>
                                                                <th className="table-th text-center">QTY</th>
                                                                <th className="table-th text-start">Description</th>
                                                                <th className="table-th">Price</th>
                                                                <th className="table-th">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ProductCost != 0 &&
                                                                <tr>
                                                                    <td className="table-td text-start">1</td>
                                                                    <td className="table-td text-start">{productName?.Description}</td>
                                                                    <td className="table-td text-end">${ProductCost.toFixed(2)}</td>
                                                                    <td className="table-td text-end">${ProductCost.toFixed(2)}</td>
                                                                </tr>
                                                            }
                                                            {additionalDayCalculation &&
                                                                <tr>
                                                                    <td className="table-td text-start">{additionalDayCalculation}</td>
                                                                    <td className="table-td text-start">ADDITIONAL DAYS</td>
                                                                    <td className="table-td text-end">${orderEntry?.order_details?.AdditionalDayCost ? orderEntry?.order_details?.AdditionalDayCost.toFixed(2) : fixedDayCost.toFixed(2)}</td>
                                                                    <td className="table-td text-end">${additionalDaysCostx}</td>
                                                                </tr>
                                                            }
                                                            {MilesOneWay > 25 &&
                                                                <tr>
                                                                    <td className="table-td text-start">{(MilesOneWay - 30) * 2}</td>
                                                                    <td className="table-td text-start">ADDITIONAL MILEAGE</td>
                                                                    <td className="table-td text-end">${orderEntry?.order_details?.ExtraMilesCost ? orderEntry?.order_details?.ExtraMilesCost.toFixed(2) : parseFloat(orderDetailData[1]?.Value).toFixed(2)}</td>
                                                                    <td className="table-td text-end">${orderEntry?.summary?.MileageCost ? parseFloat(orderEntry?.summary?.MileageCost).toFixed(2) : 0}</td>
                                                                </tr>
                                                            }

                                                            {ShinglesCost != 0 &&
                                                                <tr>
                                                                    <td className="table-td text-start">{Shingles}</td>
                                                                    <td className="table-td text-start">Shingles</td>
                                                                    <td className="table-td text-end">$3.00</td>
                                                                    <td className="table-td text-end">${ShinglesCost.toFixed(2)}</td>
                                                                </tr>
                                                            }
                                                            {ConcreteCost != 0 &&
                                                                <tr>
                                                                    <td className="table-td text-start">{Concrete}</td>
                                                                    <td className="table-td text-start">Concrete</td>
                                                                    <td className="table-td text-end">$25.00</td>
                                                                    <td className="table-td text-end">${ConcreteCost.toFixed(2)}</td>
                                                                </tr>
                                                            }
                                                            {LaborMiscCost != 0 &&
                                                                <tr>
                                                                    <td className="table-td text-start">1</td>
                                                                    <td className="table-td text-start">Labor / Misc Cost</td>
                                                                    <td className="table-td text-end">${LaborMiscCost.toFixed(2)}</td>
                                                                    <td className="table-td text-end">${LaborMiscCost.toFixed(2)}</td>
                                                                </tr>
                                                            }
                                                            <tr>
                                                                <td className="table-td" colSpan="4"></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-td" colSpan="2"></td>
                                                                <td className="table-td text-white" colSpan="2 ">.</td>
                                                            </tr>

                                                            {TrailerCost ? <tr>
                                                                <td className="table-td" colSpan="2" style={{ textAlign: 'end' }}>Sales Tax</td>
                                                                <td className="table-td" colSpan="1"></td>
                                                                <td className="table-td text-end" colSpan="2">${TrailerCost.toFixed(2)}</td>
                                                            </tr> : null}
                                                            {order_details?.Discount != 0 ?
                                                                <tr>
                                                                    <td className="table-td" colSpan="2" style={{ textAlign: 'end' }}>Discount</td>
                                                                    <td className="table-td" colSpan="1"></td>
                                                                    <td className="table-td text-end" colSpan="2">${orderEntry.order_details.Discount ? parseFloat(orderEntry?.order_details?.Discount).toFixed(2) : 0}</td>
                                                                </tr>
                                                                : null}
                                                            <tr>
                                                                <td className="table-td" colSpan="2" style={{ textAlign: 'end' }}>TOTAL</td>
                                                                <td className="table-td" colSpan="1"></td>
                                                                <td className="table-td text-end" colSpan="2">${(TotalCost - orderEntry?.order_details?.Discount).toFixed(2)}</td>
                                                            </tr>
                                                            {payments ? payments.map(paymentInfo => {
                                                                return <tr>
                                                                    <td className="table-td text-end" colSpan="2">PAID</td>
                                                                    <td className="table-td text-center" colSpan="1">{paymentInfo.PaymentType.split("-")[0]}</td>
                                                                    <td className="table-td text-end" colSpan="1">${paymentInfo.PaymentAmount.toFixed(2)}</td>
                                                                </tr>
                                                            }) : null}

                                                            <tr>
                                                                <td className="table-td" colSpan="2" style={{ textAlign: 'end' }}>BALANCE</td>
                                                                <td className="table-td" colSpan="1"></td>
                                                                <td className="table-td text-end" colSpan="2">${(orderEntry.summary.BalanceCost - orderEntry?.order_details?.Discount).toFixed(2)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {(BalanceCost == 0) && <img src="/assets/paid-stamp.png" alt="" className='w-[200px] mt-[-200px] ml-10' />}
                                        </div>
                                    </Dialog.Title>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

        </>
    )
}

