import { GOOGLE_MAP_SCRIPT_URL } from "config";
import { InputHTMLAttributes, memo, useEffect, useRef } from "react";
import { useScript } from "usehooks-ts";

export interface AddressAutocompleteProps extends InputHTMLAttributes<HTMLInputElement> {
  onPlaceSelected: (address: string) => void;
}
const AddressAutocomplete = (props: AddressAutocompleteProps) => {
  const googleMapScriptLoadStatus = useScript(process.env.REACT_APP_GOOGLE_API_KEY || null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { onPlaceSelected, ...otherProps } = props;
  useEffect(() => {
    if (googleMapScriptLoadStatus !== "ready" || !inputRef.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
      types: ["address"],
      componentRestrictions: { country: "us" },
      fields: ["formatted_address"]
    });
    autocomplete.addListener("place_changed", (place: any) => {
      onPlaceSelected(autocomplete.getPlace().formatted_address!);
    });
  }, [googleMapScriptLoadStatus, onPlaceSelected]);

  return <input ref={inputRef} autoComplete="none" {...otherProps} />;
};

export default memo(AddressAutocomplete);
