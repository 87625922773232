import { Dispatch, SetStateAction } from "react";

export declare type SetValue<T> = Dispatch<SetStateAction<T>>;

export enum Role {
  ADMINISTRATOR = "Administrator",
  DELIVERY_DRIVER = "Delivery Driver",
  ORDER_TAKER = "Order Taker"
}

export interface Account {
  ID: number;
  AccessType: string;
  Email: string;
  Name: string;
  Picture: string;
  accessToken: string;
}

export interface SystemSettings {
  companyAddress: string;
  companyCoverLogo: string;
  companySquareLogo: string;
}

export interface NotificationValues {
  pendingOrderCount: number;
  deliveredOrderCount: number;
  completedOrderCount: number;
}

export interface OrderEntry {
  OrderNumber: number;
  customer: customer_interface;
  delivery_contact: delivery_contact_interface;
  order_details: order_details_interface;
  summary: summary_interface;
  order_info: order_info_interface;
}

export enum OrderStatuses {
  CANCELLED = "Cancel",
  QUOTE = "Quote",
  PENDING = "Pending",
  DELIVERED = "Delivered",
  COMPLETED = "Completed"
}

export interface Note {
  ID: number;
  title: string;
  content: string;
}

export interface Payment {
  PaymentID?: string;
  OrderNumber: number;
  PaymentDate: string;
  PaymentType: string;
  PaymentAmount: number;
  PaymentNote: string;
}
