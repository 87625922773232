import axios from "axios";
import { baseURL } from "config";
import { FaCloudDownloadAlt, FaCog, FaFileAlt } from "react-icons/fa";
import { toast } from "react-toastify";

const DataSetting = () => {

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  const getCustomers = async () => {
    axios.get(`${baseURL}/customer/`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    })
      .then(res => { downloadCustomers(res.data.data); })
      .catch(err => console.log(err));
  };

  const getOrders = () => {
    axios.get(`${baseURL}/order/`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    })
      .then(res => { downloadOrders(res.data.data); })
      .catch(err => console.log(err));
  };

  const getPayments = async () => {
    axios.get(`${baseURL}/payment/`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    })
      .then(res => { downloadPayments(res.data.data); })
      .catch(err => console.log(err));
  };

  const downloadOrders = (orders: any) => {
    const tempData: any[] = [];
    try {
      // eslint-disable-next-line
      const csvData = [
        Object.keys(orders[0]),
        ...orders.map((item: any) => [Object.values(item)])
      ].forEach((e: any) => {
        const tempElement: any[] = [];
        if (typeof e[0] === "string") {
          tempElement.push(e?.slice(0, 28));
        } else {
          e[0].forEach((element: string, i: number) => {
            if (i > 27) return;
            if (element !== null) {
              tempElement.push(element.toString().replaceAll(",", ""));
            } else tempElement.push("");
          });
        }
        tempData.push(tempElement.join(","));
      });
    } catch (error) {
      toast.error("Download failed!");
      return;
    }

    const csvDataAsBlob = tempData.join("\n");
    const blob = new Blob([csvDataAsBlob], { type: "text/csv;charset=utf-8;" });

    try {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "Orders.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      toast.success("Download successful");
    } catch (error) {
      toast.error("Download failed");
    }
  };

  const downloadCustomers = async (customers: any) => {
    const tempData: any[] = [];
    try {
      // eslint-disable-next-line
      const csvData = [
        Object.keys(customers[0]),
        ...customers.map((item: Customer) => [Object.values(item)])
      ].forEach((e: any) => {
        const tempElement: any[] = [];
        if (typeof e[0] === "string") {
          tempElement.push(e?.slice(0, 12));
        } else {
          e[0].forEach((element: string, i: number) => {
            if (i > 11) return;
            if (element !== null) {
              tempElement.push(element.replaceAll(",", ""));
            } else tempElement.push("");
          });
        }
        tempData.push(tempElement.join(","));
      });
    } catch (error) {
      toast.error("Download failed!");
      return;
    }

    const csvDataAsBlob = tempData.join("\n");
    const blob = new Blob([csvDataAsBlob], { type: "text/csv;charset=utf-8;" });
    try {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "Customers.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      toast.success("Download successful");
    } catch (error) {
      toast.error("Download failed");
    }
  };

  const downloadPayments = async (payments: any) => {
    const tempData: any[] = [];
    try {
      // eslint-disable-next-line
      const csvData = [
        Object.keys(payments[0]),
        ...payments.map((item: any) => [Object.values(item)])
      ].forEach((e: any) => {
        const tempElement: any[] = [];
        if (typeof e[0] === "string") {
          tempElement.push(e?.slice(0, 5));
        } else {
          e[0].forEach((element: string, i: number) => {
            if (i > 4) return;
            tempElement.push(element);
          });
        }
        tempData.push(tempElement.join(","));
      });
    } catch (error) {
      toast.error("Download failed!");
      return;
    }

    const csvDataAsBlob = tempData.join("\n");
    const blob = new Blob([csvDataAsBlob], { type: "text/csv;charset=utf-8;" });

    try {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "Payments.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      toast.success("Download successful");
    } catch (error) {
      toast.error("Download failed");
    }
  };

  return (
    <>
      <div className="flex items-center">
        <div className="">
          <h1 className="flex items-center font-bold md:text-xl">
            <FaCog className="h-6 mr-1 md:w-7 md:h-7 " /> Data
          </h1>
        </div>
      </div>
      <div className=" md:p-36 md:max-lg:p-10 lg:max-2xl:p-2">
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3 md:gap-20 md:max-lg:gap-8 lg:max-2xl:gap-2">
          <button
            className="flex justify-between border py-[21px] bg-[#124EE4] btn btn-icon hover:bg-[#2b62ee]"
            onClick={getOrders}>
            <FaFileAlt className="w-20 h-20" />
            <div className="flex flex-col text-xl text-left">
              <span>Orders.csv</span>
            </div>
            <FaCloudDownloadAlt className="w-20 h-20" />
          </button>

          <button
            className="flex justify-between border py-[21px] bg-[#124EE4] btn btn-icon hover:bg-[#2b62ee]"
            onClick={getCustomers}>
            <FaFileAlt className="w-20 h-20" />
            <div className="flex flex-col text-xl text-left">
              <span>Customers.csv</span>
            </div>
            <FaCloudDownloadAlt className="w-20 h-20" />
          </button>

          <button
            className="flex justify-between border py-[21px] bg-[#124EE4] btn btn-icon hover:bg-[#2b62ee]"
            onClick={getPayments}>
            <FaFileAlt className="w-20 h-20" />
            <div className="flex flex-col text-xl text-left">
              <span>Payments.csv</span>
            </div>
            <FaCloudDownloadAlt className="w-20 h-20" />
          </button>
        </div>
      </div>
    </>
  );
};

export default DataSetting;
