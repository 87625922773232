//

import { Note } from "config/types";
import { useNoteContext } from "./NoteContext";
import { useCallback, useState } from "react";
import axios from "axios";
import { baseURL } from "config";
import { generateEmptyNote } from "utils/note.helper";
import { FaSpinner, FaTrash } from "react-icons/fa";

const NoteList = () => {
  const { notes, activeNoteId, selectNote, setNotes } = useNoteContext();
  const [loading, setLoding] = useState(false);
  const storage_string = localStorage.getItem("USER");
  const storage = storage_string ? JSON.parse(storage_string) : [];

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  const addNote = useCallback(async () => {
    const noteRequest = generateEmptyNote();
    noteRequest.title = "New Note";

    const tempId = new Date().getTime() + Math.random();
    setNotes((notes) => [...notes, { ...noteRequest, ID: tempId }]);
    selectNote(tempId);

    try {
      const response = await axios
        .post(`${baseURL}/notes/`, { title: noteRequest.title, content: noteRequest.content }, {
          headers: {
            Authorization: `Bearer ${userInfo?.accessToken}`,
          },
        })
        .then(({ data }) => data);

      const newNote = response.data;
      if (newNote) {
        setNotes((notes) => notes.map((note) => (note.ID === tempId ? newNote : note)));
        selectNote(newNote.ID);
      } else {
        throw new Error("Note not found");
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setNotes((notes) => notes.filter((note) => note.ID !== tempId));
      selectNote(-1);
    }
  }, [setNotes, selectNote, userInfo?.accessToken]);

  const deleteNote = useCallback(async (deletingNote: Note) => {
    setLoding(true)
    try {
      const response = await axios
        .delete(`${baseURL}/notes/${deletingNote.ID}`, {
          headers: {
            Authorization: `Bearer ${userInfo?.accessToken}`,
          },
        })
        .then(({ data }) => data);

      if (response.success) {
        setLoding(false)
        setNotes(notes => notes.filter(note => note.ID !== deletingNote.ID))
      }
    } catch (ex) {
      console.log(ex);
    } finally {
    }
  }, [setNotes])

  return (
    <div className="h-full bg-gray-100 py-4" >
      <ul >
        {notes.map((note: Note, index: number) => (
          <li
            key={`note-list-item-${note.ID}`}
            className={`relative px-4 cursor-pointer py-1 transition-colors hover:bg-white/70 group ${note.ID === activeNoteId ? "bg-white hover:bg-white" : ""
              }`}
            onClick={() => selectNote(note.ID)}>
            {note.title}
            <button className={`btn-text text-gray-500 hover:text-gray-700 transition-colors absolute right-0 top-0 cursor-pointer p-2 hidden ${storage['AccessType'] === "Administrator" ? 'group-hover:block' : ''}`} onClick={(e) => {
              e.stopPropagation()
              deleteNote(note)
            }}>
              {loading ? <FaSpinner className="w-4 h-4"></FaSpinner> : < FaTrash className="w-4 h-4"></FaTrash>}
            </button>
          </li>
        ))}
        <li className="px-4 cursor-pointer py-1" onClick={addNote} hidden={storage['AccessType'] === "Administrator" ? false : true}>
          + New Note
        </li>
      </ul>
    </div>
  );
};

export default NoteList;
