import { useLayout } from "providers/LayoutProvider";
import { NoteContextProvider } from "./components/NoteContext";
import NoteDetail from "./components/NoteDetail";
import NoteList from "./components/NoteList";
import { FaTimes } from "react-icons/fa";

const Notes = () => {
  const { toggleNotesPanel } = useLayout();
  return (
    <NoteContextProvider>
      <div className="h-full flex flex-col">
        <div className="relative border-b bg-blue-300">
          <h1 className="py-3 text-xl px-4">Notes</h1>
          <button
            className="btn btn-text absolute top-4 right-4 text-white/75 hover:text-white"
            onClick={toggleNotesPanel}>
            <FaTimes className="w-4 h-4" />
          </button>
        </div>
        <div className="note flex justify-between w-full flex-1">
          <div className="w-2/3">
            <NoteDetail />
          </div>
          <div className="w-1/3">
            <NoteList />
          </div>
        </div>
      </div>
    </NoteContextProvider>
  );
};

export default Notes;
