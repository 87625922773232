import { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { GOOGLE_MAP_SCRIPT_URL, baseURL } from "config";
import { useOrderContext } from "./OrderContextProvider";
import { toast } from "react-toastify";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { calculateSummaryTotals } from "utils/orders.helper";
import AddressAutocomplete from "./AddressAutocomplete";
import { useScript } from "usehooks-ts";

const RouteDialog = ({ setGoogleModal, useBillingAddress = false }: any) => {
  const { orderEntry, setOrderEntry } = useOrderContext();

  const [companyAddress, setCompanyAddress] = useState<string>("");
  const [deliveryAddress, setDeliveryAddress] = useState<string>("");
  const googleMapScriptLoadStatus = useScript(process.env.REACT_APP_GOOGLE_API_KEY || null);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const mapDirectionsServiceRef = useRef<google.maps.DirectionsService | null>(null);
  const mapDirectionsRendererRef = useRef<google.maps.DirectionsRenderer | null>(null);
  const mapDistanceMatrixServiceRef = useRef<google.maps.DistanceMatrixService | null>(null);

  const [calculating, setCalculating] = useState<boolean>(false);

  const [distance, setDistance] = useState<number>(0);
  const [duration, setDuration] = useState<string>("");

  const [updateDeliveryAddress, setUpdateDeliveryAddress] = useState<boolean>(true);

  const [orderDetailData, setOrderDetailData] = useState<OrderSetting_interface[]>([]);

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }

  const getSystemItem = useCallback(async () => {
    try {
      const response = await axios.get(`${baseURL}/setting/system`, {
        headers: {
          Authorization: `Bearer ${userInfo?.accessToken}`,
        },
      });
      if (response.data.success) {
        setCompanyAddress(response.data.data[0].companyAddress);
      }
    } catch (error) {
      console.log("getSystemItem Error::", error);
    }
  }, [setCompanyAddress]);

  const getOrderSetting = useCallback(async () => {
    const settingData = await axios.get(`${baseURL}/setting/order-entry`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    if (!settingData.data.success) return;
    setOrderDetailData(
      settingData.data.data.filter(
        (item: OrderSetting_interface) => item["TicketDescription"] === "OrderDetail"
      )
    );
  }, [setOrderDetailData]);

  useEffect(() => {
    getSystemItem();
    getOrderSetting();
  }, [getSystemItem, getOrderSetting]);

  const onDeliveryAddressSelected = useCallback(
    (address: string) => setDeliveryAddress(address),
    [setDeliveryAddress]
  );

  useEffect(() => {
    setDeliveryAddress(
      useBillingAddress
        ? orderEntry.customer.BillingAddress
        : orderEntry.delivery_contact.DeliveryAddress
    );
  }, [useBillingAddress, orderEntry, setDeliveryAddress]);

  useEffect(() => {
    if (googleMapScriptLoadStatus !== "ready" || !mapContainerRef.current) return;

    const companyLocation = new google.maps.LatLng(43.413870786921, -83.9044106257);
    const mapOptions = {
      zoom: 10,
      center: companyLocation
    };

    mapRef.current = new google.maps.Map(mapContainerRef.current, mapOptions);
    const marker = new google.maps.Marker({
      map: mapRef.current!,
      position: companyLocation
    });
    marker.setAnimation(google.maps.Animation.BOUNCE);

    mapDirectionsServiceRef.current = new google.maps.DirectionsService();
    mapDirectionsRendererRef.current = new google.maps.DirectionsRenderer({
      draggable: true
    });

    mapDirectionsRendererRef.current.setMap(mapRef.current);

    mapDistanceMatrixServiceRef.current = new google.maps.DistanceMatrixService();
  }, [googleMapScriptLoadStatus]);

  // const resetRoute = useCallback(() => {
  //   setDistance(0);
  //   setDuration("");
  // }, [setDistance, setDuration]);

  const calculateRoute = useCallback(async () => {
    if (
      !companyAddress ||
      !deliveryAddress ||
      !mapDirectionsServiceRef.current ||
      !mapDirectionsRendererRef.current ||
      !mapDistanceMatrixServiceRef.current
    )
      return;

    setCalculating(true);
    try {
      const routeResponse = await mapDirectionsServiceRef.current!.route({
        origin: companyAddress,
        destination: deliveryAddress,
        travelMode: google.maps.TravelMode.DRIVING
      });

      mapDirectionsRendererRef.current!.setDirections(routeResponse);

      const distanceMatrixResponse = await mapDistanceMatrixServiceRef.current!.getDistanceMatrix({
        origins: [companyAddress],
        destinations: [deliveryAddress],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false
      });

      const distanceMatrix = distanceMatrixResponse.rows[0].elements[0];

      setDistance(+(distanceMatrix.distance.value * 0.000621371).toFixed(2));
      setDuration(distanceMatrix.duration.text);

      toast.success("The calculation of distance has been finished!!!");
    } catch (ex) {
      toast.error("Unable to find the distance via road.");
    } finally {
      setCalculating(false);
    }
  }, [companyAddress, deliveryAddress, setCalculating]);

  const saveRoute = useCallback(() => {
    const entry = { ...orderEntry };

    if (useBillingAddress) {
      entry.customer.BillingAddress = deliveryAddress;
    }

    if (!useBillingAddress || updateDeliveryAddress)
      entry.delivery_contact.DeliveryAddress = deliveryAddress;

    entry.order_details.MilesOneWay = distance;
    entry.summary.MileageCost =
      distance < 30 ? 0 : parseFloat(((distance - 30) * 2 * +orderDetailData[1].Value).toFixed(2));

    setOrderEntry(calculateSummaryTotals(entry, +orderDetailData[0]?.Value));
    setGoogleModal(false);
  }, [
    orderEntry,
    useBillingAddress,
    deliveryAddress,
    distance,
    orderDetailData,
    updateDeliveryAddress,
    setOrderEntry,
    setGoogleModal
  ]);

  const handleCancel = useCallback(() => setGoogleModal(false), [setGoogleModal]);

  const handleCalculate = useCallback(() => calculateRoute(), [calculateRoute]);

  const handleSave = useCallback(() => saveRoute(), [saveRoute]);

  return (
    <>
      <div className="fixed inset-0 z-50 bg-black opacity-50"></div>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="relative max-w-7xl w-full googleMapModal">
          <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none p-6 space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="font-bold">Calculate Distance</h3>
              <button onClick={(e) => setGoogleModal(false)} className="btn btn-danger p-0 w-8 h-8">
                <FaTimes />
              </button>
            </div>
            <div className="space-y-4">
              <div className="flex flex-col lg:flex-row gap-4 lg:gap-8">
                <div className="space-y-4 lg:flex-1">
                  <div className="form-control">
                    <label title="From">From</label>
                    <div className="input-inline lg:!w-[90%]">
                      <input type="text" readOnly={true} defaultValue={companyAddress} />
                    </div>
                  </div>
                  <div className="form-control">
                    <label title="From">To</label>
                    <div className="input-inline lg:!w-[90%]">
                      <AddressAutocomplete
                        type="text"
                        value={deliveryAddress}
                        onChange={(e: any) => setDeliveryAddress(e.target.value)}
                        onPlaceSelected={onDeliveryAddressSelected}
                        placeholder="Type the destination address..."
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-4 ">
                  <div className="form-control lg:gap-2">
                    <label title="From">Distance</label>
                    <div className="input-inline lg:max-w-[200px]">
                      <input type="text" readOnly={true} value={`${distance} miles`} />
                    </div>
                  </div>
                  <div className="form-control lg:gap-2">
                    <label title="From">Duration</label>
                    <div className="input-inline lg:max-w-[200px]">
                      <input type="text" readOnly={true} value={duration || "0 mins"} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex w-full">
                <div ref={mapContainerRef} className="panel h-[300px] sm:h-[400px] lg:h-[500px]"></div>
              </div>

              <div>
                {useBillingAddress && (
                  <div className="flex justify-end lg:hidden">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={updateDeliveryAddress}
                        onChange={() => setUpdateDeliveryAddress(!updateDeliveryAddress)}
                        className="mr-1"
                      />
                      <span>Save delivery address</span>
                    </label>
                  </div>
                )}
                <div className="flex justify-between w-full gap-2">
                  <button className="btn btn-default sm:w-32" onClick={handleCancel}>
                    Cancel
                  </button>
                  <div className="flex">
                    <button
                      className="btn btn-danger sm:w-32 mr-2"
                      onClick={handleCalculate}
                      disabled={calculating}>
                      {calculating && <FaSpinner className="w-4 h-4 mr-1 animate-spin-slow" />}{" "}
                      Calculate
                    </button>
                    <div className="flex">
                      {useBillingAddress && (
                        <label className="items-center mr-1 hidden lg:flex">
                          <input
                            type="checkbox"
                            checked={updateDeliveryAddress}
                            onChange={() => setUpdateDeliveryAddress(!updateDeliveryAddress)}
                            className="mr-1"
                          />
                          <span>Save delivery address</span>
                        </label>
                      )}

                      <button
                        className="btn btn-primary sm:w-32"
                        onClick={handleSave}
                        disabled={calculating}>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RouteDialog;
